.myChatsContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: #EDF2F7; /* Soft background color */
    position: relative;
  }
  
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    background-color: #2D3748; /* Dark gray background for contrast */
    color: #FFFFFF;
  }

  .searchBar {
    margin: 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 2rem;
    width: calc(100% - 20px); /* Adjust based on your container's padding */
  }
  
  .GroupBtn {
    padding: 0.5rem 1rem;
    background-color: #3182CE; /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  .GroupBtn:hover {
    background-color: #4299E1; /* A lighter shade of blue for hover state */
  }
  
  .chatsBox {
    flex: 1;
    overflow-y: auto; /* Allows scrolling through chats */
    padding: 1rem;
  }
  
  .chatItem {
    /* background-color: #FFFFFF; */
    border-radius: 0.375rem; /* 6px */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    margin-top: 0.4rem;
  }
  
  .chatItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .chatName {
    font-weight: bold;
    color: #2D3748; /* Dark gray for text */
  }
  
  .latestMessage {
    color: #4A5568; /* Slightly lighter gray for subtext */
    font-size: 0.875rem;
  }

  .ModalContainer{
    backdrop-filter: blur(10px);
    margin: '0 auto';
  }
  .GroupHeading{
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-fifth);
  }

  .CreateBtn{
    padding: 0.5rem 1rem;
    background-color: #3182CE; /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  .CreateBtn:hover{
    background-color: #59a3e8; /* Chakra's blue.500 */
    color: white 
  }

  .CancelBtn{
    padding: 0.5rem 1rem;
    background-color: var(--color-danger); /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  .CancelBtn:hover{
    background-color: var(--color-dangerHover); /* Chakra's blue.500 */
    /* color: var(--color-dangerHover); */
  }

  .addBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 10px 0;
    background: #3182ce; /* This is Chakra's blue.500 */
    color: white;
    width: 5rem;
    border-radius: 1rem;
    padding: 0.2rem;
    cursor: pointer;
  }

  .addBtn:active {
    background-color: var(--color-primary); 
  }
  
  
  /* You might need to adjust this according to your design */
  .addBtn svg {
    width: 1em;
    height: 1em;
  }
  
  



  .CustomModal {
    /* Add custom styles for the modal background, etc., if needed */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ModalOverlay {
    background-color: rgba(0, 0, 0, 0.4); /* Dim the background */
    backdrop-filter: blur(8px); /* Apply a blur effect */
  }
  
  .ModalContent {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin: 0 auto; /* Center in the available space */
    display: flex; /* Enables you to use flex properties for alignment */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center-align the children horizontally */
    justify-content: center; /* Center-align the children vertically */
    /* You may need to adjust max-width or width for size control */
    max-width: 500px; /* Example: limit width for larger screens */
    width: 90%; /* Responsive width */
  }
  
  .ModalHeader {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .ModalCloseButton {
    cursor: pointer;
    position: absolute;
    right: 16px; 
    top: 16px; 
    font-size: 14px; 
    border: none;
    background: transparent; 
    padding: 0; 
    z-index: 10; 
  }
  
  
  .AddIconModalBody {
    padding-bottom: 24px; /* Adjust padding as needed */
  }
  
  .ModalFooter {
    display: flex;
    justify-content: flex-end;
  }
  
  .UploadButton {
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    /* Adjustments for better visuals */
  }
  
  .CancelButton {
    background: transparent;
    color: #000;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    /* Hover effects, etc. */
  }
  
 
  .FileInput, .DocumentNameInput {
    border: 1px solid #CBD5E0; /* Light gray border */
    padding: 8px 12px; /* Padding inside the input */
    border-radius: 4px; /* Rounded corners */
    margin-top: 8px; /* Margin at the top */
    margin-bottom: 8px; /* Margin at the bottom */
    width: calc(100% - 24px); /* Full width minus padding */
    box-sizing: border-box; /* Border box sizing */
  }
  
  .FileInput:focus, .DocumentNameInput:focus {
    border-color: #3182CE; /* Chakra's blue.500 for focus */
    box-shadow: 0 0 0 1px #3182CE; /* Focus shadow */
  }
  
  .DocumentNameInput {
    margin-bottom: 16px; /* Extra margin at the bottom for spacing */
  }
  
  
  .uploadedDocumentName {
    font-size: 16px;
    color: #2D3748;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  
.section {
    padding: 5rem 0;
    background-color: var(--bg-primary);
    color: var(--color-fourth);
  }
  
  .container, .containerReverse {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .containerReverse {
    flex-direction: row-reverse;
  }
  
  .imageContainer {
    flex: 1;
    text-align: center;
  }
  
  .imageContainer img {
    width: 100%;
    max-width: 500px;
    border-radius: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .imageContainer img:hover {
    transform: scale(1.05);
  }
  
  .textContainer {
    flex: 1;
    padding: 1rem;
  }
  
  .title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: var(--color-accent2);
    text-align: center;
    position: relative;
  }
  
  .title::after {
    content: '';
    width: 100px;
    height: 3px;
    background: var(--color-accent2);
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    text-align: justify;
  }
  
  @media (max-width: 1024px) {
    .title {
      font-size: 2rem;
    }
  
    .text {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 768px) {
    .container, .containerReverse {
      flex-direction: column;
    }
  
    .imageContainer, .textContainer {
      margin: 1rem 0;
    }
  
    .title {
      font-size: 1.8rem;
    }
  
    .text {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }
  
    .text {
      font-size: 0.85rem;
    }
    .container{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 3rem;
    }
  } 

  /* Middle container and form started */
  .midContainer, .formContainer {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: "Montserrat", sans-serif;
    overflow: hidden;
    color: var(--color-fifth);
  }
.error{
  color: var(--color-danger);
}
.logospan{
  color: var(--color-accent2);
}
  .inputContainer {
    position: relative;
    width: 100%;
  }
  .passwordInput {
    padding-right: 40px; /* Adjust the padding to leave space for the button */
    width: 70%;
    padding: 10px;
    margin: 12px 0;
    border: 0px solid;
    border-radius: 0.5rem;
    font-size: 16px;
    background-color: var(--color-fifth);
  }
/* login.module.css */
.visibilityButton {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  margin-right: 15%;
}

  
  .midContainer {
    display: flex;
    justify-content: center;
    padding-right: 12rem;
    align-items: center;
    box-sizing: border-box;
    height: 80%; /* Center vertically on the viewport */
    border: 1px solid var(--color-accent1);
  }
  
  /* Style the mid-left-container (for the logo) */
  .midLeftContainer {
    padding-right: 180px;
    width: 70rem;
    height: 42.1rem;
  }
  
  .midLeftContainer img {
    height: 100%; /* Allow image to resize proportionally */
    width: 100%;
  }
  
  /* Style the midRightContainer */
  .midRightContainer {
    border-radius: 0.6rem;
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    text-align: center;
    padding: 0px 0px 30px 0px;
    width: 50rem;
  }
  
  .container-head {
    margin-right: 4rem;
    font-size: 1.1rem;
  }
  
  /* Remove h2::after content to avoid overflow */
  h2::after {
    content: "";
  }
  
  .Para {
    margin: auto;
    color: var( --color-accent2);
    /* color: blue; */
    font-size: 1.2rem;
  }
  
  /* Style the form container */
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
 
  
  /* Style the form inputs */
  .input {
    width: 70%;
    padding: 10px;
    margin: 12px 0;
    border: 0px solid;
    border-radius: 0.5rem;
    font-size: 16px;
    background-color: var(--color-fifth);
  }

  .input:focus{
    border: 2px solid var(--color-btn-bg);
  }
  
  input::placeholder {
    color: var(--color-primary);
  }
  
  /* Style the form submit button */
  .submit{
    width: 73%;
    background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
    padding: 10px 20px;
    margin: 40px 0px 0px 0px;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .submit:hover {
    background-color: var(--color-fourth); 
    color: var(--color-btn-bg);
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    
  }
  
  .forgotPass {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 4.1rem;
    margin-right: 17%;
    color: var(--color-accent1);
    
  }
  .forgotPass:hover {
    color: var(--color-accent2);
    transform: scale(1.05); /* Slightly enlarge the item */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    
  }
  .signUp{
    color: var(--color-accent1);
  }
  
  .signUp:hover {
    color: var(--color-accent2);
    transform: scale(3.05); /* Slightly enlarge the item */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  
  small {
    margin-top: 6rem;
    display: block;
  }


  @media screen and (max-width: 1080px) {
  .midContainer {
    flex-direction: row;
    align-items: center;
    padding: 2rem 1rem;
    height: 100%; /* Adapt height to content */
    /* background-color: red; */
  }
 
  .midLeftContainer {
    width: 60%; /* Use more width for better utilization of space */
    padding: 0;
    margin-bottom: 2rem; /* Add some space between the containers */
  }

  .midRightContainer {
    border-radius: 0.6rem;
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    text-align: center;
    padding: 10px 10px 30px 10px;
    margin-left: 1rem;
    width: 50%;
  }
  

  .midLeftContainer img {
    border-radius: 0.6rem;
    box-shadow: none; /* Optionally remove shadows for a cleaner look */
    width: 100%;
  }

  .input, .passwordInput {
    width: 80%; /* Increase width for easier interaction */
  }

  .submit, .visibilityButton {
    margin-top: 0rem; /* Adjust spacing */
  }

  .forgotPass {
    padding-right: 1rem; /* Adjust padding for better alignment */
    margin-right: 0; /* Adjust margin for center alignment */
  }

  /* Optimize text size for better readability */
  .container-head, .error, .logo-span {
    font-size: calc(16px + 1vw); /* Responsive font size */
  }
}
  
  
  /* For screens smaller than 1000px */
  @media (max-width: 800px) {
    .midContainer {
      padding-right: 0;
      padding-left: 0;
    }

    .midRightContainer{
      padding-top: 6rem;
    }
  
    .midLeftContainer {
      width: 100%;
      padding: 0;
      display: none;
    }

    .inputContainer {
      position: relative;
      width: 100%;
    }

    .passwordInput {
      padding-right: 40px; /* Adjust the padding to leave space for the button */
      width: 70%;
      padding: 10px;
      margin: 12px 0;
      border: 0px solid;
      border-radius: 0.5rem;
      font-size: 16px;
      background-color: var(--color-fifth);
    }

    .visibilityButton {
      position: absolute;
      top: 50%;
      left: 60%;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
    }
  }
  
  /* For screens smaller than 768px */
  @media (max-width: 768px) {
    .midLeftContainer img {
      display: none;
    }
  
    .midRightContainer {
      padding-top: 4rem;
      width: 100%;
    }

    .inputContainer {
      position: relative;
      width: 100%;
    }

    .passwordInput {
      padding-right: 40px; 
      width: 70%;
      padding: 10px;
      margin: 12px 0;
      border: 0px solid;
      border-radius: 0.5rem;
      font-size: 16px;
      background-color: var(--color-fifth);
    }
    .visibilityButton {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      margin-left: 15%;
    }
  }
.container {
    /* Ensure the container takes up the necessary space and is centered */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; /* This will center the text within each service */
    margin-top: 3%;
    margin-bottom: 5%;
  }

.serviceContainer{ 
    display: flex;
    flex-wrap: wrap; 
    justify-content: center;
    gap: 3rem;
}

.price{
    font-weight: 700;
    color: var(--color-fifth);
    font-size: 1.5rem;
}

.heading{
    font-size: 2.2rem;
    font-weight: 900;
    text-align: center;
    color: var(--color-fifth);
    margin-bottom: 2%;
}

.service{
    background-color: var(--bg-primary);
    border-radius: 0 0 2rem 2rem;
    color: var(--bg-primary);
    height: fit-content;
    box-shadow: inset 0px 0px 6px 0px var(--color-accent2);
    transition: var(-transition);
}



.serviceHead{
    border-radius: 0 0 2rem 2rem;
    /* background-color: var(--color-accent2); */
    padding: 2rem;
}

.serviceHead h3{
    color: var(--color-bg);
    font-size: 1.3rem;
    font-weight: bolder;
    text-align: center;
}

.serviceList{
    padding: 3rem;
}

.selectButton {
    width: 100%;
    padding: 10px;
    background-color: var(--color-accent2);
    color: var(--color-fifth);
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 20px;
    box-shadow: inset 0px 0px 6px 0px;
    border-radius: 0.4rem;
    font-size: 1.2rem;
    /* Optional: Add hover effect */
    &:hover {
        /* background-color: var(--color-accent1);  */
        /* box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg); */
        font-weight: bolder;
        transform: scale(1.05); 
        /* color: var(--color-btn-bg); */
    }
  }
  
.serviceList li{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.serviceListIconCross{
    color: var(--color-danger);
}

.serviceListIcon{
    margin-top: 6px;
    color: var(--color-Right);
}

.Titles{
    color: var(--color-fifth);
}

.BeforeDiscount{
    text-decoration: line-through;
    color: white;
    margin-right: 0.5rem;
}

.Discount{
    border: 1px;
    background-color: #007bff;
    color: white;
    font-weight: 700;
    font-size: 0.7rem;
    width: 4rem;
    border-radius: 0.18rem;
}

  .DiscountContainer{
    display: flex; 
    flex-direction: row;
    justify-content: center;
}

.serviceList p {
    font-size: 0.9rem;
}

/* ============ Media Quires (Medium devices) ================ */
  
@media screen and (max-width:1024px){
    .serviceContainer{
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
    .service{
        height: auto;
    }
}
  
  /* ============ MEDIA QUERY (SMALL DEVICES)==========*/
  
  @media screen and (max-width:600PX){
    .serviceContainer{
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
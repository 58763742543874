/* SingleChat.module.css */
.chatContainer {
    background-color: #EDF2F7; 
    width: 77vw;
    height: 100%; 
    border-radius: 8px;
    top: 3.5rem;
    right: 28rem;
    padding: 1rem;
  }
  
  .placeholderText {
    font-size: 24px; 
    color: var(--color-fifth); 
    /* background-color: white; */
    color: var(--color-accent2);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35rem;
    
  }
  .chatHeader{
    color: var(--color-accent2);
    font-size: 1.5rem;
    font-weight: 700;
    width: 0;
    position: relative;
    bottom: 1rem;
  }

  .ConversationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .placeholder{
    /* background-color: red; */
  }


 /* Style/SingleChat.module.css */
.MessageInputContainer {
  display: flex;
  gap: 8px; /* spacing between input and button */
  margin-top: 16px; /* mt="4" equivalent */
  padding: 8px; /* py="2" and px="4" equivalent */
  align-items: center;
  background-color: #EDF2F7; /* bg="gray.100" equivalent */
  border-radius: 0.375rem; /* borderRadius="lg" */
  position: relative;
  bottom:0.8rem;
}
.MessageInput {
  flex: 1; /* Take up remaining space */
  padding: 0.5rem;
  border: 2px solid #E2E8F0; /* A light gray border */
  border-radius: 0.375rem; /* rounded-lg */
  background-color: white;
}

.SendButton {
  padding: 0.5rem 1rem;
  background-color: #4299E1; /* colorScheme="blue" */
  color: white;
  border: none;
  border-radius: 0.375rem; /* rounded-lg */
  cursor: pointer;
}

.SendButton:hover {
  background-color: #3182CE; /* A darker shade of blue for hover effect */
}


.card{
    background-color: var(--color-fifth);
    border: 2px solid var(--color-accent2);
    box-shadow: 0px 0px 10px 0.2px var(--color-primary);
    text-align: center;
    padding: 2rem 1rem;
    /* border-radius: 0 3rem 0 3rem; */
    transition: var(--transition);
}

.card:hover{
    background: transparent;
    background-color: var(--color-fourth);
    cursor: default;
}

.card span{
    width: 3rem;
    height: 3rem;
    background-color: var(--color-fourth);
    color: var(--bg-primary);
    font-size: 1.5rem;
    padding: 0.8rem;
    display: grid;
    place-items: center;
    margin-bottom: 1.5rem;
    margin-inline: auto;
    border-radius: 1rem;
    transition: var(--transition);
}

.card:hover span{
    color: var(--color-accent2);
    background-color: var(--color-fifth);
}

.card p{
    margin: 0;
    display: block;
    font-weight: 300;
    
}
.detailsText{
    color: var(--bg-primary);
    font-weight: bold;
}

@media screen and (min-width: 1024px) {
    /* .card{
        background-color: var(--color-fifth);
        border: 2px solid var(--color-accent2);
        box-shadow: 0px 0px 10px 0.2px var(--color-primary);
        text-align: center;
        padding: 2rem 6rem;
        height: 20rem;
        border-radius: 0 3rem 0 3rem;
        transition: var(--transition);
    } */
}


@media screen and (max-width: 480px) {
    
    .card p {
        margin: 0;
        display: block;
        font-weight: 300;
        position: relative;
        /* bottom: 2rem; */
        /* width: 18rem; */
        font-size: 0.8rem;
        padding-left: 1rem;
        text-align: center;
    }
  

}
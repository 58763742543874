.navbar {
    width: 99vw;
    margin-left: 0.4vw;
    background-color: var( --color-fourth); /* Example color - Material UI primary color */
    padding: 8px 16px; /* Adjust padding as needed */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    display: flex;
    justify-content: flex-end; /* Position button to the right */
    align-items: center;
    border-radius: 1rem;
   
  }
  .heading{
    margin-right: 28%;
    color: var(--bg-primary);
    font-weight: bolder;
    font-family: "Montserrat", sans-serif;
  }
  .searchBtn {
    color: var(--bg-primary); /* Button text color */

  }
  .searchBtn:hover {
    color: var(--bg-primary); /* Button text color */
    background-color: var(--color-fourth);
    box-shadow: var(--bg-primary);

  }
  
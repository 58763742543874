.caseDetailContainer {
  background-color: var(--color-fourth); 
  margin-left: 23%;
  margin-top: 2%;
  margin-bottom: 3% ;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.caseDetail {
  margin-top: 20px;
}

.caseDetail h1 {
  color: var(--bg-primary);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
}

.caseDetail p, .caseDetail strong {
  color: var(--bg-primary);
  font-size: 1rem;
  line-height: 1.6;
  margin: 10px 0;
}

.caseDetail strong {
  font-weight: bold;
}

.error {
  color: var(--color-danger);
  text-align: center;
}

.caseDetail a {
  color: var(--color-accent2); 
  transition: var(--transition); 
  font-weight: bolder;
}

.caseDetail a:hover {
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
  transition: var(--transition);
}

@media (max-width: 1366px) {
  .caseDetailContainer {
    background-color: var(--color-fourth);
    margin: 2% auto; /* Center align the container and apply top and bottom margins */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Maintain a max-width for readability */
    width: 90%; /* Adjust width for better side margins */
    box-sizing: border-box;
    position: relative;
  }

  .caseDetail h1 {
    font-size: 2.2rem; /* Slightly reduce font size for h1 */
    margin-bottom: 20px; /* Keep consistent bottom margin for spacing */
  }

  .caseDetail p, .caseDetail strong {
    font-size: 1rem; /* Maintain readable font size for body text */
    line-height: 1.6; /* Keep line height for better readability */
    margin: 10px 0; /* Consistent margin for paragraph spacing */
  }

  .caseDetail a {
    color: var(--color-primary);
    transition: var(--transition);
    font-weight: bolder;
    /* No change in font size on hover to maintain consistency */
  }

  .caseDetail a:hover {
    padding: 5px; /* Slight padding on hover for clickable effect */
    cursor: pointer;
    transition: var(--transition);
  }

  /* Adjusting error class for better visibility */
  .error {
    color: var(--color-danger);
    text-align: center;
  }
}

@media (max-width: 580px) {
  .caseDetailContainer {
    margin-left: 5%; /* Reduced margin for smaller screens */
    margin-right: 5%; /* Ensure consistent spacing on all sides */
    width: 90%; /* Use more width for smaller devices */
  }

  .caseDetail h1 {
    font-size: 1.5rem; /* Smaller font size for h1 */
  }

  .caseDetail p, .caseDetail strong {
    font-size: 0.875rem; /* Smaller font size for text */
  }
}

/* Media Query for Tablets (up to 980px) - Adjustments for better readability */
@media (max-width: 980px) and (min-width: 581px) {
  .caseDetailContainer {
    margin: 2% 10%; /* Adjusting margins for tablet view */
    width: 80%; /* Adjust width to provide more breathing space */
  }

  .caseDetail h1 {
    font-size: 2rem; /* Adjusted font size for better readability on tablets */
  }
}

/* Additional Media Query for smaller devices (up to 400px) - Fine-tuning */
@media (max-width: 400px) {
  .caseDetail h1 {
    font-size: 1.5rem; /* Further reduce font size for very small devices */
  }

  .caseDetailContainer {
    margin: 2% auto; /* Centering the container */
    width: 95%; /* Maximizing usable space */
  }
}
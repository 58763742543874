.formContainer {
  width: 490px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  border-radius: 5px;
  margin-top: 11px;
  background-color: var(--bg-primary);
}
.Heading{
  text-align: center;
  color: var(--color-primary);
}

.BtnContainer{
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.formField {
  margin-bottom: 15px;
  
}
/* Add this CSS for the new fields on the same horizontal line */
.statusPriorityFields {
display: flex;
justify-content: space-between;
gap: 15px; /* Adjust the gap between fields */
}

.statusPriorityFields {
flex: 1;
padding: 10px;
border: 1px solid var(--bg-secondary);
border-radius: 4px;
font-size: 16px;
box-shadow: inset 0px 0px 4px 0px var(--color-Third);
}


.label {
  margin-top: 1px;
  color: var(--color-fifth);
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  transition: border-color 0.3s; /* Add a subtle transition effect */

}

.inputField:focus, .selectField:focus,.date:focus, .cdate:focus{
  border: 2px solid var(--color-primary);
}


.horizontalFields {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectField {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  transition: border-color 0.3s; /* Add a subtle transition effect */

}
.date {
  width: 90%;
  padding: 10px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  transition: border-color 0.3s; /* Add a subtle transition effect */

  
}
.cdate {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  transition: border-color 0.3s; /* Add a subtle transition effect */ 
}

.link{
  color: var(--color-primary);
}

.link:hover {
  text-decoration: underline;
}

/* Hover and active styles for submit button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.CancelButton {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
}

.CancelButton:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

.error {
  color: var(--color-danger);
  font-size: 12px;
  margin-top: 1px;
}

.labelTitel{
  /* margin-left: 0.48rem; */
  color: aliceblue;
}

.inputFieldTitel{
  height: 2rem;
  width: 100%;
}

@media (max-width: 600px) {
  .formContainer {
    width: 100%; 
    max-width: none; 
    padding: 10px; 
    height: 41rem;
  }
  .statusPriorityFields {
    flex-direction: column;
  }

  .statusPriorityFields {
    width: 100%; /* Reset the width for mobile */
  }
  .horizontalFields {
    flex-direction: row; 
    justify-content: space-between; 
  }

  .selectField {
    width: 160%; 
  }
  .selectField:focus{
    border: 1px solid var(--color-secondary);
  }
  .link {
      color: var(--color-primary);
    }
    
    .link:hover {
      text-decoration: underline;
    }
}

@media (max-width: 480px) {
  .formContainer {
    width: 100%; /* Full width */
    padding: 8px; /* Reduced padding for more space */
    border: 1px solid var(--color-btn-bg); /* Keeping the border consistent */
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1); /* Soft shadow for depth */
    border-radius: 5px; /* Smoothed corners */
    margin-top: 11px; /* Consistent top margin */
    background-color: var(--bg-primary); /* Background color */
    height: auto; /* Height adjusts to content */
  }

  .Heading {
    text-align: center;
    color: var(--color-primary);
  }

  .BtnContainer {
    display: flex;
    justify-content: center;
    gap: 0.8rem; /* Maintain gap for button spacing */
  }

  .formField {
    margin-bottom: 14px;
  }

  /* Adjusting fields to stack vertically */
  .statusPriorityFields {
    display: flex;
    flex-direction: column; /* Stack fields vertically */
    gap: 11px; /* Consistent gap between fields */
  }

  .statusPriorityFields,
  .selectField,
  .date,
  .cdate {
    width: 20rem; /* Full width for easy tapping */
    /* height: 2rem; */
    /* padding: 4px; */
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 11px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    transition: border-color 0.3s; /* Smooth transition on focus */
  }

  .inputField:focus, .selectField:focus, .date:focus, .cdate:focus {
    border: 2px solid var(--color-primary); /* Highlight focus */
  }

  /* Adjusting button container for better usability */
  .BtnContainer,
  .horizontalFields {
    display: flex;
    flex-direction: column; /* Stack buttons vertically for easier access */
    align-items: center; /* Center align for aesthetics */
    gap: 10px; /* Gap between buttons */
  }

  .submitButton,
  .CancelButton {
    width: 100%; /* Full width buttons for easier tapping */
    padding: 10px 0; /* Adequate padding for touch targets */
    margin-top: 10px; /* Space above buttons */
  }

  .submitButton:hover, .CancelButton:hover {
    transform: scale(1.05); /* Slight increase on hover for feedback */
  }

  .link, .link:hover {
    text-decoration: underline; /* Underline links for clarity */
    color: var(--color-primary); /* Consistent color for links */
  }

  .error {
    font-size: 12px; /* Slightly larger error text for readability */
  }
  .labelTitel{
    margin-left: 0.48rem;
    color: aliceblue;
  }

  .inputField{
    height: 2rem;
    width: 19rem;
  }
  .inputFieldTitel{
    height: 2rem;
    width: 21rem;
  }
}

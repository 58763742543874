.timelineContainer {
    max-width: 960px;
    max-height: 48vh;
    margin: 0 auto;
    padding: 20px;
    background: #f0f0f0; /* Light grey background for a futuristic yet minimal look */
    font-family: 'Roboto', sans-serif; /* Modern, clean font */
    overflow-y: scroll;
  }
  
  .timelineEvent {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }
  
  .timelineEvent:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 32px;
    height: 100%;
    border-left: 2px dashed #ccc; /* Dashed line for a sleek look */
    top: 24px;
    z-index: 1;
  }
  
  .timelineEventIcon {
    background: linear-gradient(145deg, #0093E9, #80D0C7); /* Futuristic gradient for the icons */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  
  .timelineContent {
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 15px;
    margin-left: 20px;
    transition: all 0.3s ease;
  }
  
  .timelineContent:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover for dynamic interaction */
  }
  
  .timelineDate {
    font-size: 0.85em;
    color: #999;
  }
  
  /* Add more futuristic styles here */
  
:root {
  /* --color-primary: #6200ee;  */
  /* --color-fourth: #03dac6; */
  /* --sidebar-width: 250px;  */
  --sidebar-transition: all 0.5s ease-in-out; 
  --fancy-gradient: linear-gradient(135deg, var(--bg-primary), var(--bg-accent)); 
}

.navContainer {
  height: 100vh;
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  left: 0;
  background: var(--fancy-gradient); /* Use the fancy gradient here */
  overflow-x: hidden;
  padding-top: 5px;
  z-index: 5;
  transition: var(--sidebar-transition);
  box-shadow: 3px 0 15px rgba(0,0,0,0.2); /* More pronounced shadow for 3D effect */
  transform: translateX(-100%);
  backdrop-filter: blur(10px); /* Slight blur effect behind the sidebar */
  overflow-x: auto; 
}

.open {
  transform: translateX(0);
}

.close {
  pointer-events: none;
}


.hamBurger{
  font-size: 1.8rem;
  position: fixed;
  cursor: pointer;
  top: 0rem;
  display: flex;
  justify-content: start;
  z-Index: 6;
  color: var(--color-secondary);
  margin: 1rem;
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
}

.closeIcon {
  font-size: 1.8rem;
  position: fixed;
  cursor: pointer;
  top: 0rem;
  z-Index: 6;
  color: var(--color-secondary);
  margin: 1rem;
  transition: transform 0.3s ease; /* Smooth transition for hover effects */
}

.hamBurger:hover,
.closeIcon:hover {
  transform: scale(1.2); /* Slightly enlarge icons on hover */
}



.closeIcon {
  font-size: 2rem;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
  width: 15rem;
}

.linksContainer ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-top: -10px;
}

.linksContainer li {
  padding: 15px 20px;
  text-decoration: none;
  font-size: 1.2rem;
  color: white; /* Change to pure white for better contrast against the gradient */
  display: block;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}

.linksContainer li:hover {
  color: var(--color-fourth);
  background: rgba(255, 255, 255, 0.2); /* Lighter background color on hover for better visibility */
  transform: translateX(10px); /* Move text slightly to the right on hover */
}

.linksContainer a {
  text-decoration: none;
  color: inherit;
  display: block;
  transition: color 0.3s ease;
}

.linksContainer a:hover {
  color: var(--color-fourth);
}

.listContainer svg {
  font-size: 1.5rem;
  margin-right: 1rem;
  transition: transform 0.3s ease; /* Add a transition to the SVG icons */
}

.listContainer svg:hover {
  transform: scale(1.2); /* Enlarge the SVG icons on hover */
}

/* Dropdown styles */
.dropdown {
  position: relative; 
}

.dropdownMenu li{
  padding: 0;
  font-size: 0.8rem;
  font-family: sans-serif;
  width: 120%;
}
.BellIcon{
  font-size: 1.6rem;
}


.dropdownToggle {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: var(--color-fifth); 
  padding: 1px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s ease-in-out;
}

.dropdownToggle:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Change for hover state */
}

.dropdownToggle svg {
  transition: transform 0.3s ease-in-out;
}

.dropdownMenu {
  display: none; 
  flex-direction: column;
  padding-left: 0; 
  position: absolute;
  top: 100%; 
  left: 0;
  right: 0; 
  background: var(--fancy-gradient); 
  border-radius: 0 0 4px 4px; 
  overflow: hidden;
  z-index: 10;
}

/* Showing dropdown menu when dropdown is active */
.dropdown:hover .dropdownMenu,
.dropdownToggle:focus + .dropdownMenu,
.dropdownToggle:active + .dropdownMenu {
  display: flex; /* Show the dropdown */
}

.dropdownItem {
  /* color: white; */
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease-in-out;
}
.NotifiCount{
  position: absolute;
  top: 31rem;
  left: 0rem;
  /* right: 10rem; */
  transform: translate(100%, -50%);
  background-color: var(--color-danger);
  color: var(--color-fifth);
  border-radius: 50%;
  padding: 0 5px;
  min-width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownItem:hover {
  /* background-color: rgba(255, 255, 255, 0.2); */
}

/* ... your existing CSS ... */

/* Responsive design */
@media (max-width: 768px) {
  .navContainer {
    width: 55%;
    box-shadow: none;
    font-size: 1rem;
  }

  .linksContainer {
    margin-top: 5rem;
    height: auto; /* Set height to auto to accommodate multiple links */
  }

  .linksContainer li {
    padding: 10px 20px; /* Adjust padding for better appearance on small screens */
    font-size: 1rem; /* Adjust font size for better appearance on small screens */
  }

  .linksContainer a {
    font-size: 1rem; /* Adjust font size for better appearance on small screens */
  }

  .dropdownToggle {
    font-size: 1rem; /* Adjust font size for better appearance on small screens */
    padding: 10px 20px; /* Adjust padding for better appearance on small screens */
  }

  .dropdownItem {
    padding: 8px 20px; /* Adjust padding for better appearance on small screens */
    font-size: 0.8rem; /* Adjust font size for better appearance on small screens */
  }

  .notificationsCount{
    background-color: red;
    padding: 0rem 0.2rem;
    border-radius: 5rem;
  }
}

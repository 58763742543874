
.container {
  width: 70%; 
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  margin-top: 40px;
}
.fileInputContainer{
  display: flex;
  flex-direction: column;
}
.heading{
  font-weight: bolder;
  font-size: 26px;
  text-align: center;
  color: var(--color-fifth);
}
.required {
  color: var(--color-danger); /* Adjusted color */
  font-weight: bold; /* Added emphasis */
  font-size: large;
}
.BtnContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}


.form {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;  
  
}
.row1 {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;  
  
}
.column {
  display: flex;
  flex-direction: column;
  
}
.columnAp {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  
}
.columnAlert {
  display: flex;
  flex-direction: column;
  margin-left: 22%;
  
}
.columnDoc {
  display: flex;
  flex-direction: column;
  margin-left: 22%;
}
.columnTeam {
  display: flex;
  flex-direction: column;
  margin-left: 1%;
  
}
.label {
  
  color: var(--color-fifth);
}
.labelCaveat2{
 
  color: var(--color-fifth);
  margin-left: 50%;
}
.labelManage{
   
  color: var(--color-fifth);
  margin-left: 55%;
}
.labelNotes{
 
  color: var(--color-fifth);
  margin-left: 103%;
}
.labelTeam{
  
 
  color: var(--color-fifth);
  
}
.labelln{
  margin-left: 1%;

  color: var(--color-fifth);
  
}
.labelei{
  margin-left: 1%;
  
  color: var(--color-fifth);
  
}
.input {
  flex: 1;
  padding: 5px;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
 
  
}

.inputDate {
  width: 136%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
}
.inputTitle {
  width: 180%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  font-size: 16px;
  margin-right: 30px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
}
.inputCaveat1 {
  width: 190%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
}
.inputCaveat2 {
  width: 110%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  margin-left: 200px;
  
}
.inputopn {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
  
}
.inputln {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  margin-left: 2px;
  
}
.inputmn {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
  
}
.inputei {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  margin-left: 2px;
  
}


.radioGroup {
  margin-bottom: 10px;
}

.radio {
margin-left: 10px;
width: 15px; 
height: 15px;
cursor: pointer;
vertical-align: middle;  
}


.select {
  flex: 1;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
}
.selectClient {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
}
.selectTeam {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px; 
}

.selectCd {
  width: 41%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
}
.selectCd:focus, .selectTeam:focus , .selectClient:focus{
  border: var(--color-accent1);
}


.selectPa {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  
}
.selectManage {
  width: 330%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  margin-left: 50%;
  
}
.selectCaseStatus {
  width: 100%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
}

/* Textarea */
.textarea {
  width: 192%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  height: 100px;
  font-size: 16px;
}
.textareaNotes {
  width: 220%;
  padding: 5px;
  border: 1px solid var(--color-primary);
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  border-radius: 4px;
  font-size: 16px;
  height: 100px;
  margin-left: 100%;
}

/* Error Messages */
.error {
  color: var(--color-danger);
  font-size: 12px;
  margin-top: 5px;
}
.error1 {
  color: var(--color-danger);
  font-size: 12px;
  margin-top: 5px;
  text-align: end;
  margin-right: 8.7rem;
}

.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  
}
.CancelButton {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.CancelButton:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

/* Heading */


.form, .row, .row1 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.link:hover {
  text-decoration: underline;
}
.linkTeam{
  
  color: var(--color-accent1);}

.column, .columnAp, .columnAlert, .columnDoc, .columnTeam {
  flex: 1;
  min-width: 220px;
}
.linkTeam:hover {
  text-decoration: underline;
  color: var(--color-accent2);
}
.linkClient{
  color: var(--color-accent1);
  
}
.linkClient:hover {
  text-decoration: underline;
  color: var(--color-accent2);
}

@media screen and (max-width: 980px) {
  .row, .row1, .column, .columnTeam {
    flex-direction: column;
    width: auto;
  }

  .labelln, .labelei {
    margin-left: 0;
  }
}


/* Hide the default radio button */
.radio {
  display: none;
}

/* Style for the label that acts as the custom radio button */
.radio + label {
  padding: 8px 15px;
  margin-right: 10px;
  border: 2px solid #1976d2; /* Blue border color */
  border-radius: 20px; /* Rounded corners for the label */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  user-select: none; /* Prevent text selection */
}

/* Change label style when the radio button is checked */
.radio:checked + label {
  background-color: #1976d2; /* Blue background color */
  color: white; /* White text color */
}

/* Optional: change the label style on hover */
.radio + label:hover {
  background-color: #1565c0; /* Slightly darker blue */
  color: white;
}


.fileInput {
  
  width: 20%;
  padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--color-primary);
    background-color: var(--color-fifth);
    border-radius: 4px;
    font-size: 14px;
  
}

/* Style for the label that acts as the custom upload button */
.labels {
  display: inline-block;
  background-color: #1976d2; /* Blue background color */
  color: #fff; /* White text color */
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.labels:hover {
  background-color: #1565c0; /* Slightly darker blue on hover */
}

/* Style for the required asterisk */
.required {
  color: red;
  margin-left: 4px;
}

/* Optional: Style to display selected file name */
.fileNameDisplay {
  margin-left: 10px;
  font-size: 16px;
}


@media (max-width: 850px) {
  .fileInput {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .input {
    width: 14.5rem;
  }
}
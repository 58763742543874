/* Add these styles to your existing CSS file */
.resultContainer{
  width: 95%; 
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  margin-top: 40px;
}
/* Search container start */
.tagLine{
  font-size: 1.2rem;
  margin-top: 4rem;
  font-weight: 500;
  font-family: sans-serif;
 margin-bottom: 1%;
}

.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 8px;
  margin: 2rem 0;
}
.result{
  color: var(--color-danger);

}

.noResults p {
  color: var(--bg-primary);
  
  font-size: 1.2rem;
  font-weight: bold;
}

.noResultsIcon {
  font-size: 3rem; /* Large size for the icon */
  display: block; /* Ensures it's on a new line */
  margin-bottom: 1rem; /* Space between the icon and text */
}


.law{
  color: var(--color-fifth);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.searchContainer {
  display: flex;
  align-items: center;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-primary);
  padding: 15px;
  width: 100%;
  max-width: 800px;
  /* margin-top: 4rem; */
  border-radius: 8px;
  
}

.SearchName,
.searchSelect {
  border: none;
  outline: none;
  font-size: 18px;
  padding: 15px 10px;
  width: 25rem;
  flex: 1;
  border-radius: 6px;
  background: var(--color-fourth);
  box-shadow: 0px 0px 10px 0.2px var(--color-primary);
  margin-right: 10px;
}

.SearchName::placeholder,
.searchSelect::placeholder {
  color: var(--bg-primary);
  font-weight: 300;
}

.btn {
 
  border: none;
  background-color: var(--color-btn-bg); 
  color: var(--color-fifth);
  padding: 12px 24px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 50px;
}

.btn:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.animateerror {
  animation: shake 0.5s ease-in-out forwards;
}


.resultTitle {
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-size: 2.5rem;
  color: var(--color-primary);
  font-weight: 700;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
}

.searchTerm {
  margin: 1rem 0;
  color: var(--color-secondary);
  font-weight: 500;
}

/* Modern Table styles */
.tableHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-radius: 8px;
  transition: background 0.3s ease;
  color: var(--color-fifth);
  background: var(--bg-secondary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-primary);
  font-weight: bolder;
}

.tableContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tableColumn {
  flex-basis: calc(12% - 12px);
  text-align: left;
  padding: 1rem;
  margin-right: 100px;
  font-weight: bolder;
}

/* Styling for Case Number */
.caseNo {
  background-color: var(--color-secondary);
  color: var(--color-fifth);
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
}

/* .tableRow:hover {
  background-color: var(--color-fifth); 
} */

.tableDataLink {
  text-decoration: none;
  width: 15rem;
  color: var(--bg-primary);
  /* color: var(--color-fourth); */
  transition: color 0.3s ease;
}

.tableDataLink:hover {
  color: var(--color-fifth); 
  font-weight: bolder;
  
}
.table {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates two columns of equal width */
  gap: 20px; /* Adjust the gap between the cards */
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 62%;
  width: 100%;
  height: 100%;
  
  transition: var(--bg-primary) 0.3s ease;
  box-shadow: 0 2px 4px var(--color-primary);
  
}

.previousButton,
.nextButton {
  margin-right: 10px; /* Adjust this value as needed */
  
}

.previousButton .prev,
.nextButton .next {
  margin-left: 5px; /* For .previousButton only */
  margin-right: 5px; /* For .nextButton only */
  color: var(--color-fourth);
  
}



.currentPageIndicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 38px;
  border-radius: 50%;
  color: var(--color-fifth); /* Ensure this variable is defined in your CSS or root */
  font-weight: bold;
  background-color: var(--bg-accent); /* Ensure this variable is defined */
  margin: 0 10px;
  transition: var(--bg-primary) 0.3s ease; 
   box-shadow: 0 2px 4px var(--color-primary); 
  border: 1px solid var(--bg-secondary);
}
.currentPageIndicator1{
  color: var(--color-fourth);
  font-weight: bold;
}
.currentPageIndicatorCoulmn{
  display: flex;
  flex-direction: column;
}

/* Chart container with a modern look */
.chartContainer {
  margin-top: 2rem;
  width: 1rem;
  text-align: center;
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pieChart canvas{ 
  height: 40%;
  width: 40%;
}

.chartContainer h4 {
  margin-bottom: 1rem;
  color: var(--color-text);
  font-weight: 500;
  font-size: 1.4rem;
}

/* Additional Styles for Card */
.card {
  width: 100%;
  /* background-color:  var(--color-fifth); */
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0.2px var(--color-primary);
  margin: 1rem;
  padding: 1rem;
  /* transition: transform 0.2s ease; */
  /* background-color: var(--color-fourth); */
}

.card:hover {
  
  background-color: var(--color-fourth);
  transform: scale(1.03);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

}
.classifier{
  color: var(--bg-primary);
  font-weight: bolder;
}

/* Additional Styles for Chart */
.chartContainer .react-doughnut-chart {
  width: 100%;
  height: 100%;
}

.suggestionContainer {
  position: absolute;
  max-height: 200px;
  width: 25rem;
  overflow-y: auto;
  border: 2px solid var(--bg-secondary);
  border-top: none;
  z-index: 1000;
  color: var(--color-fifth);
  background-color: var(--bg-primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestion {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.suggestion:hover {
  background-color: var(--color-fourth);
  color: var(--bg-primary);
}


@media (max-width: 1081px) {

  .container { /* Assuming this is the flex container */
    display: flex;
    flex-direction: column; /* Stack children vertically */
  }

  .resultContainer {
    padding: 15px;  /* Slightly reduce padding */
    margin-top: 20px; /* Reduce top margin */
    margin-bottom: 20px; /* Reduce bottom margin */
  }

  .tagLine {
    font-size: 1.1rem; /* Slightly reduce font size */
    margin-top: 3rem; /* Reduce the top margin */
  }

  .searchContainer {
    max-width: 700px; /* Adjust max width for better fit */
  }

  .SearchName, .searchSelect {
    width: 100%; /* Full width for better usability */
    padding: 10px 8px; /* Adjust padding */
  }

  .btn {
    padding: 10px 20px; /* Slightly reduce padding */
    font-size: 16px; /* Adjust font size for buttons */
  }

  .noResultsIcon {
    font-size: 2.5rem; /* Reduce the size of icons */
  }

  .resultTitle {
    font-size: 2rem; /* Reduce title font size */
  }

  .tableColumn {
    padding: 8px; /* Reduce padding in table headers and columns */
  }
  .tableHeader{
    display: none;
  }

  .tableDataLink {
    width: auto; /* Allow links to fit content */
  }

  .chartContainer {
    flex-basis: 50%; /* Adjust the flex basis to make charts more prominent */
    order: -1; /* Moves the chartContainer to the top */
  }

  .card {
    margin: 0.5rem; /* Reduce margin around cards */
  }

  .paginationContainer {
    margin-left: 50%; /* Center the pagination container more effectively */
    transform: translateX(-50%); /* Adjust positioning */
    position: relative;
    left: 10rem;
  }

  .suggestionContainer {
    width: calc(100% - 20px); /* Adjust width to allow for padding */
    left: 10px; /* Adjust left positioning */
  }
}

@media (max-width: 800px) {
  .searchContainer {
    flex-direction: column;
    padding: 15px;
    align-items: stretch;
  }
  .tableHeader{
    display: none;
  }

  .SearchName,
  .searchSelect,
  .btn {
    width: 100%;
    margin: 5px 0; 
  }

  .suggestionContainer {
    width: calc(100% - 32px); 
    left: 16px; 
  }

  .tableContainer {
    /* flex-direction: column-reverse; */
  }

  .tableHeader,
  .tableColumn {
    flex-basis: 100%; 
  }

  .chartContainer {
    /* flex-basis: 100%; */
    width: 80%;
    height: 80%;
  }
  .tagLine{
    text-align: center;
  }
  
}

/* Further adjustments for even smaller screens */
@media (max-width: 480px) {
  .resultContainer,
  .resultTitle,
  .searchTerm {
    padding: 1rem;
    font-size: 1.2rem;
  }
  .card {
    width: 100%;
    /* background-color:  var(--color-fifth); */
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0.2px var(--color-primary);
    margin: 0.5rem;
    padding: 1rem;
    /* transition: transform 0.2s ease; */
    /* background-color: var(--color-fourth); */
  }
  .table {
    display: grid;
    grid-template-columns: repeat(1, 1fr); /* Creates two columns of equal width */
    gap: 20px; /* Adjust the gap between the cards */
  }

  .paginationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
    width: 100%;
    height: 100%;
    transition: var(--bg-primary) 0.3s ease;
    box-shadow: 0 2px 4px var(--color-primary);
  }
  
}

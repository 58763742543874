/* NotificationSetting.module.css */
.MainContainer{
  margin-top: 2rem;
  height: 100%;
  max-height: 88vh; /* Maximum height set to 80% of the viewport height */
  overflow-y: auto;
  box-shadow: 0px 4px 20px 0px var(--color-accent1);
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
}



.container {
  /* background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 4px 20px 0px var(--color-accent1);  */
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  
  max-width: 400px;
   
  }

  .inputField {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid var(--color-primary); /* Light grey border */
    background-color: var(--color-fifth);
    color: var(--bg-primary);
  }
  
  /* ... rest of your styles */
  
  
  .title {
    color: var(--color-fifth); /* Yellow color for the title */
    text-align: center; /* Centering the title */
    margin-bottom: 30px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .sectionTitle {
    color: var(--color-fifth); /* Yellow color for section titles */
    margin-bottom: 10px;
  }
  
  .checkboxLabel {
    display: block; /* Block display to allow margin */
    margin: 10px 0;
    cursor: pointer; /* Cursor pointer for better UX */
    
  }
  .label{
    color: var(--color-fourth);
  }
  
  .checkboxInput {
    margin-right: 10px; /* Spacing before the label text */
  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 1rem;
  }
  
  /* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  color: var(--color-btn-bg);
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  
  
}
.cancelButton {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.cancelButton:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}
  .toggleSwitch {
    position: relative;
    display: inline-block;
    width: 40px; /* Adjusted width */
    height: 22px; /* Adjusted height */
    margin-left: 10px;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-fourth);
    transition: .4s;
    border-radius: 22px; /* Adjusted to new height to maintain the pill shape */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px; /* Adjusted for visual appeal */
    width: 18px; /* Adjusted for visual appeal */
    left: 2px; /* Smaller offset */
    bottom: 2px; /* Smaller offset */
    background-color: var(--color-fifth);
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color:var(--color-accent2);
  }
  
  input:checked + .slider:before {
    transform: translateX(18px); /* Adjusted to new slider width */
  }
  
  /* Keep the focus style the same or adjust as desired */
  input:focus + .slider {
    box-shadow: 0 0 1px var(--color-secondary);
  }

  .notificationOption{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  
  
.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px); 
  z-index: 1050; 
}

.TotalContainer {
  background-color: var(--color-fifth);
  border: 0px solid;
  border-radius: 0.5rem;
  /* padding: 1rem; */
}

.closeButton {
  position: relative;
  top: 0rem;
  bottom: 3.8rem;
  left: 17rem;
  /* right: 20rem; */
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.subscriptionOptionsContainer {
  margin: 2rem auto;
  padding: 2rem;
  z-index: 1051;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 40%;
  height: 39rem;
}

.Discount {
  border: 1px;
  background-color: #007bff;
  color: white;
  font-weight: 700;
  font-size: 0.7rem;
  width: 4rem;
  border-radius: 0.18rem;
}

.BeforeDiscount {
  text-decoration: line-through;
  color: grey;
  margin-right: 0.5rem;
}

.Label {
  display: flex;
  margin-bottom: 0.4rem;
  font-size: 0.8rem;
  color: #333;
  border: 1px solid;
  padding: 1rem;
  border-radius: 0.25rem;
  flex-direction: row;
}

input[type="radio"] {
  margin-right: 0.5rem;
}

.totalCost {
  text-align: left;
}

.Tearms {
  font-size: 0.8rem;
  text-align: left;
  margin-top: 4rem;
}

.TearmConditions {
  color: var(--color-accent2);
  font-weight: 700;
}

.Amount {
  text-align: right;
}

.totalCost p {
  margin: 0.5rem 0;
}

.paymentButton {
  display: block;
  width: 100%;
  padding: 1rem;
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paymentButton:hover {
  background-color: #0056b3;
}

.couponSection {
  margin-top: 1rem;
}

.couponHeadingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.couponHeading {
  margin: 0; /* Remove default margins to align properly */
}

.couponInputContainer {
  display: flex;
  gap: 0.5rem;
}

.Heading, .SubHeading {
  text-align: left;
}

.SubHeading {
  margin-bottom: 2rem;
}

.Price {
  border: 0px solid;
  background-color: var(--color-fifth);
  color: var(--color-primary);
  font-weight: 600;
  border-radius: 0.2rem;
  width: 4rem;
}

.couponInput {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.applyButton {
  padding: 0rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.applyButton:hover {
  background-color: #0056b3;
}

.couponToggleLink {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.discountApplied {
  color: green;
  font-weight: bold;
}
/* Base styles remain unchanged */

/* Styles specifically targeting devices with widths up to 1080px */
@media (max-width: 1080px) {
  .subscriptionOptionsContainer {
    position: relative;
    width: 80%; /* Adjust width for better fit on smaller screens */
    height: auto; /* Flexible height based on content */
    padding: 2rem; /* Adequate padding for touch interaction */
    margin: 2rem auto; /* Centering the modal in the viewport */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth perception */
  }

  .closeButton {
    position: absolute; /* Positioning close button absolutely within the modal */
    top: 0rem; /* Adjusting top position to align better */
    bottom: 45rem;
    right: 00rem; /* Ensuring it does not overlap the content */
    left: 36.5rem;
    font-size: 2rem; /* Making the button larger for easier interaction */
    z-index: 1052; /* Ensuring it's above other elements */
  }

  .couponInputContainer, .couponHeadingContainer, .Label, .totalCost {
    flex-direction: column; /* Stacking elements vertically for better readability */
  }

  .couponInput {
    flex-grow: 1; /* Allowing input to take available space */
    margin-top: 0.5rem; /* Spacing between label and input */
  }

  .applyButton {
    width: 100%; /* Button takes full width for easier access */
    margin-top: 0.5rem; /* Spacing after input field */
  }

  .paymentButton {
    margin-top: 2rem; /* More space above the payment button for clarity */
    padding: 1rem; /* Larger padding for better touch interaction */
  }

  .Label {
    padding: 1rem; /* Adequate padding for labels */
    margin-bottom: 1rem; /* Spacing between form elements */
  }
}



/* Styles for devices up to 768px in width, targeting smaller tablets */
@media (max-width: 768px) {
  .subscriptionOptionsContainer {
    width: 95%; /* Utilize more screen width for better layout */
    padding: 1rem; /* Adjust padding to save space */
    margin: 1rem auto; /* Center the container with automatic margins */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    height: auto; /* Let height grow with content */
  }

  .closeButton {
    left: calc(100% - 35px); /* Adjust close button position */
    font-size: 1.5rem; /* Ensure the button is large enough to tap easily */
  }

  .couponInputContainer, .couponHeadingContainer, .Label, .totalCost {
    flex-direction: column; /* Stack elements vertically for better access */
  }

  .couponInput {
    order: 2; /* Ensure input is below the label in mobile layout */
  }

  .applyButton {
    order: 1; /* Place button above the input for intuitive access */
  }

  .paymentButton {
    padding: 0.8rem; /* Adjust padding for touch friendliness */
  }
}

/* Additional adjustments for very small mobile devices up to 460px */
@media (max-width: 460px) {
  .subscriptionOptionsContainer {
    width: 95%; /* Allow modal to use more of the screen */
    padding: 0.5rem; /* Reduce padding to save space */
    margin: 0.5rem; /* Minimal margin */
    font-size: 0.875rem; /* Adjust font size for readability */
    height: 95%;
  }

  .closeButton {
    top: 0.5rem;
    right: 0.5rem;
  }

  .Label, .totalCost, .couponSection, .paymentButton, .couponInputContainer {
    flex-direction: column; /* Ensure all interactive elements are stacked for easy reach */
    padding: 0.5rem;
  }

  .Discount {
    font-size: 0.875rem; /* Adjust font size for small screens */
  }

  .Tearms, .Amount, .info {
    font-size: 0.875rem; /* Ensure text is readable but doesn't take too much space */
  }

  .couponInput, .applyButton, .paymentButton {
    font-size: 0.875rem; /* Smaller font size for compact display */
  }
  .Label {
    display: flex;
    margin-bottom: 0.4rem;
    font-size: 0.8rem;
    color: #333;
    border: 1px solid;
    padding: 1rem;
    border-radius: 0.25rem;
    flex-direction: row;
  }
}
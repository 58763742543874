.MainContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formContainer {
  width: 90%;
  max-width: 600px;
  padding: 20px;
  margin: auto;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  border-radius: 5px;
  box-shadow: 0 4px 20px var(--color-accent1);
}

.heading {
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  color: var(--color-fifth);
  margin-bottom: 20px;
}

.fieldContainer, .clientContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.inputField, .selectFieldClient, .selectFieldType, .inputFieldDate, .textareaField {
  width: 100%;
  padding: 0.7rem 0.8rem; /* Standardize padding */
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  height: 2.5rem; /* Uniform height for all input types */
  font-size: 1rem; /* Larger font size for readability */
  margin-bottom: 0.8rem; /* Consistent spacing */
}

.BtnContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.submitButton, .buttonCancel {
  width: calc(50% - 5px); /* Account for gap */
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

.submitButton:hover, .buttonCancel:hover {
  background-color: var(--color-fourth);
  transform: translateY(-2px);
}

.link {
  color: var(--color-accent1);
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
  color: var(--color-accent2);
}
.label{
  color: WHITE;
}

.invoiceNo{
  color: var(--color-accent2);
}

 /* Submit Button */
 .submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
}

.selectFieldTypeDate{
  height: 2.5rem;
  width: 100%;
  border-radius: 0.2rem;
  margin-bottom: 0.8rem;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .heading {
    font-size: 22px;
  }

  .formContainer {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .heading {
    font-size: 20px;
  }

  .formContainer {
    padding: 15px;
  }

  .submitButton, .buttonCancel {
    width: 100%;
  }

  .selectFieldTypeDate{
    height: 2.5rem;
    width: 20.8rem;
    border-radius: 0.2rem;
    margin-bottom: 0.8rem;
  }
}

/* Default styles for larger screens */
.benfits {
    margin-top: 9rem;
    padding: 2rem;
}
.heading{
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
  }
  
.benfitsWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
    margin-top: 4rem;
}
.info{
    color: var(--bg-primary);
}
/* Add this inside your services.module.css */

@keyframes slideRightToLeft {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  .movingServicesWrapper {
    display: flex;
    animation: slideRightToLeft 30s linear infinite;
    
  }
  

  @media screen and (max-width: 1024px) {

    
    .benfitsWrapper {
        display: grid;
        grid-template-columns: repeat(2 , 1fr);
        gap: 3rem;
        margin-top: 4rem;
    }

    .programsProgram{
        width: 100%;
    }
  }

/* Styles for medium-sized screens */
@media screen and (max-width: 768px) {
    section {
        margin-top: 6rem;
    }

    .benfitsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .benfitsWrapper {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/* Styles for smaller screens */
@media screen and (max-width: 480px) {
    section {
        margin-top: 4rem;
    }

    .benfitsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .benfitsWrapper {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}

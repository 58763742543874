/* Base styles for the testimonials section */
.testimonials {
    width: 50rem; /* Fixed width for larger screens */
    margin-inline: auto; /* Center the testimonials */
}

.testimonialsHead {
    justify-content: center; /* Center the header contents */
}

.testimonial {
    position: relative;
    text-align: center;
    margin-top: 5rem; /* Spacing from the top */
    height: 14rem; /* Fixed height for larger screens */
    background-color: var(--color-fifth);
    border: 2px solid var(--color-accent2);
    /* box-shadow: 0 0 10px 0.2rem var(--color-primary); */
}

.testimonial:hover {
    background-color: var(--color-fourth); /* Color change on hover */
}

.testimonialAvatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%; /* Circular avatar */
    overflow: hidden;
    position: absolute;
    top: -2rem; /* Position above the card */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Ensure centering is accurate */
    box-shadow: 0 0.1rem 0.5rem rgba(26, 26, 54, 0.4);
}

.info{
    font-style: italic;
    margin: 1rem 0 2rem;
    color: var(--bg-primary);
    font-weight: bolder;
}

.testimonialBtnContainer {
    width: fit-content;
    margin: 2rem auto 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.testimonialsBtn {
    background: transparent;
    cursor: pointer;
}

.testimonialsBtn svg {
    color: var(--color-accent2);
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
}

.testimonialInfo h5 {
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-primary);
    font-weight: bolder;
}

.testimonialTitle {
    position: absolute;
    padding: 2rem;
    color: var(--color-primary);
    font-weight: bolder;
}

/* Responsive styles for medium-sized screens */
@media screen and (max-width: 768px) {
    .testimonials {
        width: 90%; /* Increase width percentage for smaller screens */
    }

    .testimonial {
        margin-top: 3rem; /* Adjust top margin */
        height: auto; /* Make height dynamic based on content */
    }

    .testimonialAvatar {
        width: 3.5rem;
        height: 3.5rem;
    }
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 480px) {
    .testimonials {
        width: 90%; /* Ensure full utilization of screen width */
    }

    .testimonial {
        margin-top: 2rem; /* Less top margin for compact layout */
    }

    .testimonialAvatar {
        width: 4rem;
        height: 4rem;
        position: relative;
        bottom: 0.5rem;    
    }

    .testimonialInfo h5, .testimonialTitle {
        position: static; /* Remove absolute positioning */
        padding: 1rem 0; /* Add padding for better spacing */
        text-align: center; /* Center align the text for better readability */
    }
}

.myChatsContainer {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    background-color: var(--color-fifth); /* Soft background color */
  }

  .headerBox {
    display: flex;
    
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    padding: 0.75rem 1rem;
    background-color: var(--bg-primary); /* Dark gray background for contrast */
    color: var(--color-fifth);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  
  .GroupBtn {
    padding: 0.5rem 1rem;
    background-color: #3182CE; /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  
  .GroupBtn:hover {
    background-color: #4299E1; /* A lighter shade of blue for hover state */
  }
  
  .chatsBox {
    flex: 1;
    overflow-y: auto; /* Allows scrolling through chats */
    padding: 1rem;
  }
  
  .chatItem {
    /* background-color: #FFFFFF; */
    border-radius: 0.375rem; /* 6px */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 0.75rem 1rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    margin-top: 0.4rem;
    
  }
  
  .chatItem:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
  
  .chatName {
    font-weight: bold;
    color: #2D3748; /* Dark gray for text */
  }
  
  .latestMessage {
    color: #4A5568; /* Slightly lighter gray for subtext */
    font-size: 0.875rem;
  }

  .ModalContainer{
    backdrop-filter: blur(10px);
    margin: '0 auto';
  }
  .GroupHeading{
    font-size: 2rem;
    font-weight: 700;
    color: var(--color-fifth);
  }

  .CreateBtn{
    padding: 0.5rem 1rem;
    background-color: #3182CE; /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  .CreateBtn:hover{
    background-color: #59a3e8; /* Chakra's blue.500 */
    color: white 
  }

  .CancelBtn{
    padding: 0.5rem 1rem;
    background-color: var(--color-danger); /* Chakra's blue.500 */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 0.375rem; /* 6px */
    font-size: 1rem;
    transition: background-color 0.2s;
  }
  .CancelBtn:hover{
    background-color: var(--color-dangerHover); /* Chakra's blue.500 */
    /* color: var(--color-dangerHover); */
  }
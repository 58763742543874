.container {
  border-radius: 8px;
  height: auto; /* Adjusted for responsiveness */
  border: 1px solid;
  box-shadow: 0px 0px 18px 2px var(--color-accent1);
  margin-top: 2.9rem;
  margin-left: auto; 
  margin-right: auto; 
  width: 90%; 
}

.th{
  width: 5rem;
}

.td{
  color: white;
}

.DataColume {
  color: var(--color-fifth);
}

.heading {
  /* color: #333; */
  color: white;
  margin-bottom: 15px;
  text-align: center;
}

.table-container {
  overflow-x: auto;
  overflow-y: auto; /* Updated for vertical scrolling */
  max-height: 300px; /* Adjusted for responsiveness */
  margin-bottom: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.tableHead {
  background-color: var(--color-fourth);
  color: white;
}

.tableHead th {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  top: 0;
  background-color: inherit; /* Ensure the sticky header has a background */
}

.tableBody tr:hover {
  /* transform: scale(1.03); */
}

.tableBody .td {
  padding: 10px;
  text-align: left;
}

.btn {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 15px;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem; /* Adjusted for better spacing */
  position: relative;
  width: auto; /* Adjusted for responsiveness */
  left: 12rem; /* Reset positioning for responsiveness */
}

.previousButton, .nextButton {
  margin-right: 10px;
}

.previousButton .prev, .nextButton .next {
  margin: 0 5px;
  color: var(--color-fourth);
}

.td{
  color: white;
}

.Sno {
  width: 5rem !important; /* Ensure this width takes precedence */
}

/* Apply the same width setting to the corresponding 'td' elements */
.tableBody .Sno {
  width: 5rem !important; /* Make sure this is not overridden */
}

.currentPageIndicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 5rem;
  color: var(--color-fifth);
  font-weight: bolder;
  background-color: var(--bg-accent);
  margin: 0 10px;
  border: 1px solid var(--bg-secondary);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .container, .paginationContainer {
      width: 95%; /* Wider container on smaller screens */
  }
  .table {
    width: 100%;
    
  }

  .table-container {
    overflow-x: auto; /* Enables horizontal scrolling if the table is wider than the container */
    overflow-y: hidden; /* Hides vertical scrollbar, adjust as needed */
    width: 100%; /* Adjust based on your layout needs */
    max-height: 500px; /* Example for vertical scrolling, adjust based on your needs */
  }
  
  .btn, .previousButton, .nextButton, .currentPageIndicator {
      padding: 8px 12px; /* Adjusted for touch-friendliness */
      font-size: 14px; /* Adjusted for readability on smaller screens */
  }
}

@media (max-width: 768px) {
  .container, .paginationContainer {
    width: 100%; /* Use the full width on very small screens */
  }
  
  .table-container {
    overflow-x: auto; /* Enables horizontal scrolling if the table is wider than the container */
    overflow-y: hidden; /* Hides vertical scrollbar, adjust as needed */
    width: 100%; /* Adjust based on your layout needs */
    max-height: 500px; /* Example for vertical scrolling, adjust based on your needs */
  }
  
  
  .heading, .tableHead th, .tableBody .td {
    font-size: 14px; /* Smaller font size for space efficiency */
  }
  
  .btn, .previousButton, .nextButton, .currentPageIndicator {
    padding: 6px 10px; /* Smaller buttons for compact layout */
    font-size: 12px; /* Smaller text for consistency */
  }
}
@media (max-width: 480px) {
    .container {
      overflow-x: auto; /* Allows horizontal scrolling */
      overflow-y: auto; /* Allows vertical scrolling */
      padding: 10px; /* Adds some padding for better content display */
      /* Other styles remain the same */
    }

    .paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem; /* Adjusted for better spacing */
      position: relative;
      left: 6rem; /* Reset positioning for responsiveness */
    }
}

.walletContainer {
  padding: 30px;
  border: 1px solid var(--color-btn-bg);
  background-color: var(--bg-primary);
  border-radius: 10px; /* More pronounced rounded corners */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 21rem; /* Slightly wider for better layout */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions for interactions */
  z-index: 100000;
  right: 18.5rem;
  top: 8.5rem;
  position: absolute
}

.blurContainer {
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2); 
  display: flex;
  justify-content: center;
  align-items: center; /* Center the child wallet container */
  z-index: 1000; /* Ensure it's above other content */
}


.walletContainer::before {
  content: "";
  position: absolute;
  top: -8px;
  font-size: 1.4rem;
  right: 5px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--bg-primary);
}
.walletContainer:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 10px 15px var(--bg-primary), 0 8px 20px var(--bg-primary); /* Increased shadow on hover for depth */
}

.walletBalance {
  font-size: 1.5em; /* Larger font size for emphasis */
  color: var(--color-fifth);
  font-weight: 600; /* Added weight for balance visibility */
  margin-bottom: 20px; /* Increased spacing for clarity */
  
}

.transferButton, .formButton {
  padding: 12px 24px; /* More padding for larger click area */
  border: none;
  border-radius: 8px; /* More rounded corners */
  cursor: pointer;
  margin-top: 15px; /* Increased spacing */
  font-weight: 500; /* Medium font weight for readability */
  letter-spacing: 0.5px; /* Slight spacing for elegance */
  width: 100%; /* Full width buttons */
  
}

.transferButton {
  background-color: var(--color-btn-bg); 
  color: var(--color-fifth); /* Subtle shadow for depth */
}

.transferButton:hover, .formButton:hover {
  opacity: 0.9; /* Subtle feedback on hover */
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
}

.formButton {
  background-color: var(--color-secondary);
}

/* Enhance form and input styles for consistency and aesthetics */
.transferForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px; /* More space between form elements */
}

.transferInput {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid var(--bg-secondary); /* Lighter border for subtlety */
  width: 100%; /* Full width to match button */
  box-shadow: inset 0 1px 2px --color-secondary; /* Inner shadow for depth */
  transition: border-color 0.3s ease; /* Smooth transition for focus */
}

.transferInput:focus {
  border-color: var(--color-third); /* Highlight focus with color */
  box-shadow: inset 0 2px 4px var(--bg-primary), 0 0 0 2px var(--bg-primary); /* Increased depth and focus ring */
}

/* Adjust the top margin for the first child of the form for visual balance */
.transferForm > *:first-child {
  margin-top: 20px;
}

.input{
  border-radius: 0.2rem;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  
}

.input:focus{
  border: 2px solid var(--color-accent1);
}
@media (max-width: 1080px) {
  .walletContainer {
    width: 40%; /* Adjust width specifically for 1200px screen */
    right: 20%; /* Center the container by adjusting right */
    padding: 25px; /* Slightly more padding for better aesthetics */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  }
  .formContainer{
    padding: 1rem;

  }

  .walletBalance, .transferButton, .formButton {
    font-size: 1.2em; /* Increase font size for better readability */
  }

  .transferButton, .formButton {
    padding: 14px 28px; /* Increase padding for a better click area */
    margin-top: 18px; /* Slightly more margin for better spacing */
  }

  .transferInput, .input {
    padding: 14px; /* More padding for easier interaction */
    margin-bottom: 15px; /* Adjust margin-bottom for consistency */
    gap: 1rem;
  }

  .blurContainer {
    background-color: rgba(0, 0, 0, 0.25); /* Slightly darker for better focus on walletContainer */
  }
}

@media (max-width: 768px) {
  .walletContainer {
    width: 90%; /* Make the container take up most of the screen */
    right: 5%; /* Center the container */
    top: 10%; /* Adjust top positioning */
    padding: 20px; /* Reduce padding */
  }

  .walletBalance, .input, button {
    font-size: 1em; /* Adjust font size for readability */
  }

  button {
    padding: 10px 20px; /* Adjust button padding */
  }

  /* Adjust input fields for smaller screens */
  .input {
    padding: 10px;
    margin-bottom: 10px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .walletContainer {
    width: 73%; /* Adjust width for tablet screens */
    right: 10%; /* Adjust right positioning to center */
    top: 10%; /* Adjust top positioning */
  }

  /* Similar adjustments as in mobile, but slightly less drastic */
  .walletBalance, .input, button {
    font-size: 1.2em; /* Slightly larger font size for better readability on tablets */
  }

  button {
    padding: 12px 22px; /* Comfortable padding for better tap targets */
    margin-top: 15px; /* Consistent vertical spacing between buttons and other elements */
  }
  
  .input {
    padding: 0.3rem 0.3rem; 
    margin: 0.5rem; /* Increased bottom margin to create a noticeable gap between input fields */
    
  }
}

/* Enhance the focus state for better accessibility */
.input:focus {
  outline: none; /* Remove the default browser outline */
  border: 2px solid var(--color-accent1); /* Make the focus state prominent with a thicker border */
}


/* Sidebar  */
.Sidebar {
    grid-area: sidebar;
    height: 100%;
    background-color: #263043;
    overflow-y: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
  }
  
  .SidebarTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0px 30px;
    margin-bottom: 30px;
  }
  
  .SidebarTitle > span {
    display: none;
  }
  
  .SidebarBrand {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 700;
  }
  
  .SidebarList {
    padding: 0;
    list-style-type: none;
  }
  
  .SidebarListItem {
    padding: 20px 20px 20px 20px;
    font-size: 18px;
  }
  
  .SidebarListItem:hover {
    background-color: red(255, 255, 255, 0.2);
    color: red;
    cursor: pointer;
  }
  
  .SidebarListItem > a {
    text-decoration: none;
    color: #9e9ea4;
  }

  /* Sidebar styles */
  .sidebar {
    width: 250px; /* Adjust width as needed */
    position: fixed; /* Position it relative to the viewport */
    left: 0;
    top: 0;
    bottom: 0;
    transform: translateX(-100%); /* Start off-screen */
    transition: transform 0.5s ease; /* Smooth transition for the transform */
    background-color: #263043; /* Sidebar background color */
    overflow-y: auto; /* Allows scrolling */
    z-index: 100; /* Ensure it's above other content but below the open/close buttons */
  }

  .sidebarResponsive {
    transform: translateX(0); /* Move into view */
  }

  .closeSidebarBtn,
.openSidebarBtn {
  background-color: var(--bg-primary);
  color: white;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Add transition for opacity */
}

.closeSidebarBtn {
  position: fixed;
  top: 25rem;
  left: 260px; 
  z-index: 101; 
}

.openSidebarBtn {
  position: fixed;
  align-items: center;
  top: 25rem;
  left: 20px;
  z-index: 101; 
}

/* .Container {
  background-image: url('../../../../assets/dashboard.jpg');
} */

.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 81.7vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  perspective: 1000px; /* Adds perspective to the children elements */
}

.ContainerLeft,
.ContainerRight {
  width: 80%;
  max-width: 600px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  color: var(--bg-primary);
  background-color: var(--color-fourth);
  box-shadow: 0px 10px 20px 0px var(--color-primary), inset 0px 0px 15px 0px rgba(0,0,0,0.2); /* Updated shadow for 3D effect */
  height: 40%;
  margin: 10px;
  transform: rotateX(10deg) rotateY(10deg); /* Adds a subtle 3D tilt */
  transition: transform 0.5s ease-in-out; /* Smooth transition for hover effect */
}

.ContainerLeft:hover,
.ContainerRight:hover {
  transform: rotateX(0deg) rotateY(0deg); /* Resets the tilt on hover */
  background-color: var(--bg-secondary);
  
}

.Caselinks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 20px;
}

.heading {
  font-size: 2.5rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
  color: var(--bg-primary);
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Adds shadow to text for depth */
}
.hovered {
  color: var(--color-fifth); /* Change the color based on your theme */
}

.Caselinks li {
  list-style: none;
  margin-bottom: 4px;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px 0px var(--color-primary);
  background-color: var(--color-fourth);
}

.Caselinks a {
  display: inline-block;
  color: var(--color-fifth);
  border: 1px solid var(--button-border-color);
  box-shadow: 0px 4px 6px var(--color-primary);
  background: linear-gradient(145deg, var(--bg-primary), var(--color-fourth));
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  width: 11rem;
   /* Shadow for 3D effect */
}

.Caselinks a:hover {
  font-size: 1.2rem;
  font-weight: 700;
  width: 12.9rem;
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

@media screen and (max-width: 1150px) {
  .MainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    perspective: 1000px; /* Adds perspective to the children elements */
  }

}

@media screen and (max-width: 768px) {
  .ContainerLeft,
  .ContainerRight {
    width: 98%;
  }

  .Caselinks a {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .MainContainer {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
  }

  .Caselinks a {
    width: 11rem;
  }

  .ContainerLeft,
  .ContainerRight {
    width: 15rem;
    height: 14rem;
  }

  .ContainerLeft h1,
  .ContainerRight h1 {
    font-size: 2rem;
  }
}

/* Styles for the container of the navbar */
.nav-container {
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: var(--color-primary);
    box-shadow: var(--bg-secondary) 2px 2px 15px 0px;
}
.heading{
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
    color: var(--color-accent2);
    font-family: "Montserrat", sans-serif;
  }

 
  
 

  

.taglines {
    font-size: 10px; /* Makes the text size smaller */
    font-weight: bolder; /* Makes the text bolder */
    color: var(--color-fourth); /* Sets the text color */
    margin-top: 0;
    margin-bottom: 5px;
    font-family: "Montserrat", sans-serif;
}

.nav-logo{
    margin: 0; 
    padding: 0;
    line-height: 1;
    border-bottom: 2px solid var(--color-fifth); 
    padding-bottom: 3px; 
    margin-bottom: 1px;
}

.logo-and-tagline-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 0;
}

.nav-logo  {
    font-size: 24px;
    color: var(--color-primary);
    margin-bottom: 0;
}

.logo-span{
     color: var(--color-fifth);
}


.logo{
    color: var(--color-fifth);
    font-weight: bolder;
  }
  
  /* Styles for the menu links */
  .navbar-menu-links .navbar-lists {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    height: 2.5rem;
    color: aliceblue;
  }
  
  .navbar-menu-links .navbar-lists-links {
    margin-right: 20px; 
  }
  .navbar-lists-links{
    color: white;
  }
  
  .navbar-menu-links .active-nav {
    color: var(--color-accent2); /* Example: Change to a bright yellow for high visibility */
    transition: color 0.5s;
  }

  /* Styles for the toggle button (assuming it's for mobile navigation) */
  .nav-toggle-btn{
    display: none; /* Keep the button hidden on larger screens */
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust size as needed for visibility */
    color: var(--color-secondary);
}

/* Media query for mobile responsiveness */
@media (max-width: 1150px) {
    .nav-container {
        flex-direction: column;
        padding: 10px;
        height: auto; /* Set the height to auto */
        text-align: center;
    }

    .navbar-menu-links .navbar-lists {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .navbar-menu-links .navbar-lists-links {
        margin: 0;
    }

    .navbar-menu-links .active-nav {
        display: block;
        padding: 10px 0;
    }

    .nav-toggle-btn {
        display: block;
        margin-top: 20px;
    }

    /* Add the "Login" and "Register" links in the burger menu */
    .burger-menu-links .navbar-lists {
        list-style: none;
        padding: 0;
    }

    .burger-menu-links .navbar-lists-links {
        margin: 0;
    }

    .burger-menu-links .active-nav {
        display: block;
        padding: 10px 10px;
    }

    .burger-menu-links {
        display: block;
    }

    .show-nav {
        display: flex;
    }

    .hide-nav {
        display: none;
    }

    .nav-container {
        flex-direction: column;
        padding: 10px;
        height: auto; /* Default state */
        text-align: center;
    }

    .show-nav {
        height: 30vh; /* Full viewport height when nav is open */
        /* overflow: auto;  */
    }

    .navbar-menu-links .navbar-lists {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .navbar-menu-links .navbar-lists-links {
        margin: 0;
    }

    .navbar-menu-links .active-nav {
        display: block;
        padding: 10px 0;
    }

    .nav-toggle-btn {
        display: block;
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .show-nav {
        height: 22rem; /* Adapt height to content */
        max-height: 100vh; /* Ensure it does not exceed the viewport height */
    }
}

@media (max-width: 300px) {
    .show-nav {
        height: 40vh; /* Adapt height to content */
        max-height: 10vh; /* Ensure it does not exceed the viewport height */
    }
}
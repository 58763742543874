.sectionHead{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
    justify-content: center;
}

.sectionHead span{
    color: var(--color-accent2);
    border-radius: 1rem;
    border: 0.1px solid var(--color-accent2);
    padding: 0.6rem;
}

.heading{
    color: var(--color-accent2);
    font-family: "Montserrat", sans-serif;
}

/* General styles for the partners container */
.partners {
    padding: 20px;
    background-color: #f9f9f9; /* Light background color for the section */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8rem;
}

/* Styling for the main title of the section using the Benifits component */
.main1 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem; /* Larger font size for the main title */
    color: #0056b3; /* Stylish blue for headings */
    margin-bottom: 20px; /* Space below the heading */
}

/* Customized styling for the carousel */
.custom-dot-list-style {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
}

.custom-dot-list-style li button {
    background-color: #ccc; /* Grey dots for the carousel */
    border: none;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
}

.custom-dot-list-style li.slick-active button {
    background-color: #007bff; /* Highlight color for active dot */
}

.carousel-item-padding-40-px {
    padding-right: 40px; /* Right padding for each carousel item */
}

/* Styling for each program card in the carousel */
.programsProgram {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 1rem;
    text-align: center;
    height: 15rem;
    margin: 2rem;
}



/* Responsive styles for tablet devices */
@media (max-width: 1024px) {
    .sectionHead {
        flex-direction: column; /* Stack elements vertically on tablet */
        gap: 0.5rem; /* Reduce gap for tighter vertical spacing */
    }

    .programsProgram {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        padding: 1rem;
        text-align: center;
        height: 15rem;
        margin: 2rem;
    }

    .sectionHead span {
        padding: 0.4rem; /* Smaller padding for compact display */
    }

    .main1 {
        font-size: 1.2rem; /* Smaller font size for the main title */
    }

    

    .partners {
        padding: 15px;
        height: auto; /* Adjust height to content on smaller screens */
    }

    .carousel-item-padding-40-px {
        padding-right: 20px; /* Less padding on right for carousel items */
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
    .sectionHead {
        flex-direction: column;
        gap: 0.3rem; /* Even tighter spacing for mobile */
    }

    .sectionHead span {
        padding: 0.3rem; /* Minimal padding for compact appearance */
    }

    .main1 {
        font-size: 1rem; /* Smaller font size for constrained spaces */
    }

    .programsProgram {
        padding: 0.8rem; /* Minimal padding to maximize space usage */
        margin: 0.2rem;
        height: 12rem;
    }

    .partners {
        padding: 10px;
    }

    .carousel-item-padding-40-px {
        padding-right: 10px; /* Minimal padding for carousel items on mobile */
    }
}

@media (max-width: 480px) {
    .icon{
        /* height: 0rem; */
    }

}
/* Continuing existing styles... */

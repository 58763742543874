*, *::before, *::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

/* light mode colors */
:root {
    --color-primary: #000000; /* Light blue for primary text */
    --color-secondary: #FFAB91; /* Soft orange for secondary elements */
    --color-third: #A5D6A7; /* Soft green for accents */
    --color-fourth: #B0BEC5; /* Light grey for less important elements */
    --color-fifth: #ECEFF1; /* Off-white for backgrounds */
    --color-accent1: #FFD740; /* Amber for highlighted elements */
    --color-accent2: #F48FB1; /* Pink for interactive elements */
    --bg-primary: #263238; /* Deep dark blue-grey for background */
    --bg-secondary: #37474F; /* Dark grey for secondary backgrounds */
    --bg-accent: #455A64; /* Darker grey for accent areas */
    --color-danger: #EF5350; /* Bright red for errors and warnings */
    --color-dangerHover: #f17f7d; /* Bright red for errors and warnings */
    --color-Right: #10d243;

    --color-dark-blue: #3188d3; /* Bright blue for important actions */
    --color-btn-bg: #546E7A; /* Dark grey for button backgrounds */

    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 0.3s ease;
}
/* Dark mode colors */
:root.dark {
    --color-primary: #81878a; /* Dark blue for primary text */
    --color-secondary: #FFAB91; /* Blue for secondary elements */
    --color-third: #A5D6A7; /* Bright blue for accents */
    --color-fourth: #888888; /* Light grey for less important elements */
    --color-fifth: #ECEFF1; /* Off-white for backgrounds */
    --color-accent1: #FFD740; /* Amber for highlighted elements */
    --color-accent2: #F48FB1; /* Pink for interactive elements */
    --bg-primary: #22272B; /* Already a deep dark blue-grey, close to black */
    --bg-secondary: #161A1D; /* Darker grey, closer to black */
    --bg-accent: #1C1C1C; /* Even darker, nearly black */
    --color-danger: #EF5350; /* Deep red for errors and warnings */
    --color-dangerHover: #f17f7d; /* Bright red for errors and warnings */
    --color-Right: #10d243;

    --color-dark-blue: #3188d3; /* Bright blue for important actions */
    --color-btn-bg: #343434; /* Grey for button backgrounds */

    --container-width-lg: 80%;
    --container-width-md: 90%;
    --transition: all 0.3s ease;
}



body {
    background: var(--bg-primary);
    /* color: var(--color-fifth);  */
    /* overflow-x: hidden; */
}

/* .container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
} */

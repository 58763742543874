.container {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.success_img {
    width: 200px;
    height: 200px;
    object-fit: contain; /* This ensures the image keeps its aspect ratio */
  }

.green_btn {
	border: none;
	outline: none;
	padding: 12px 0;
	background-color: var(--bg-secondary);
	border-radius: 20px;
	width: 180px;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}




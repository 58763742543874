/* AppointmentForm.module.css */

.appointmentForm {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--color-fifth);
    border: 1px solid var(--bg-secondary);
    border-radius: 5px;
    box-shadow: 0 2px 4px var(--bg-primary);
    margin-top: 50px;
  }
  
  .formSection {
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
    margin-top: 1px;
    color: var(--color-secondary);
  }
  
  .error {
    color: var(--color-danger);
    font-size: 14px;
    margin-top: 5px;
  }
  
  .horizontalFields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .style{
    width: 100%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .style1{
    width: 75%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .style2{
    width: 105%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .textarea{

    width: 100%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border-radius: 4px;
    font-size: 14px;
    height: 100px;
  }

  
  .field {
    flex: 1;
    margin-right: 15px;
  }
  
  .link{
    color: var(--color-primary);
    
  }
  .link:hover {
    text-decoration: underline;
  }
  
  .submitButton {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 6px 0px var(--color-primary);
  }
  
  @media screen and (max-width: 768px) {
    
    .appointmentForm {
        max-width: 100%;
        margin: 0 auto;
        padding: 20px;
        background-color: var(--color-fifth);
        border: 1px solid var(--bg-secondary);
        border-radius: 5px;
        box-shadow: 0 2px 4px var(--bg-primary);
        margin-top: 50px;
      }
      
      .formSection {
        margin-bottom: 15px;
      }
      .horizontalFields {
        display: flex;
    flex-direction: column;
        justify-content: space-between;
        margin-bottom: 15px;
      }
      
      .label {
        font-weight: bold;
        margin-top: 1px;
        color: var(--color-secondary);
      }
      
      .error {
        color: var(--color-danger);
        font-size: 14px;
        margin-top: 5px;
      }
      
      
      .style{
        width: 100%;
        padding: 5px;
        margin-top: 1px;
        border: 1px solid var(--bg-secondary);
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid var(--bg-secondary);
        box-shadow: inset 0px 0px 4px 0px var(--color-Third);
      }
      .style1{
        width: 100%;
        padding: 5px;
        margin-top: 1px;
        border: 1px solid var(--bg-secondary);
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid var(--bg-secondary);
        box-shadow: inset 0px 0px 4px 0px var(--color-Third);
      }
      .style2{
        width: 105%;
        padding: 5px;
        margin-top: 1px;
        border: 1px solid var(--bg-secondary);
        border-radius: 4px;
        font-size: 14px;
        border: 1px solid var(--bg-secondary);
        box-shadow: inset 0px 0px 4px 0px var(--color-Third);
      }
      .textarea{
    
        width: 100%;
        padding: 5px;
        margin-top: 1px;
        border: 1px solid var(--bg-secondary);
        box-shadow: inset 0px 0px 4px 0px var(--color-Third);
        border-radius: 4px;
        font-size: 14px;
        height: 100px;
      }
    
      
      .field {
        flex: 1;
        margin-right: 15px;
      }
      
      .link{
        color: var(--color-primary);
        
      }
      .link:hover {
        text-decoration: underline;
      }
      
      .submitButton {
        background-color: var(--color-primary);
        color: var(--color-fifth);
        padding: 10px 15px;
        border: none;
        border-radius: 0.4rem;
        cursor: pointer;
        margin-top: 10px;
        box-shadow: inset 0px 0px 6px 0px ;
      }
      .submitButton:hover {
        background-color: var(--color-fourth); 
        color: var(--color-primary);
        transform: scale(1.05); 
        box-shadow: inset 0px 0px 6px 0px var(--color-primary);
      }
    
    }
  
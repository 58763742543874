.mainHeader{
    width: 100%;
    align-items: center;
    margin-top: 0rem; 
    display: grid;
    place-items: center;
    /* background-image: url(https://law.gok.pk/wp-content/uploads/2020/07/top-law-firm-pakistan.jpg); */
    background-image: url('../../../assets/lawfaxMain.png');
    background-repeat: no-repeat;
    background-position: center; /* Centers the background image */
    background-size: cover;
    height: 87vh;
} 

.heading{
    font-weight: bolder;
    font-size: 40px;
    text-align: center;
    color: var(--color-accent2);
    font-family: "Montserrat", sans-serif;
  }

img{
    width: inherit;
    height: 40rem;
}

.Span{
    color: var(--color-fifth);
    
}

.Span1{
    color: var(--bg-primary);
    
    
}
.Span2{
    color: var(--color-fifth);
    font-size: 26px;
    
    
}
.buttonContainer {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    align-items: center;     /* Centers the button vertically if needed */
    height: 100%;            /* Adjust height as necessary */
  }
.btn {
    display: inline-block;
    padding: 0.6rem   1.4rem; 
    border-radius: 0.6rem; 
    background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
    box-shadow: inset 0px 0px 2px 0px black; 
    text-decoration: none; 
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}



.btn:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    color: var(--color-btn-bg);
    transition: var(--transition);
}

.mainHeaderLeft {
    color: var(--color-primary);
}

.Heading{
    font-size: 3rem;
    color: var(--color-primary);
}


@media screen and (max-width: 780px) {
    .Heading{
        font-size: 2.1rem;
    }

    .btn {
        display: inline-block;
        padding: 0.8rem   1.8rem; 
        border-radius: 1rem; 
        background-color: var(--color-secondary); 
        color: var(--color-fifth);
        box-shadow: inset 0px 0px 6px 0px black; 
        text-decoration: none; 
        text-align: center;
        cursor: pointer;
    }

    .mainHeader{
        width: 100%;
        align-items: center;
        margin-top: 0rem; 
        display: grid;
        place-items: center;
        background-image: url('../../../assets/lawfaxMain.png');
        background-repeat: no-repeat;
        background-position: cover;
        height: 87vh;
    } 
}

@media screen and (max-width: 480px) {
    .Heading{
        font-size: 1.4rem;
    }
    .Span2{
       
        font-size: 22px;
        
        
    }
    .btn {
        display: inline-block;
        padding: 0.6rem   1.6rem; 
        border-radius: 1rem; 
        background-color: var(--color-secondary); 
        color: var(--color-fifth);
        box-shadow: inset 0px 0px 6px 0px black; 
        text-decoration: none; 
        text-align: center;
        cursor: pointer;
    }

    .mainHeader{
        width: 100%;
        align-items: center;
        margin-top: 0rem; 
        display: grid;
        width: 100%;
        place-items: center;
        background-image: url('../../../assets/lawfaxMain.png');
        background-repeat: no-repeat;
        background-position: cover;
        background-repeat: round;
        height: 87vh;
    } 
}
/* ScrollableChat.module.css */
.chatContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 10px;
  height: 65vh; /* Adjusted dynamically below */
}

/* Hide scrollbar */
.chatContainer::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar width */
  background: transparent;  /* Optional: just to ensure the scrollbar is not visible */
}

.messageBox {
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 12px;
  word-wrap: break-word;
  max-width: 90%; /* Allow messages to take more width on smaller screens */
}

.sender {
  margin-left: auto;
  background-color: #90cdf4; /* Lighter blue for sender */
  text-align: right;
  /* width: 500px;  */
}

.receiver {
  margin-right: auto;
  background-color: #bee3f8; /* Different shade for receiver */
  text-align: left;
}

.senderName, .messageContent, .messageTimestamp {
  margin: 0;
}

.messageTimestamp {
  font-size: 0.75rem; /* Increase base font size, then adjust for smaller screens */
  opacity: 0.7;
}

/* Responsiveness */
@media (max-width: 768px) {
  .messageBox {
    padding: 6px; /* Slightly reduce padding */
    max-width: 95%; /* Allow messages to use more width */
  }

  .chatContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    padding: 10px;
    height: 70vh; /* Adjusted dynamically below */
  }
  
  .sender {
    margin-left: auto;
    background-color: #fcd4f1; /* Lighter blue for sender */
    text-align: right;
    max-width: 500px;
  }
  
  .receiver {
    margin-right: auto;
    background-color: #fcd4f1; /* Different shade for receiver */
    text-align: left;
  }
  
  .senderName, .messageContent, .messageTimestamp {
    margin: 0;
  }
  
  .messageTimestamp {
    font-size: 0.65rem; /* Adjust font size for smaller devices */
  }
}

@media (max-width: 480px) {
  .chatContainer {
    height: 58vh; /* Adjust height for mobile devices */
  }

  .messageBox {
    /* width: 40%;  */
  }

  .sender, .receiver {
    /* For very small screens, you might not need to change the max-width,
       but consider other adjustments here if needed */
  }

  .messageTimestamp {
    font-size: 0.6rem; /* Further reduce font size for small screens */
  }
}

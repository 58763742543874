/* ClientForm.module.css */

.container {
  padding: 20px;
  height: auto; /* Changed from 100vh to auto for better responsiveness */
  border-radius: 8px;
  margin: 20px;
 
}
  .DataColume{
    color: var(--color-fifth);
  }
  
  .heading {
    color: var(--color-fifth);
    margin-bottom: 15px;
    text-align: center;
    font-weight: bolder;
  }
  
  .titleColumn {
    width: 20%; 
  }


.table {
  width: 100%;
  min-width: 600px; /* Adjust based on your table's content */
  border-collapse: collapse;
  
}

  .tableHead {
    background-color: var(--bg-secondary);
    color: var(--color-fifth);
    margin-bottom: 1rem;
  }
  
  .tableHead th {
    color: var(--color-fifth);
    padding: 10px;
    text-align: left;
    border-top: 2px solid var(--bg-accent);
    border-bottom: 2px solid var(--bg-accent);
    /* border-right: 2px solid var(--bg-accent);
    border-left: 2px solid var(--bg-accent); */
  }
  
  .tableBody {
    background-color: var(--color-fourth);
    
    color: var(--color-accent2);
    font-weight: bold;
    font-size: 0.9rem;
  }


   .tableBody tr:hover {
    transform: scale(1.03);
    background-color: var(--color-fifth);
    box-shadow: 0 10px 20px var(--bg-primary), 0 6px 6px var(--bg-primary);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
.btn {
  background-color: var(--color-fourth);
  color: var(--bg-secondary);
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px var(--bg-primary);
  margin-top: 2%;
  
}

.tableBody tr:hover .btn {
  background-color: var(--bg-fifth); 
  color: var(--bg-secondary); 
  
}
  .tableBody td {
    padding: 4px;
    text-align: left;
    color: var(--bg-primary);
   
  }
  
  
  .paginationContainer {
    position: relative;
    left: 38rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 207%; */
    width: 80%;
    margin-top: 20px;
    transition: var(--bg-primary) 0.3s ease;
    box-shadow: 0 2px 4px var(--color-primary);
  }
  
  .previousButton,
  .nextButton {
    margin-right: 10px; /* Adjust this value as needed */
    
  }
  
  .previousButton .prev,
  .nextButton .next {
    margin-left: 5px; /* For .previousButton only */
    margin-right: 5px; /* For .nextButton only */
    color: var(--color-fourth);
    
  }
 
  
  .currentPageIndicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 38px;
    border-radius: 50%;
    color: var(--color-fifth); /* Ensure this variable is defined in your CSS or root */
    font-weight: bold;
    background-color: var(--bg-accent); /* Ensure this variable is defined */
    margin: 0 10px;
    transition: var(--bg-primary) 0.3s ease; 
     box-shadow: 0 2px 4px var(--color-primary); 
    border: 1px solid var(--bg-secondary);
  }
  
  .currentPageIndicator1{
    color: var(--color-fourth);

  }
  
  @media (max-width: 1080px) {

    .paginationContainer {
      position: relative;
      left: 17rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      /* background-color: red; */
      box-shadow: 0 2px 4px var(--color-primary);
    }
    
  }
  
  @media (max-width: 768px) {
    .container {
      /* Adjust the styles for smaller screens */
      padding: 10px;
      max-width: 100%;
      overflow-x: auto; /* Enables horizontal scrolling */

    }
  
    .table {
      min-width: 600px; /* Adjust this value based on your table's content */
    }
  
    .tableHead th, .tableBody td {
      /* Reduce padding and font size for smaller screens */
      padding: 5px;
      font-size: 14px; /* Adjust font size as needed */
    }

    .paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-left: 207%; */
      position: relative;
      left: 0;
      width: 80%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      box-shadow: 0 2px 4px var(--color-primary);
    }
    
  
    .btn {
      /* Adjust button sizes and layout for smaller screens */
      padding: 8px 10px;
      font-size: 14px;
    }
  }

  @media (max-width: 480px) {

    .paginationContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      left: 17rem;
      width: 80%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      box-shadow: 0 2px 4px var(--color-primary);
    }
    
  }
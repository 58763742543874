/* ChatPage.module.css */
.container {
    display: flex;
    height: 85vh;
    width: 80%;
    gap: 1rem;
}
  
  .rightSidebar {
    width: 50%;
    height: 4rem; 
  }
  
  .myChats {
    width: 25%;
    height: 100%;
    left: 0.5rem; 
    padding: 0.4rem;
    /* position: absolute; */
    top: 8rem;
    background-color: var(--color-fifth);
    border-radius: 1rem;
  }
  
  .singleChat {
    width: 65%;
    height: 100%; 
    flex-grow: 0;
  }
    
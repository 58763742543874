/* ChatPage.module.css */
.container {
    display: flex;
    height: 90vh;
    width: 100%;
    
}
  
  .rightSidebar {
    width: 50%;
    height: 4rem; 
    
  }
  
  .myChats {
    width: 19rem;
    height: 80%;
    left: 0.5rem; 
    padding: 0.4rem;
    position: absolute;
    top: 8rem;
    background-color: var(--color-fifth);
    border-radius: 1rem;
  }
  
  .singleChat {
    width: 40%;
    height: 50%; 
    flex-grow: 0;
    /* display: none; */
    position: relative;
    right: 8rem;
    
  }


  @media (max-width: 1150px) {
    .container {
      flex-direction: column; /* Stack elements vertically */
  }

  .rightSidebar, .myChats, .singleChat {
      width: 100%; 
      flex: none; 
      height: auto; 
  }

  .myChats {
      position: relative; /* Adjust if necessary, could be static */
      top: unset; /* Reset top positioning */
      left: unset; /* Reset left positioning */
      order: 1; /* Ensure order if needed */
  }

  .singleChat {
  /* width: 100%;
  height: 0%; 
  flex-grow: 0;
  position: relative;
  top: 0rem;
  right: 0rem; */
  /* left: 22rem; */
  width: 100%; /* Full width to utilize screen space */
    height: auto; /* Height based on content, could be set to a specific value if needed */
    position: relative; /* Ensures normal flow */
    top: 0;
    left: 0;
    order: 1; /* Adjust order if necessary */

  }

  .rightSidebar {
      height: auto; 
      order: 0; 
  }

  .hideOnMobile {
      display: none !important; /* Force hiding */
    }
  }
  
  @media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack elements vertically */
    }
  
    .rightSidebar, .myChats, .singleChat {
        width: 100%; 
        flex: none; 
        height: auto; 
    }
  
    .myChats {
        position: relative; /* Adjust if necessary, could be static */
        top: unset; /* Reset top positioning */
        left: unset; /* Reset left positioning */
        order: 1; /* Ensure order if needed */
    }
  
    .singleChat {
    /* width: 100%;
    height: 0%; 
    flex-grow: 0;
    position: relative;
    top: 0rem;
    right: 0rem; */
    /* left: 22rem; */
    width: 100%; /* Full width to utilize screen space */
      height: auto; /* Height based on content, could be set to a specific value if needed */
      position: relative; /* Ensures normal flow */
      top: 0;
      left: 0;
      order: 1; /* Adjust order if necessary */

    }

    .rightSidebar {
        height: auto; 
        order: 0; 
    }

    .hideOnMobile {
        display: none !important; /* Force hiding */
      }
}
@media (max-width: 480px) {
    .container {
      flex-direction: column; /* Ensures vertical stacking for simplicity */
      height: 100%; /* Full screen height */
    }
  
    .rightSidebar,
    .myChats,
    .singleChat {
      width: 100%; /* Full width to utilize screen space */
      height: auto; /* Height based on content, could be set to a specific value if needed */
      position: relative; /* Ensures normal flow */
      top: 0;
      left: 0;
      order: 1; /* Adjust order if necessary */
    }
  
    .singleChat {
      /* Ensure singleChat fills the screen when active */
      /* display: ${isChatVisible ? 'block' : 'none'}; */
      height: 100%; /* Full height */
    }
  
    .hideOnMobile {
      display: none !important; /* Hide elements not needed in mobile view */
    }
  }
  
.MainContainer{
    height: 100%;
  }
  
  .formContainer {
      width: 400px;
      height: 100%;
      margin: 0 auto;
      padding: 1rem;
      background-color: var(--bg-primary);
      border: 1px solid var(--color-btn-bg);
      box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    
      border-radius: 5px;
      margin-top: 1rem;
      
    }

    .BtnContainer{
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
    .Heading{
      text-align: center;
      color: var(--color-primary);
    }
    
    .imageUpload {
      text-align: center;
      margin-left: 100px;
    }
    
    .imageLabel {
      display: block;
      width: 150px;
      height: 150px;
      border: 2px dashed var(--color-accent1);
      border-radius: 50%;
      line-height: 150px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
    }
    
    .uploadedImage {
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
    
    .emptyImage {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color:var(--color-fifth);
      color: var(--color-primary);
      padding: 20px; 
      font-size: 10.5px; 
      font-weight: 1000;
    }
    
    
    .imageInput {
      display: none;
    }
    
    .fieldGroup {
      margin-bottom: 5px;
    }
    
    .inputField {
      width: 100%;
      padding: 10px;
      border: 1px solid var(--bg-secondary);
      border-radius: 4px;
      font-size: 16px;
      height: 30px;
      box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    }
    
    .selectField {
      width: 150%;
      padding: 5px;
      border: 1px solid var(--bg-secondary);
      border-radius: 4px;
      font-size: 16px;
      height: 30px;
      box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    }
    
    .error {
      color: var(--color-danger);
      font-size: 10px;
      
    }
    
    .horizontalFields {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .link{
      color: var(--color-accent1);
    }
    .link:hover {
      text-decoration: underline;
    color: var(--color-accent2);
    }
    .inputField:focus, .selectField:focus{
      border: 2px solid var(--color-primary);
    }
   /* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  
}
.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  font-weight: bold;
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}
    @media screen and (max-width: 768px) {
      .MainContainer{
        height: 80%;
        margin-top: 1rem;
      }
      .formContainer {
        width: 100%;
        max-width: 100%; /* Full width for smaller screens */
        margin: 0 auto;
      }
      .selectField {
          width: 100%;
          padding: 5px;
          border: 1px solid var(--bg-secondary);
          border-radius: 4px;
          font-size: 16px;
          height: 30px;
          box-shadow: inset 0px 0px 4px 0px var(--color-Third);
        }
    }
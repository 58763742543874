/* SingleChat.module.css */
.chatContainer {
  background-color: #EDF2F7;
  width: 78.5vw; 
  height: 79.5vh;
  border-radius: 1rem;
  position: relative;
  top: 3.5rem; 
  right: 20rem;
  /* padding-top: 2rem; */
}
    /* background-color: var(--color-fifth); 
    width: 78.5vw;
    height: 80vh; 
    border-radius: 1rem;
    position: relative;
    top: 3.5rem;
    right: 28rem;
    padding: 1rem;
  } */

  /* .BackIcon{
    display: none; 
  } */
  
  .placeholderText {
    font-size: 24px; 
    color: var(--bg-primary); 
    /* background-color: white; */
    /* color: var(--color-accent2); */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35rem;
    
  }
  .placeholderText1 {
    font-size: 24px; 
    color: var(--bg-primary); 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 29rem;
    
  }
  .chatHeader{
   margin-top: 0.4rem;
    color: var(--color-fifth);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    /* border-radius: 1rem; */
    font-weight: bold;
    padding: 0.35rem 1rem ;
    background-color: var(--bg-primary);
    font-size: 1.5rem;
    font-weight: 700;
    width: 77.9vw; /* Adjusted width to 100% */
    position: relative;
    top: 0.2rem;
    left: 0.2rem;
    /* bottom: 1rem; */
    text-align: center; /* Added to center the text horizontally */
  }
  
  .ConversationContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .placeholder{
    /* background-color: red; */
  }

.placeholderText {
  font-size: 20px; /* Reduce the font size for mobile view */
  color: var(--color-fifth);
  color: var(--color-accent2);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25rem; /* Reduce the height for mobile view */
}

/* .chatHeader {
  color: var(--color-accent2);
  font-size: 1.2rem; 
  font-weight: 700;
  width: 100%;
  position: relative;
  bottom: 0rem; 
  text-align: center;
} */

.ConversationContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Style/SingleChat.module.css */
.MessageInputContainer {
  display: flex;
  gap: 8px;
  margin-top: 1rem; /* Change the margin-top to 1rem for mobile view */
  padding: 8px;
  
  padding: 4px; /* py="2" and px="4" equivalent */
  align-items: center;
  background-color: #EDF2F7;
  border-radius: 0.375rem;
  position: relative;
  /* bottom: 2rem;  */
  bottom:0rem;
}

.MessageInput {
  flex: 1;
  padding: 0.5rem;
  width: 20rem;
  /* border: 2px solid red; */
  /* background-color: red; */
  position: relative;
  border-radius: 2rem;
}

.SendButton {
  padding: 0.5rem 1rem;
  /* background-color: #4299E1; */
  /* color: white; */
  background-color: var(--color-btn-bg);
    color: var(--color-fifth);
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  box-shadow: inset 0px 0px 6px 0px;
}

.SendButton:hover {
  background-color: #3182CE;
}


@media (max-width: 1150px) {
   
  .chatContainer {
    background-color: #EDF2F7;
    width: 88.5vw; 
    height: 79.5vh;
    border-radius: 1rem;
    position: relative;
    top: 1rem; 
    right: -2rem;
    /* padding-top: 2rem; */
  }

  .chatHeader{
    width: 87.8vw;
  }
  .MessageInput{
    
  }

  .MessageInputContainer { 
    position: relative;
    top: 0rem;
  }
}


@media (max-width: 768px) {
  
  .chatContainer {
    background-color: #EDF2F7;
    width: 88.5vw; 
    height: 79.5vh;
    border-radius: 1rem;
    position: relative;
    top: 1rem; 
    right: -2rem;
    /* padding-top: 2rem; */
  }

  .chatHeader{
    width: 87.8vw;
  }
  .MessageInput{
    
  }

  .MessageInputContainer { 
    position: relative;
    top: 0rem;
  }

}


/* Add media query for mobile view */
@media screen and (max-width: 480px) {
    .chatContainer {
      width: 100%;
      height: 68vh;
      position: relative;
      left: 0rem;
      /* right: 5rem; */
      top: 0rem;
    }

    .BackIcon{
      /* display: block; */
      position: relative;
      right: 0rem;
      top: 0.2rem;
    }

    .SendButton {
      padding: 0.8rem 0.8rem;
      width: 4rem;
      background-color: #4299E1;
      color: white;
      border: none;
      border-radius: 2rem;
      cursor: pointer;
    }

    .MessageInputContainer { 
      /* width: 96vw; */
      bottom: 2rem;
    }

    .MessageInput{
      width: 6rem;
    }
    .chatHeader{
      width: 98vw;
    }

  }
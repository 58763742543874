/* footer.module.css */

.MainContainer {
    background-color: var(--color-fifth); /* light grey background */
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    font-family: "Montserrat", sans-serif;
  }

  .taglines {
    font-size: 11px; /* Makes the text size smaller */
    font-weight: bolder; /* Makes the text bolder */
    color: var(--bg-primary);
    margin-top: 1.2rem;
    margin-bottom: 5px;
    font-family: "Montserrat", sans-serif;   
}
.logo-and-tagline-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Adjust as needed, or remove if not needed */
  gap: 0;
}
.LogoContainer h4 {
  margin: 0; 
  padding: 0;
  line-height: 1;
  border-bottom: 2px solid var(--bg-primary); /* Creates an underline */
   /* Adds space between the text and the underline */
  margin-bottom: 1px;
  
}
  .LogoContainer {
    margin-bottom: 20px;
  }
  .LogoSpan{
    color: var(--color-accent2);
    font-size: 2.3rem;
}
  
  .logo {
    text-decoration: none;
    color: var(--bg-primary); /* dark text color for contrast */
    font-size: 24px; /* larger font size for visibility */
    height: 1.5rem;
  }
  .logo1{
    display: flex;
    height: 14rem;
    position: absolute;
  }
  
  .FooterCopyContainer {
    margin-bottom: 20px;
  }
  
  .footerCopy {
    color:var(--bg-secondary); /* medium grey for less emphasis */
    font-size: 14px;
  }
  
  .footerSocial a {
    margin: 0 10px;
    color: #333; 
    font-size: 24px;
  }
  
  .LinkedIn:hover, .FaceBook:hover, .InstaGram:hover, .YouTuber:hover {
    color: #0056b3; /* change color on hover for visual feedback */
  }
  
  /* Additional responsive design considerations can be added as needed */
  
  @media screen and (max-width: 1366px) {
    .MainContainer {
    background-color: var(--color-fifth); /* light grey background */
    padding: 20px;
    text-align: center;
    bottom: 0;
    width: 100%;
    height: 27.7rem;
  }
  }

  @media screen and (max-width: 768px) {
    .MainContainer {
        padding: 15px; /* Slightly reduce padding */
    }

    .LogoContainer {
        margin-bottom: 15px; /* Adjust margin */
    }

    .logo1 {
        height: 10rem; /* Adjust logo size */
    }

    .footerSocial a {
        font-size: 20px; /* Adjust icon size */
    }
}

/* Responsive Design for Smartphones */
@media screen and (max-width: 480px) {
  .logo1 {
      height: 8rem; /* Further reduce logo size */
  }

  .footerSocial a {
      font-size: 18px; /* Further reduce icon size */
  }

  /* If the text is too large for small screens, adjust here */
  .footerCopy {
      font-size: 12px;
  }
}
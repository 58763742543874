

.ProfileDropdown {
  background: linear-gradient(to bottom, 
              var(--bg-secondary) 44%, /* Gradient color for the top 30% */
              var(--color-primary) 30%, /* Starting color for the bottom 70% */
              var(--color-primary) 100%); /* Ending color for the bottom 70% */
  border-radius: 6px;
  box-shadow: 0 8px 16px var(--color-primary);
  /* color: #fff; */
  font-family: 'Arial', sans-serif;
  padding: 20px;
  position: absolute;
  right: 2rem;
  top: 50px;
  width: 240px;
  z-index: 10;
  /* Rest of the styles */
}
.Input {
  background: var(--color-btn-bg); /* Dark background for contrast */
  border: 1px solid var(--bg-primary); /* Border color similar to the gradient top */
  border-radius: 4px;
  color: var(--color-fifth); /* White text color for readability */
  padding: 8px;
  margin-bottom: 12px; /* Space between each input */
  width: calc(100% - 16px); /* Full width minus padding */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.Input:focus {
  outline: none;
  border-color: var(--color-btn-bg); /* Highlight color when focused */
  box-shadow: 0 0 0 2px var(--color-btn-bg); /* Outer glow effect */
}

.ProfileDropdown::before {
  content: "";
  position: absolute;
  top: -8px;
  font-size: 1.4rem;
  right: 15px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--color-btn-bg); /* Use the darker of the gradient colors here */
}

.EditIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  color: var(--color-fifth);
  cursor: pointer;
}

.EditIcon:hover {
  color: var(--color-primary);
}

.ProfileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ProfileImage {
  background-color: #eb4d4b; 
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  margin-right: 10px;
}

.ProfileName {
  font-weight: bold;
  margin-bottom: 5px;
}

.ProfileEmail {
  color: var(--color-fifth);
  font-size: 14px;
}

.Label {
  color: var(--color-fifth);
  display: block;
  
}
/* .LabelU {
  color: var(--color-accent2);
  display: block;
  
} */
.Button {
  
  border: none;
  border-radius: 4px;
  
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;
  text-align: center;
  margin-top: 20px;
  width: 100%;
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.Button:hover {
  background-color: var(--color-fourth); 
  color: var(--color-btn-bg);
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  /* transform: scale(1.05);  */
  transform: translateY(-2px);
}

/* .Button:active {
  background: linear-gradient(to right, #4e5d94, #6a7db3);
  box-shadow: 0 2px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
} */


.Button1 {
  
  border: none;
  border-radius: 4px;
  background-color: var(--color-primary);
  color: var(--color-fifth);
  cursor: pointer;
  font-size: 14px;
  padding: 10px 16px;
  text-align: center;
  margin-top: 2px;
  margin-bottom: 15px;
  width: 100%;
  
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.Button1:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
  font-weight: bold;
  transform: translateY(-2px);
}

/* .Button1:active {
  background: linear-gradient(to right, #4e5d94, #6a7db3);
  box-shadow: 0 2px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
} */

/* Additional custom styles */
/* ... */
.avatarIconContainer {
  position: relative;
  width: 70px;
  height: 70px;
  margin: 10px auto;
}

.avatarIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--color-primary);
  background: var(--color-fifth); 
  border-radius: 50%;
  cursor: pointer;
}

.avatarIcon:hover {
  background: rgba(255, 255, 255, 0.9); 
}

/* input[type="file"] {
  display: none; 
} */

.notesContainer {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: auto;
  }
  
  .notesInput {
    width: 100%;
    height: 111px;
    padding: 10px;
    margin-bottom: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .saveButton {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0093E9;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .saveButton:hover {
    background-color: #0077CC;
  }
  
  .savedNotes {
    max-height: 21vh;
    margin-top: 20px;
    overflow-y: scroll;
  }
  
  .note {
    background-color: #f0f0f0;
    border-left: 3px solid #0093E9;
    margin-bottom: 10px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
  }
 

  .deleteButton {
    padding: 5px 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px; /* Add some space between the note text and the delete button */
  }
  
  /* Add :hover styles if you want to make the button change when the user hovers over it */
  .deleteButton:hover {
    background-color: darkred;
  }
    
  .savedNoteItem {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #f3f3f3;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .deleteButton {
    margin-left: 10px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .deleteButton:hover {
    background-color: darkred;
  }
  
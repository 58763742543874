.sectionsContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px; /* Adjust the gap between the sections */
  align-items: baseline;
  justify-content: center;
}


@keyframes moveRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


.bannerAnimation {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  animation: moveRightToLeft 10s linear infinite;
}




/* Individual section styling */
.section {
  flex: 1; /* Each section takes up equal width */
  /* Add padding, margins, etc., as needed */
}


/* Container for all notifications */
.notificationsContainer {
  display: flex;
  flex-direction: row; /* Aligns children side by side */
  flex-wrap: wrap; /* Allows items to wrap into a new row if they don't fit */
  align-items: flex-start; /* Aligns items at the start of the cross axis */
  justify-content: center;
  gap: 20px; /* Space between sections */
  padding: 20px;
  border-radius: 10px;
  color: var(--color-fifth);
  overflow: hidden; /* Ensures no overflow outside the container */
}

.alertsToggleContainer {
  position: absolute;
  top: 9%;
  right: 2%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0; /* Adjust the space between the label and the toggle */
  cursor: pointer;
  z-index: 10; /* Ensure it's above other content */
}

.alertsLabel {
  color: var(--color-fifth);
  font-size: 18px;
  font-weight: bolder;
  gap: 0;
  margin: 0; /* Removes default margin to reduce space below the label */

}
.alertsrow{
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.infoIcon {
  font-size: 21px;
  font-weight: bolder;
  margin-top: 5px;
 
  color: white; 
  
}


.alertsToggle {
  width: 40px; /* Adjust based on your preference */
  height: 20px; /* Adjust based on your preference */
  background-color: var(--color-fourth); /* Off state color */
  border-radius: 20px;
  position: relative;
  transition: background-color 0.3s;
}

.alertsToggleOn {
  background-color: var(--color-accent2); /* On state color */
}

.alertsToggleOn::before, .alertsToggleOff::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.alertsToggleOn::before {
  transform: translateX(20px); /* Adjust based on the size of your toggle */
}

.alertsToggleOff::before {
  transform: translateX(0);
}

.anchortag{
  color: var(--color-fifth);
}
.anchortag:hover {
  text-decoration: underline;
color: var(--color-accent2);
}

/* Header styling */
.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  margin-bottom: 20px;
  color: var(--color-fifth); 
  /* background-color: red; */
}

/* Individual notification item styling */
.notificationItem {
  background-color: var(--color-fourth);
  border: 1px solid var(--color-btn-bg);
  
  position: relative;
  width: 600px;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-left: 4px solid var(--color-accent2); /* Bright border for active element */
}
/* .notificationItem:hover {
  transform: scale(1.05); 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
} */

/* Styling for read and unread notifications */
.notificationTextRead, .notificationTextUnread {
  color: var(--bg-secondary); /* Light text color for readability */
}

.notificationTextUnread {
  font-weight: bold;
}

/* Dropdown toggle button styling */
.dropdownToggle {
  cursor: pointer;
  font-size: 24px;
  color: var(--color-primary); 
  background-color: var(--color-fourth);
  padding: 5px; /* Add padding for a larger clickable area */
  border-radius: 4px; /* Optional: for rounded corners */
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
}

.dropdownToggle:hover {
  transform: rotate(90deg); /* Keep the rotation */
  background-color: var(--color-accent); /* Change to a more noticeable color */
  
}


/* Dropdown menu styling */

@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    transform: scale(0.95) rotateX(-10deg);
    transform-origin: top;
  }
  100% {
    opacity: 1;
    transform: scale(1) rotateX(0deg);
  }
}
.dropdownMenu {
  /* display: none;  */
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--bg-accent);
  border-radius: 4px;
  z-index: 10;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s linear;
  animation: dropdownAnimation 0.5s ease forwards;
}

.dropdownMenuVisible {
  visibility: visible;
}

/* Menu Items */
.MenuItem {
  padding: 8px 15px;
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  font-size: 14px;
  cursor: pointer;
  
  border-radius: 4px;
  margin-bottom: 5px;
}

.MenuItem:hover {
  background-color: var(--color-fourth); 
  color: var(--color-btn-bg);
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); }

  .MenuItemDelete {
    padding: 8px 15px;
    background-color: var(--color-primary);
    color: var(--color-fifth);
    font-size: 14px;
    cursor: pointer;
    
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .MenuItemDelete:hover {
    background-color: var(--color-danger); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 1px 0px var(--color-primary);}


    @media screen and (max-width: 768px) {
      .sectionsContainer, .notificationsContainer {
        flex-direction: column;
        align-items: center;
      }
    
      .section, .notificationItem {
        width: 90%; /* Adjust width to fit smaller screens */
      }
    
      .Header{
        font-size: 1.2rem;
      }
      .alertsLabel, .notificationTextRead, .notificationTextUnread {
        font-size: 0.8rem; /* Slightly larger font for readability */
      }
    
      .dropdownToggle, .btn, .alertsToggle {
        padding: 10px; /* Larger touch targets */
      }
    
      /* Adjust other elements as needed for consistency */
    }
    

    @media screen and (max-width: 480px) {
      .sectionsContainer, .notificationsContainer {
        gap: 10px; /* Reduce space between items */
      }
    
      .section, .notificationItem {
        width: 96%; /* Use full width for mobile */
      }
    
      .alertsToggleContainer {
        top: 5%; /* Adjust positioning for visibility */
        right: 5%;
      }
      .Header{

      }
      .alertsLabel, .notificationTextRead, .notificationTextUnread {
        font-size: 0.8rem; /* Adjust font size for mobile readability */
      }
    
      .dropdownToggle, .btn, .alertsToggle {
        padding: 12px; /* Even larger touch targets for mobile */
      }

      .alertsToggleContainer{
        top: 25%;
        right: 1%;
      }
    }
    
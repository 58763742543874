.comingSoonContainer {
    background-color: var(--bg-secondary); /* Purple background */
    color: white; /* White text */
    font-family: 'Arial', sans-serif;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30vw; /* Set a fixed height for your timer */
    width: 100%; /* Full width */
    margin-top: 4%;
  }
  .title{
    font-size: 2rem;
  }
  .dec {
    font-size: 0.8rem;
    margin-bottom: 20px;
    
  }
  
  .timer {
    display: flex;
    font-size: 3rem; /* Large text for the countdown */
  }
  
  .timerComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
  }
  
  .timeValue {
    background-color: var(--color-accent2); /* Lighter purple background for the time values */
    padding: 10px 20px;
    border-radius: 5px; /* Rounded corners */
  }
  
  .timeLabel {
    margin-top: 5px;
    font-size: 1rem;
  }
  
  .timesUp {
    font-size: 3rem;
  }
  
  .closeDemoBtn {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    padding: 10px 20px;
    border: none;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
  }
  .closeDemoBtn:hover {
    background-color: var(--color-danger); 
    color: var(--color-primary);
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    
    
  }
  .buttonGroup {
    display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  }
  .submitButton {
    background-color: var(--color-btn-bg); 
      color: var(--color-fifth);
      padding: 10px 20px;
      border: none;
      margin-top: 30px;
      cursor: pointer;
      border-radius: 5px;
      font-size: 1rem;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    color: var(--color-btn-bg);
    
  }
/* Container */
.bill-form-container {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
    padding: 20px;
    height: 80%;
    background-color: var(--bg-primary);
    border: 1px solid var(--color-btn-bg);
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .error-message {
    color: var(--color-danger);
    font-size: 14px;
    margin-bottom: 10px;

   
  }
  .BtnContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .heading{
    text-align: center;
  font-weight: bolder;
  font-size: 26px;
  color: var(--color-fifth);
  }
  .billNo {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--color-accent2);
  }
  /* Labels */
  .label {
    
    margin-top: 1px;
    color: var(--color-fifth);
  }
  
  /* Input Fields */
  .input-field {
    width: 100%;
    padding: 8px;
    height: 2.5rem;
    margin-bottom: 10px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border-radius: 4px;
    margin-right: 10px;  
    
  }

  .input-fieldCurrentDate{
    width: 95%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    margin-right: 5px;
  }
  .input-fieldDateFrom{
    width: 99%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    margin-right: 10px;
  }
  .input-fieldDateTo{
    width: 110%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    margin-left: 5px;
  }
  /* Textarea */
  .textarea-field {
    width: 110%;
    height: 100px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  
  /* Select Field */
  .select-field {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  
  /* Horizontal Fields */
  .horizontal-fields {
    flex: 1 1;
    display: flex;
    /* justify-content: space-between; */
    /* margin-bottom: 10px; */
    /* margin-right: 25px; */
    gap: 2.5rem;
  }
  
  
  /* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
}

.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

 
  /* File Upload */
  .labelFile {
    margin-left: 15px;
    margin-top: 1px;
    color: var(--color-fifth);
    
  }
  .file-upload {
    width: 105%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--color-primary);
    background-color: var(--color-fifth);
    border-radius: 4px;
    font-size: 14px;
    margin-left: 15px;
  }
  
  .input-field:focus, .input-fieldCurrentDate:focus, .input-fieldDateFrom:focus,
    .input-fieldDateTo:focus, .textarea-field:focus, .select-field:focus{
      border: 2px solid var(--color-primary)
    }

    .input-field-date{
      width: 10rem;
      height: 2.5rem;
      border-radius: 0.2rem;
    }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .bill-form-container {
      max-width: 100%;
      padding: 10px; /* Reduce padding for smaller screens */
    }
  
    /* Adjust other styles as needed for smaller screens */
    .input-field {
      width: 100%;
      padding: 5px;
    }
  
    .input-fieldCurrentDate {
      width: 100%;
      padding: 5px;
    }
  
    .input-fieldDateFrom {
      width: 100%;
      padding: 5px;
    }
  
    .input-fieldDateTo {
      width: 100%;
      padding: 5px;
    }
  
    .textarea-field {
      width: 100%;
      height: 80px;
      padding: 5px;
    }
  
    .select-field {
      width: 100%;
      padding: 5px;
    }
  
    .horizontal-fields {
      flex-direction: column; /* Stack fields vertically on smaller screens */
      margin-bottom: 5px;
      width: 100%;
    }

    

  
    .submitButton {
      padding: 8px 12px; /* Adjust button padding for smaller screens */
    }
    .file-upload {
        width: 95%;
        padding: 5px;
        margin-top: 1px;
        border: 1px solid var(--color-primary);
        border-radius: 4px;
        font-size: 14px;
        
      }
  
    /* Adjust other styles for smaller screens */
  }

  @media (max-width: 480px) {
      .input-field {
      width: 100%;
      padding: 5px;
      height: 2.5rem;
      }

      .horizontal-fields {
        /* flex: 1 1; */
        display: flex;
        flex-direction: row;
        /* justify-content: space-between; */
        /* margin-bottom: 10px; */
        /* margin-right: 25px; */
        gap: 0.2rem;
      }
      .input-field-date{
        width: 6.8rem;
        height: 2.5rem;
        border-radius: 0.2rem;
      }

     
  }
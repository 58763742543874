.videoModalOverlay {
    position: fixed;
    top: 0;
    left: 0rem;
    right: 0;
    bottom: 0;
    background-color: rgba(106, 102, 102, 0.8); /* Transparency with a dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(10px); /* Blur effect for background content */
}



.modalCloseButton {
    position: absolute;
    top: 1rem;
    right: 2rem;
    border: none;
    background: none;
    font-size: 2.5rem;
    cursor: pointer;
    z-index: 1100; 
    font-weight: 700;
  }
  

.video {
    height: 34rem;
    width: 33rem;
    display: flex;
    margin: auto;
    position: fixed;
    top: 4rem; /* Start 3rem from the top of the viewport */
    left: 30rem; /* Align left edge with the .videoModalOverlay */
    z-index: 1001; /* Higher than .videoModalOverlay to ensure it's on top */
}

@media (max-width: 1024px) {

    .modalCloseButton {
        position: absolute;
        top: 1rem;
        right: 2rem;
        border: none;
        background: none;
        font-size: 2rem;
        cursor: pointer;
        z-index: 1100; 
        font-weight: 600;
      }

    .video {
        height: 34rem;
        width: 33rem;
        display: flex;
        margin: auto;
        position: fixed;
        top: 4rem; 
        left: 10rem; 
        z-index: 1001; 
    }
}

/* Media queries for smaller devices */
@media (max-width: 768px) {
    .videoModalOverlay {
        left: 0; /* Remove side margin for smaller screens */
    }

    .video {
        width: 90%; /* Make video wider on small screens */
        height: auto; /* Adjust height based on new width */
        left: 5%; /* Center the video horizontally */
        top: 10%; /* Adjust top margin for better vertical placement */
    }

    .videoModalContent {
        padding: 10px; /* Reduce padding on smaller screens */
        width: 95%; /* Increase width to use more of the small screen */
        max-width: none; /* Remove max-width restriction */
    }

    .modalCloseButton {
        font-size: 20px; /* Adjust font size for close button to fit better */
    }
}

@media (max-width: 480px) {
    .modalCloseButton {
        position: absolute;
        top: 0rem;
        right: 0rem;
        left: 20.5rem;
        bottom: 44rem;
        border: none;
        background: none;
        font-size: 2rem;
        cursor: pointer;
        z-index: 1100; 
        font-weight: 600;
      }

    .video {
        height: 34rem;
        width: 23rem;
        display: flex;
        margin: auto;
        position: fixed;
        top: 4rem; 
        left: 0.25rem; 
        z-index: 1001; 
    }
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    height: 100vh;
    width: 100vw;
  }
  
  .modalContent {
    background-color: var(--bg-primary);
    padding: 20px;
    border-radius: 5px;
    position: relative;
  }
  
  .modalCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.5rem;
  }
  
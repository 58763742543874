.container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-x: auto;
  display: block;
  max-height: 720px;
  overflow-y: auto;
}
.heading{
  text-align: center;
}
.table {
  width: 100%; /* Adjusted for better responsiveness */
  max-width: 100%; /* Ensures the table does not exceed the container's width */
  border-collapse: collapse;
}
.dateHeaderContainer {
  display: flex; 
  align-items: center; 
  gap: 1%;
  margin-bottom: 15px; 
}

.dateHeader {
  margin: 0; 
 
}

.downloadIcon {
  cursor: pointer; 
  
  
}

.tableHeader {
  background-color: #007bff;
  color: #ffffff;
  text-align: left;
  padding: 12px 15px;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensures header is above other content */
}

.tableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.searchInput {
  padding: 10px 15px;
  margin: 20px 0;
  width: 12%; /* Adjust width to account for padding */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s, box-shadow 0.2s;
}

.searchInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 123, 255, 0.6);
}

.tableCell {
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
}

.actionButton, .redbtn {
  font-size: 14px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionButton:hover {
  background-color: #8bc097;
}

.redbtn {
  background-color: #f54a06;
}

.redbtn:hover {
  background-color: #f69068;
}

.actionButton.payBack {
  background-color: #dc3545;
}

.actionButton.payBack:hover {
  background-color: #c82333;
}

.formContainer {
    width: 490px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--bg-primary);
    border: 1px solid var(--color-btn-bg);
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    border-radius: 5px;
    margin-top: 50px;
  }
  .heading{
    text-align: center;
  font-weight: bolder;
  font-size: 26px;
  color: var(--color-fifth);
  }
  .inputField:focus, .date:focus, .cdate:focus{
    border: 2px solid var(--color-primary);
  }

  .BtnContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .formField {
    margin-bottom: 15px;
    
  }
/* Add this CSS for the new fields on the same horizontal line */
.statusPriorityFields {
  display: flex;
  justify-content: space-between;
  gap: 15px; /* Adjust the gap between fields */
}

.statusPriorityFields select {
  flex: 1;
  padding: 10px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 16px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
}


  .label {
    margin-top: 1px;
    color: var(--color-fifth);
  }
  
  .inputField {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  
  
  .horizontalFields {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .selectField {
    width: 80%;
    padding: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }

  .date {
    width: 90%;
    padding: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third); 
  }

  .cdate {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 16px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    
  }
  
  .link{
    color: var(--color-primary);
    
    
  }
  .link:hover {
    text-decoration: underline;
  }
  /* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  
}
.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}
  .error {
    color: var(--color-danger);
    font-size: 12px;
    margin-top: 1px;
  }
.selectField:focus{
  border: var(--bg-primary);
}

.inputFieldStartDate, .inputFieldCompletionDate{
  width: 13.5rem;
  height: 2.5rem;
}

 
/* Responsive adjustments for mobile views */
@media (max-width: 480px) {
  .formContainer {
      width: 97%;
      padding: 0.625rem;
  }

  .statusPriorityFields, .horizontalFields {
      flex-direction: column;
      align-items: stretch;
  }

  .selectField, .date, .cdate {
      width: 100%; /* Ensuring full width usage */
  }

  .selectField {
      margin-bottom: 0.625rem; /* Adding space between stacked fields */
  }

  .label {
      display: block; /* Ensuring labels are aligned above fields */
  }

  .link:hover {
      text-decoration: underline;
  }
    /* .statusPriorityFields select { */
      /* width: 100%; Reset the width for mobile */
    /* } */

    .horizontalFields {
      flex-direction: row; 
      justify-content: space-between; 
    }
  
    /* .selectField {
      width: 180%; 
    } */

    .link {
        color: var(--color-primary);
      }
      
      .link:hover {
        text-decoration: underline;
      }
      .inputField{
        width: 22rem;
      }

      .inputFieldStartDate, .inputFieldCompletionDate{
        width: 22rem;
        height: 2.5rem;
      }
}
/* Base styles */
.MainContainer{
  height: 69vh; /* Keep as is or adjust based on your layout */
  max-height: 100%; /* Add max-height to ensure it doesn't grow beyond the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%; /* Ensure full width */
  padding-right: 17px; /* Adjust for scrollbar width to prevent layout shift */
}

.ComponentContainer {
  display: flex;
  flex-wrap: nowrap;
}

/* Align children at the top */
.ComponentContainer > * {
  align-self: flex-start;
}

.MultiFormContainer{
  margin-left: 1rem;
}

/* Responsive adjustments */
@media (min-width: 768px) and (max-width: 1024px), (max-width: 767px) {
  .MainContainer {
    height: 100vh;
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .MainContainer {
    height: auto; /* Adjust height for better space utilization */
    padding: 1rem; /* Add some padding for smaller devices */
  }
  .ComponentContainer {
    flex-direction: column;
  }
  .MultiFormContainer {
    margin-left: 0; /* Adjust margins for a stacked layout */
    margin-top: 1rem; /* Add some space between components */
  }
  /* Adjust font sizes for better readability on tablets */
  button, textarea {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .MainContainer {
    margin-top: 1rem; /* Less margin at the top */
    padding: 0.5rem; /* Adjust padding for very small screens */
  }
  .ComponentContainer {
    flex-direction: column;
  }
  .ComponentContainer > * {
    align-self: stretch; /* Make children fill the container width */
  }
  .MultiFormContainer {
    margin-left: 0; /* Ensure margins are reset for mobile */
    margin-top: 1rem; /* Maintain spacing between components */
  }
  /* Increase font sizes for better readability on mobile */
  button, textarea {
    font-size: 18px; /* Slightly larger fonts for mobile readability */
  }
}

@media (max-width: 480px) {
  .MainContainer{
    height: 100%; /* Keep as is or adjust based on your layout */
    max-height: 100%; /* Add max-height to ensure it doesn't grow beyond the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 100%; /* Ensure full width */
    padding-right: 17px; /* Adjust for scrollbar width to prevent layout shift */
  }
  
}

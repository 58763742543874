.formContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  border-radius: 5px;
  margin-bottom: 40px;
  margin-top: 40px;
  max-height: 38rem;
  overflow-x: auto; 
}
.heading{
  font-weight: bolder;
  font-size: 26px;
  text-align: center;
  color: var(--color-fifth);
  margin-bottom: 2%;
  margin-top: 2%;
}

.labels {
  color: var(--color-fifth);
  margin-top: 2%;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-bottom: 10px;
  justify-content: space-between;
}

.fileInputContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 10px;
}

.inputn {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  transition: border-color 0.3s;
}

.TextArea{
  width: 100%;
  height: 4rem;
  
}



.submitButton {
  background-color: transparent;
  color: var(--color-secondary);
  margin-right: 1rem;
  padding: 9px 22px;
  font-weight: 500;
  font-size: 1rem;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.submitButton:hover {
  background-color: var(--color-fourth);
  transform: scale(1.05);
  color: white;
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .formContainer {
    padding: 15px;
  }

  .formRow {
    flex-direction: column;
    align-items: stretch;
  }

  .inputn,
  .submitButton {
    margin-right: 0;
    width: 100%;
  }

  .fileInputContainer {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .formContainer {
    width: 90vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .labels {
    margin-top: 5%;
  }
}




.radioGroup {
  margin-bottom: 10px;
}

.radioGroup input[type="radio"] {
  display: none;
}

.radioGroup .radio {
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.radioGroup .radio:hover {
  background-color: #eee;
}

.radioGroup input[type="radio"]:checked + .radio {
  background-color: var(--color-secondary);
}

.fileInputContainer {
  margin-bottom: 0px;
}

.fileInputContainer .radio {
  display: block;
  padding: 10px 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.fileInputContainer input[type="file"] {
  display: none;
}

.submitButton {
  background-color: var(--color-primary);
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
}

.submitButton:hover {
  background-color: var(--color-fourth);
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.container {
  max-width: 100%;
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
}

.required {
  color: #e53935;
}

.BtnContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}


.form, .row, .row1 {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.column, .columnAp, .columnAlert, .columnDoc, .columnTeam {
  flex: 1;
  min-width: 220px;
}

.radio, .labelCaveat2, .labelManage, .labelNotes, .labelTeam, .labelln, .labelei {
  display: block;
  margin-bottom: 5px;
  color: var(--color-fifth);
  font-weight: bold;
}

.input, .inputDate, .inputTitle, .inputCaveat1, .inputCaveat2, .inputopn, .inputln, .inputmn, .inputei,
.select, .selectClient, .selectTeam, .selectPa, .selectManage, .selectCaseStatus, .selectCd,
.TextArea, .textareaNotes {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.input:focus, .select:focus, .TextArea:focus, .inputn:focus, .selectClient:focus, .selectTeam:focus,
.selectCd:focus, .inputopn:focus {
  border-color: var(--bg-primary);
  
}

.error, .error1 {
  margin-top: 5px;
  font-size: 14px;
  color: var(--color-danger);
}

/* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  
}


.CancelButton {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.CancelButton:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

.linkClient {
  color: var(--color-accent1);

}

.linkClient:hover {
  text-decoration: underline;
  color: var(--color-accent2);
}
.linkTeam{
  color: var(--color-accent1);
}
.linkTeam:hover{
  text-decoration: underline;
  color: var(--color-accent2);
}

@media screen and (max-width: 768px) {
  .row, .row1, .column, .columnTeam {
    flex-direction: column;
    width: auto;
  }

  .labelln, .labelei {
    margin-left: 0;
  }
}


/* Hide the default radio button */
.radio {
  display: none;
}

/* Style for the label that acts as the custom radio button */
.radio + label {
  padding: 8px 15px;
  margin-right: 10px;
  border: 2px solid #1976d2; /* Blue border color */
  border-radius: 20px; /* Rounded corners for the label */
  cursor: pointer;
  color: var(--color-fifth);
  transition: background-color 0.3s, color 0.3s;
  user-select: none; /* Prevent text selection */
}

/* Change label style when the radio button is checked */
.radio:checked + label {
  background-color: #1976d2; /* Blue background color */
  color: white; /* White text color */
}

/* Optional: change the label style on hover */
.radio + label:hover {
  background-color: #1565c0; /* Slightly darker blue */
  color: white;
}



/* Hide the default file input */
.fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* Style for the label that acts as the custom upload button */




/* Style for the required asterisk */
.required {
  color: red;
  margin-left: 4px;
}

/* Optional: Style to display selected file name */
.fileNameDisplay {
  margin-left: 10px;
  font-size: 16px;
}

/* Concern&Opponent.module.css */

.container {
    width: 70%;
    height: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 40px;
    background-color: var(--bg-primary); /* Ensure this variable is defined */
    border: 1px solid var(--color-btn-bg); /* Ensure this variable is defined */
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1); /* Ensure this variable is defined */
    margin-top: 40px;
  }
  
  .fileInputContainer {
    display: flex;
    flex-direction: column;
  }
  
  .heading {
    font-weight: bolder;
    font-size: 1.8rem;
    text-align: center;
    color: var(--color-fifth); /* Ensure this variable is defined */
  }
  
  .BtnContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 2rem;
    width: 100vw;
  }
  
  .column, .columnTeam {
    display: flex;
    flex-direction: column;
  }
  
  .label, .labelTeam {
    color: var(--color-fifth); /* Ensure this variable is defined */
  }
  
  .selectClient, .selectTeam, .selectCd {
    width: 100%;
    padding: 5px;
    border: 1px solid var(--color-primary); /* Ensure this variable is defined */
    box-shadow: inset 0px 0px 4px 0px var(--color-Third); /* Ensure this variable is defined */
    border-radius: 4px;
    font-size: 16px;
    margin-bottom: 10px; /* Added for spacing */
    position: relative;
    gap: 2rem;
  }
  
  .linkClient {
    color: var(--color-accent1); /* Ensure this variable is defined */
  }
  
  .linkClient:hover {
    text-decoration: underline;
    color: var(--color-accent2); /* Ensure this variable is defined */
  }
  
  .error {
    color: var(--color-danger); /* Ensure this variable is defined */
    font-size: 12px;
    margin-top: 5px;
  }
  
  .submitButton, .CancelButton {
    background-color: var(--color-btn-bg); /* Ensure this variable is defined */
    color: var(--color-fifth); /* Ensure this variable is defined */
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px;
  }
  
  .submitButton:hover, .CancelButton:hover {
    transform: scale(1.05);
    font-weight: bolder;
  }
  
  /* Adjustments for responsive design */
  @media screen and (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  }
  /* ---------------------------------Concern People Table css start ---------------------- */

  .container {
    padding: 20px;
    border-radius: 8px;
    margin: 20px;
  }
  
  .DataColume {
    color: var(--color-fifth);
  }
  
  .headings {
    color: var(--color-fifth);
    font-size: 1.8rem;
    font-weight: bolder;
    text-align: center;
  }
  
  .table-container {
    overflow-x: auto; /* Enables horizontal scrolling */
    overflow-y: hidden; /* Optional: hides vertical scrollbar */
    max-height: 400px; /* Keeps the max height setting */
    /* Add any additional styling as needed */
  }
  
  .table {
    width: 100%;
    min-width: 600px; /* Adjust this value based on your needs */
    border-collapse: collapse;
  }
  
  .tableHead {
    background-color: var(--color-fourth);
    color: white;
    margin-bottom: 1rem;
  }
  
  .tableHead th {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .tableBody {
    padding-top: 1rem;
    color: var(--color-fifth);
  }
  
  .tableBody tr:nth-child(odd) {
    /* background-color: #f2f2f2; */
  }
  
  .tableBody tr {
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .tableBody tr:hover {
    transform: scale(1.03);
  }
  
  .tableBody td {
    padding: 10px;
    text-align: left;
  }
  
  .btn {
    background-color: var(--primary-color);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
  }
  
  /* Media query for screens with a width of 768px or more */
  @media (min-width: 768px) {
    .container {
      height: 100vh;
    }
  
    .table-container {
      max-height: 400px; /* Set a max height for the table container */
    }
  
    .tableBody tr {
      /* Add styles for larger screens if needed */
    }
  
    .btn {
      /* Add styles for larger screens if needed */
    }
  }
  

  /* ---------------------------------Concern People Table css End ---------------------- */
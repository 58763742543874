.head {
    display: flex;
    flex-direction: row-reverse;
    margin: 2rem;
  }
  
  .btn {
    display: inline-block;
    padding: 1rem   2rem; 
    border-radius: 0.6rem; 
    background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
    box-shadow: inset 0px 0px 2px 0px black; 
    text-decoration: none; 
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}

.messageIconContainer {
  position: fixed; /* or 'absolute' depending on your layout */
  top: 185px; /* Adjust as needed */
  left: 60px; /* Adjust as needed */
  z-index: 1000; /* Ensure it's above other content */
  color: var(--color-fifth);
}
.unreadCountBadge {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(100%, -50%);
  background-color: red; /* You can replace this with any variable or specific color you use */
  color: white; /* Adjust color as needed */
  border-radius: 50%;
  padding: 0 5px;
  min-width: 18px;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    color: var(--color-btn-bg);
    transition: var(--transition);
}

  
  .benfitsWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    margin: 4rem;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .programsProgram {
    background-color: var(--color-fifth); /* White background color */
    border: 1px solid var(--color-primary); /* Light gray border for contrast */
    border-radius: 0.8rem;
    box-shadow: 0 4px 15px var(--bg-primary);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
    /* Add 3D styling */
    transform-style: preserve-3d;
    transform: perspective(1000px);
    position: static;
  }
  
  .programsProgram:hover {
    transform: translateY(-5px) rotateX(5deg); /* Apply a slight rotation on hover for a 3D effect */
    box-shadow: 0 6px 20px var(--bg-primary);
    background-color: var(--color-fourth)

  }
  
  .programsProgram span {
    position: absolute;
    color: var(--color-btn-bg); /* Blue color for the icon */
    transform: translateZ(25px); /* Move the icon forward in the z-axis for a 3D effect */
    background-color: var(--color-fourth)

  }
  
  .programsProgram h4 {
    font-size: 1.2rem;
    margin: 10px 0;
    color: var(--bg-primary); /* Darker text color for better contrast */
  }
  
  .SearchLogo {
    margin: auto;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media screen and (max-width: 768px) {
    .benfitsWrapper {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .benfitsWrapper {
      grid-template-columns: 1fr;
      gap: 1rem;
    }
  }
  
.header{
    margin-top: 0rem;
    height: 100%;
    overflow: hidden;
}

.headerContainer{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    place-items: center;
    background: var(--bg-primary);
}


.headerContainerBg{
    width: 100%;
    position: absolute;
}

.headerContainerBg img{
    /* opacity: 0.5; */
    height: 94.9vh;
}

.headerContent {
    position: relative;
    width: 44%;
    margin: 0 auto;
    text-align: center;
    padding-top: 15%;
    color: var(--color-fifth);
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    
    
} 

.headerContent small {
    text-align: center;    
    padding-left: 8%;
    margin: 0;
    color: var(--color-fifth);
}
.headerContent .h2 {
    color: var(--color-fifth);
    font-size: 4.5rem;
    margin-bottom: 24rem;
}

.h2{
    margin-bottom: 2rem;
}

@media (max-width: 1024px) {
    .header {
        margin-top: 0rem;
        height: 100%;
        overflow: hidden;
    }
    .headerContainerBg img {
        height: 30rem; /* Slightly reduced height for a better fit */
    }

    .headerContainer {
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        place-items: center;
        background: var(--bg-primary);
    }

    .headerContainerBg {
        width: 100%;
        position: absolute;
    }

    

    .headerContent {
        position: relative;
        width: 60%; /* Adjusted for better text wrapping */
        margin: 0 auto;
        text-align: center;
        padding-top: 10%; /* Reduced padding-top for alignment */
        color: var(--color-fifth);
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        font-size: 1.6rem; /* Adjusted font size for readability */
    } 

    .headerContent small {
        text-align: center;    
        padding-left: 0; /* Adjust padding for alignment */
        margin: 0;
        color: var(--color-fifth);
    }
    
    .headerContent .h2 {
        color: var(--color-fifth);
        font-size: 3.5rem; /* Adjusted font size for headline */
        margin-bottom: 10rem; /* Adjusted margin bottom for spacing */
    }

    .h2 {
        margin-bottom: 1.5rem; /* Adjust margin bottom for other h2 elements */
    }
}



@media (max-width: 800px) {
    .headerContent {
        width: 90%;
        height: 70%;
        font-size: 1.4rem;
        padding-top: 0rem;
        text-align: center;
    }
    .headerContent p{
        margin: 0;
    }

   
}

@media (max-width: 480px) {
    .headerContainerBg img {
        height: 29rem; /* Slightly reduced height for a better fit */
    }
}
/* CaseDetail.module.css */
.caseDetail {
    font-family: 'Arial', sans-serif;
    font-size: 14px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    background-color: #004085;
    color: white;
    padding: 16px;
  }
  
  .backButton {
    background-color: #f8f9fa;
    color: #004085;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
  }
  
  .title {
    font-size: 18px;
  }
  
  .caseDetails {
    font-size: 18px;
  }
  
  .caseInfo {
    background-color: #f8f9fa;
    padding: 16px;
  }
  
  .infoRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dee2e6;
    padding: 8px 0;
  }
  
  .label {
    font-weight: bold;
  }
  
  .value {
    text-align: right;
  }
  
  .caseHistory {
    border-top: 1px solid #dee2e6;
  }
  
  .historyHeader {
    background-color: #007bff;
    padding: 8px;
    color: white;
  }
  
  .historyTitle {
    font-weight: bold;
  }
  
  /* ...additional styles for case history rows */
  
  .interimOrders {
    border-top: 1px solid #dee2e6;
  }
  
  .orderHeader {
    background-color: #28a745;
    padding: 8px;
    color: white;
  }
  
  .orderTitle {
    font-weight: bold;
  }
  
  /* ...additional styles for interim order rows */
  
  /* Case Detail section */

  /* ExampleComponent.module.css */
.CaseDetailContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .headerRow {
    background-color: #007bff;
    color: white;
    display: contents;
  }
  
  .header {
    padding: 8px;
    border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  
  .dataRow {
    background-color: #f8f9fa;
    display: contents;
  }
  
  .data {
    padding: 8px;
    border: 1px solid #dee2e6;
  }
  
  .dataHighlight {
    padding: 8px;
    border: 1px solid #dee2e6;
    background-color: #ffcccc; /* Or any other highlight color */
  }
  
  /* You may need to add additional styling to match the exact look */
  
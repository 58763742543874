.dropdown {
    position: relative;
    display: inline-block;
    width: 200px;
  }
  
  .selected {
    padding: 2px;
    background: var(--color-fifth);
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    margin-right: 10px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border: 1px solid var(--color-primary);
  }
  
  .menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--color-fifth);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border: 1px solid var(--color-primary);
    display: none;
    z-index: 10;
    width: max-content; /* Ensure menu width is only as wide as its content */
  }
  
  .menuVisible {
    display: block;
  }
  
  .selected:hover .menu {
    display: block;
  }
  
  .menuItem {
    padding: 2px;
    cursor: pointer;
    white-space: nowrap; /* Prevent line breaks in menu items */
    position: relative; /* Ensure the submenu is positioned relative to the menu item */
  }
  
  .menuItem:hover {
    background: var(--color-fourth);
  }
  
  .subMenu {
    position: absolute;
    top: 0;
    left: 100%;
    background: var(--color-fifth);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border: 1px solid var(--color-primary);
    display: none;
    z-index: 10;
  }
  
  .menuItem:hover .subMenu,
  .subMenu:hover {
    display: block;
  }
  
  .subMenuItem {
    padding: 2px;
    cursor: pointer;
    white-space: nowrap; /* Prevent line breaks in submenu items */
    position: relative; /* Ensure the subsubmenu is positioned relative to the submenu item */
  }
  
  .subMenuItem:hover {
    background: var(--color-fourth);
  }
  
  .subSubMenu {
    position: absolute;
    bottom: 0;
    left: 100%;
    background: var(--color-fifth);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    border: 1px solid var(--color-primary);
    display: none;
    z-index: 10;
    max-height: 300px; /* Show only 10 items */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    width: max-content; /* Ensure subsubmenu width is only as wide as its content */
  }
  
  .subMenuItem:hover .subSubMenu,
  .subSubMenu:hover {
    display: block;
  }
  
  .subSubMenuItem {
    padding: 2px;
    cursor: pointer;
    white-space: nowrap; 
  }
  
  .subSubMenuItem:hover {
    background: var(--color-fourth);
  }
  
  .selectedSub {
    display: none;
  }
  .arrow {
    margin-left: 80px;
  }
  
 
  @media (max-width: 600px) {
    .menu {
      width: 100%; 
    }
  
    .subMenu {
      position: relative;
      top: 0;
      left: 0;
      width: 100%; 
    }
  
    .subSubMenu {
      position: relative;
      background: var(--color-third);
      bottom: 0;
      left: 0;
      width: 100%; 
      max-height: 200px; 
    }
  
    .menuItem:hover .subMenu,
    .subMenu:hover,
    .subMenuItem:hover .subSubMenu,
    .subSubMenu:hover {
      display: block;
    }
  }
  


.formContainer {
  width: 100%; /* Set to 100% for maximum responsiveness */
  max-width: 600px; /* Max width to ensure form doesn't get too wide on larger screens */
  height: 80vh;
  margin: 2rem auto;
  padding: 1rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 4px 20px 0px var(--color-accent1); /* Adjusted shadow */
  border-radius: 5px;
  overflow-y: auto;
}

.fileNameSpan{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 5%;
  color: var(--color-fifth);
}

  

.BtnContainer{
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.heading{
  text-align: center;
  font-weight: bolder;
  font-size: 26px;
  color: var(--color-fifth);
  margin-bottom: 2%;
}

.invoiceNo {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-accent2);
}

.clientContainer {
  display: flex;
  /* align-items: center; */
  margin-bottom: 20px;
}

.selectFieldClient {
  width: 75%;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}

.link {
  color: var(--color-accent1);

}

.link:hover {
  text-decoration: underline;
  color: var(--color-accent2);
}
.input0Field {
  flex: 1;
  padding: 5px;
  width: 50%;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
  margin-bottom: 5%;
}
.selectFieldType {
  /* flex: 1; */
  width: 50%;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}
.fieldContainer {
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.inputField {
  flex: 1;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}
.label {
  flex-basis: calc(33.33% - 10px); 
  display: flex;
  
  color: var(--color-fifth);
}

.formGroup3 {
  flex-basis: calc(33.33% - 10px); 
  
  
}
.selectFieldTaxType{
  flex: 1;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}

.textareaField {
  width: 98%;
  height: 100px;
  padding: 5px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  margin-bottom: 20px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-top: 5%;
}
.inputFieldHearingDate {
  width: 47.5%;
  padding: 5px;
  
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}

.expensesTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  margin-top: 20px;
  color: var(--color-primary);
}
.inputFieldTitle {
  width: 98%;
  padding: 5px;
  
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
}

.fileField {
  width: 98%;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  margin-right: 10px;
  background-color: var(--color-fifth);
}



.inputField:focus, .inputField2:focus, .selectFieldType:focus,
 .inputFieldTitle:focus, .selectFieldClient:focus, .selectFieldTaxType:focus,
 .inputField1:focus, .textareaField:focus{
  border: 2px solid var(--color-secondary);
 }


/* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  color: var(--color-btn-bg);
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  
  
}
.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

.formSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}


.formGroup {
  flex-basis: calc(33.33% - 10px); 
  margin-right: 105px;
  
}
.inputField1 {
  width: 120%;
   padding: 5px;
   
   border: 1px solid var(--bg-secondary);
   border-radius: 4px;
   font-size: 14px;
   box-shadow: inset 0px 0px 4px 0px var(--color-Third);
   margin-right: 20px;
 }
.inputField2 {
  width: 120%;
   padding: 5px;
   
   border: 1px solid var(--bg-secondary);
   border-radius: 4px;
   font-size: 14px;
   box-shadow: inset 0px 0px 4px 0px var(--color-Third);
   margin-right: 20px;
 }
 
.inputField3 {
 width: 90%;
  padding: 5px;
  
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
  box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  
}
.errorMessage {
  color: var(--color-danger);
  font-size: 14px;
  margin-bottom: 10px;
 
 
}

@media (max-width: 768px) {
  .formContainer {
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow-y: auto; 

  }

  /* Adjust input fields and select elements to take full width on smaller screens */
  .input0Field, .inputFieldTitle, .inputFieldHearingDate, 
  .textareaField, .fileField, .inputField1, .inputField2, .inputField3 {
    width: 100%; /* Full width for a stacked layout */
    margin-right: 0; /* Remove margin to align with the width of the container */
  }

  /* Stacking labels above inputs/selects/textarea on small screens */
  .label, .formGroup, .formGroup3 {
    flex-basis: 100%; /* Full width for labels */
    margin-right: 0; /* Reset margin */
  }

  /* Update the layout for any containers that need to stack their children */
  .clientContainer, .fieldContainer, .formSection {
    flex-direction: column; /* Stack children vertically */
  }

  /* Update the margin for items that are meant to be inline with other elements */
  .inputField, .selectFieldClient, .selectFieldTaxType {
    margin-bottom: 10px; /* Add bottom margin for spacing */
  }

  /* Adjust the buttons container to stack buttons if necessary */
  .BtnContainer {
    flex-direction: column; /* Stack buttons vertically */
    gap: 0.5rem; /* Adjust gap for vertical stacking */
  }

  /* Adjust for all other input and select fields to be full width */
  .selectFieldClient,  .selectFieldTaxType,
  .inputField, .inputFieldTitle, .textareaField, .fileField {
    width: 100%; /* Full width for all form fields */
  }
}

/* Media Query for very small screens, typically for phones */
@media (max-width: 480px) {
  /* Adjust the top and bottom margins of the form container for small screens */
  .formContainer {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  /* Further adjustments for form fields if necessary */
  .inputField1, .inputField2, .inputField3 {
    width: 100%; /* Adjust if necessary for smaller screens */
  }

  /* If any text is too large on small screens, adjust the font size accordingly */
  .invoiceNo, .expensesTitle {
    font-size: 20px; /* Slightly smaller font size for smaller screens */
  }

  /* Adjust paddings and margins for smaller screens to maximize space */
  /* ... */
}

/* AppointmentForm.module.css */
.AppointmentVisibleForm {
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    padding: 20px;
    border-radius: 8px;
  }
  
  .formRow {
    margin-bottom: 15px;
  }
  .errorMsg {
    color: var(--color-danger); /* Red color for the error message */
    margin-top: 5px; /* Optional: Adjust spacing as needed */
    font-size: 0.8em; /* Optional: Adjust font size as needed */
  }
  
  .AppointmentFormTitle,
  .TasksVisibleTitle,
  .HearingVisibleFormTitle {
    display: block; /* Make labels block level for better control */
    margin-bottom: 5px;
    font-weight: bold;
    color: var(--color-fifth); /* Dark grey color for text */
  }
  
  .TasksVisibleInput,
  .HearingVisibleFormInput,
  .HearingVisibleFormDate,
  .HearingVisibleFormTime {
    width: 100%; /* Full width */
    padding: 8px;
    border: 1px solid var(--color-primary); /* Light grey border */
    border-radius: 4px;
    box-sizing: border-box; /* Box sizing to ensure padding doesn't affect width */
    transition: border-color 0.3s; /* Add a subtle transition effect */
  }

  .TasksVisibleInput:focus,
  .HearingVisibleFormInput:focus,
  .HearingVisibleFormDate:focus,
  .HearingVisibleFormTime:focus,
  .formInput:focus{
    border: 1px solid var(--color-primary);
  }

  .submitButton {
    background-color: var(--color-btn-bg); 
      color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .CancelButton {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    font-weight: bolder;
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    color: var(--color-btn-bg);
    
  }
  .CancelButton:hover {
    background-color: var(--color-danger); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 1px 0px var(--color-primary);
  }
  
  
  .BtnContainerAppoint {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
  
  /* AppointmentForm.module.css */
.formRow {
    display: flex; /* Enables Flexbox */
    justify-content: space-between; /* Space out the child elements */
    margin-bottom: 15px;
  }
  
  .formGroup {
    flex: 1; /* Allows each group to take equal width */
    margin-right: 10px; /* Adds some space between the input groups */
  }
  
  .formGroup:last-child {
    margin-right: 0; /* Removes margin for the last group in the row */
  }
  
  .formInput {
    width: 100%; 
    transition: border-color 0.3s; /* Add a subtle transition effect */

  }
  .TasksVisibleInputTime{
    height: 2rem;
    width: 10rem;
  }
  

  /* For tablets */
@media only screen and (max-width: 768px) {
  .AppointmentVisibleForm {
    padding: 15px; 
  }
  
  .BtnContainerAppoint {
    flex-direction: column;
    align-items: center;
  }

  .submitButton, .CancelButton {
    width: 100%;
  }
}

/* For mobile devices */
@media only screen and (max-width: 480px) {
  .AppointmentVisibleForm {
    padding: 10px;
  }

  .formRow {
    flex-direction: row;
    gap: 0.5rem;
  }

  .TasksVisibleInput{
    height: 2rem;
  }
  .HearingVisibleFormDate{
    height: 2rem;
    width: 9.5rem;
  }

  .TasksVisibleInputTime{
    height: 2rem;
    width: 6rem;
  }

  .formGroup {
    margin-right: 0;
    /* margin-bottom: 10px; */
  }

  .submitButton, .CancelButton {
    width: 100%;
    /* font-size: 0.9em; */
  }
}

.clientForm {
  width: 600px;
  height: 87vh;
  margin: 0 auto;
  padding: 1rem;
  background-color: var(--bg-primary);
  border: 1px solid var(--color-btn-bg);
  box-shadow: 0px 4px 20px 0px var(--color-accent1); /* Adjusted shadow */
  border-radius: 5px;
  margin-bottom: 4px;
  
  margin-top: 1rem;
  overflow-y: scroll; /* Ensures scrollbar for vertical overflow */
}

.heading {
  text-align: center;
  font-weight: bolder;
  font-size: 26px;
  color: var(--color-fifth);
}


  .BtnContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .formSection {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 5px;
  }
  
  
  .formGroup {
    flex-basis: calc(33.33% - 10px); 
    margin-right: 105px;
    
  }
  
  .formGroup3 {
    flex-basis: calc(33.33% - 10px); 
    margin-right: 15px;
    
  }
  .inputField {
    width: 155%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--bg-secondary);
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .inputFieldEmail{
    width: 310%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .input3 {
    width: 103%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  
  .error {
    color: var(--color-danger);
    font-size: 12px;
    margin-top: 1px;
  }
  
/* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  color: var(--color-btn-bg);
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  
  
}
.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}
.inputField:focus, .inputField2:focus, .selectFieldType:focus,
 .inputFieldTitle:focus, .selectFieldClient:focus, .selectFieldTaxType:focus,
 .inputField1:focus, .textareaField:focus, .inputFieldEmail:focus, .input3:focus,
 .inputFieldTextarea:focus{
  border: 2px solid var(--color-primary);
 }

  .label {
    
    margin-top: 1px;
    color: var(--color-fifth);
  }
  .inputFieldTextarea{

    width: 155%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    height: 100px;
    transition: background-color 0.3s ease;

  }
  .link {
    color: var(--color-accent1);
  
  }
  
  .link:hover {
    text-decoration: underline;
    color: var(--color-accent2);
  }
  
  @media screen and (max-width: 768px) {
    

.clientForm {
  width: 600px;
  height: auto; 
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--bg-secondary);
  border-radius: 5px;
  margin-bottom: 40px;
  background-color: var(--color-fifth);
  margin-top: 40px;
}

.formSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.formGroup {
  flex-basis: calc(33.33% - 10px); 
  margin-right: 20px; 
}

.formGroup3 {
  flex-basis: 100%; 
  margin-right: 0;
}

.inputField {
  width: 100%;
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
}

.inputFieldEmail {
  width: 100%; 
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
}

.input3 {
  width: 100%; 
  padding: 5px;
  margin-top: 1px;
  border: 1px solid var(--bg-secondary);
  border-radius: 4px;
  font-size: 14px;
}

.error {
  color: var(--color-danger);
  font-size: 12px;
  margin-top: 1px;
}

.submitButton {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px;
}

.submitButton:hover {
  background-color: var(--color-fourth);
  color: var(--color-primary);
  transform: scale(1.05);
  box-shadow: inset 0px 0px 6px 0px var(--color-primary);
}

.label {
  margin-top: 1px;
  color: var(--color-secondary);
}


.link {
  color: var(--color-primary);
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .clientForm {
    width: 100%; /* Full width for mobile screens */
    height: 86vh; /* Adjust height to content */
    padding: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    overflow-y: scroll; /* Ensures scrollbar for vertical overflow on mobile */
  } 
  
  .formGroup {
    flex-basis: 100%; 
    margin-right: 0;
    }

  .formGroup3 {
    flex-basis: 100%; 
    margin-right: 0;
    }
    
  }
}

  
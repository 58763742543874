/* Navbar.module.css */
.Container {
  position: sticky;
  top: 0;
  background-color: var(--color-fourth);
  border-radius: 1rem;
  width: 80%;
  margin: 0rem auto;
  padding: 15px 11px;
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensures navbar is above other content */
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Adds shadow for depth */
}

.MdMessage{
  position: relative;
  margin-top: 0.2rem;
  color: var(--bg-secondary);

}

.ModalOverlay {
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  /* width: 100%; */
  /* height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* z-index: 1050; */
}

/* .ModalContent {
  position: relative;
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 400px;
} */

/* .exit {
  position: absolute;
  top: 2rem;
  right: 5rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 1rem;
  color: var(var(--color-primary));
  z-index: 1000;
} */

.BellIcon, .IconContainer {
  cursor: pointer;
  position: relative;
  color: var(--color-third);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.BellIcon svg {
  color: var(--bg-secondary);
}

.NotifiCount {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(100%, -50%);
  background-color: var(--color-danger);
  color: var(--color-fifth);
  border-radius: 50%;
  padding: 0 5px;
  min-width: 18px;
  height: 18px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  font-weight: bolder;
  color: var(--bg-secondary);
}

.LinksList {
  margin: 0 1rem;
  position: relative;
}

.submenuLinks, .mainMenu {
  color: var(--bg-primary);
  text-decoration: none;
  padding: 0.5rem;
  display: block;
  font-weight: bolder;
  text-align: center;
}

.DropDown {
  display: none; /* Hidden by default, shown on hover */
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--color-fourth);
  min-width: 150px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 0.5rem;
  z-index: 2;
  overflow: hidden;
}

.LinksList:hover > .DropDown {
  display: flex; /* Display dropdown on hover */
}

.DropDown .LinksList {
  white-space: nowrap;
}

/* Adjustments for responsiveness */
@media (max-width: 768px) {
  .Container {
      flex-direction: column;
      width: 95%;
  }

  .Links {
      flex-direction: column;
  }

  .LinksList {
      margin: 0.5rem 0;
  }
}

.clientForm {
    width: 600px; 
            height: 80%;
            margin: 0 auto;
            padding: 20px;
            border: 1px solid var(--color-btn-bg);
            
            border-radius: 5px;
            margin-bottom: 40px;
            background-color: var(--bg-primary);
            margin-top: 40px;
            box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  }
  .heading{
    font-weight: bolder;
  font-size: 26px;
  color: var(--color-fifth);
  }

  .BtnContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
  
  
  .formSection {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 5px;

  }
  
  
  .formGroup {
    flex-basis: calc(33.33% - 10px); 
    margin-right: 105px;
    
  }
  
  .formGroup3 {
    flex-basis: calc(33.33% - 10px); 
    margin-right: 15px;
    
  }
  .inputField {
    width: 155%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid var(--bg-secondary);
    
    transition: border-color 0.3s; /* Add a subtle transition effect */
  }
  .inputField:focus,.inputFieldEmail:focus,.inputFieldTextarea:focus{
    border: 2px solid var(--color-primary);
  }

  .inputFieldEmail{
    width: 310%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    transition: border-color 0.3s; /* Add a subtle transition effect */

  }
  .input3 {
    width: 103%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 14px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
    transition: border-color 0.3s; /* Add a subtle transition effect */

  }
  
  .error {
    color: var(--color-danger);
    font-size: 12px;
    margin-top: 1px;
  }
  
  .submitButton {
    background-color: var(--color-btn-bg); 
      color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .CancelButton {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    font-weight: bolder;
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    color: var(--color-btn-bg);
    
  }
  .CancelButton:hover {
    background-color: var(--color-danger); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 1px 0px var(--color-primary);
  }
  
  .label {
    margin-top: 1px;
    color: var(--color-fifth);
  }

  .inputFieldTextarea{

    width: 155%;
    padding: 5px;
    margin-top: 1px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    font-size: 14px;
    height: 100px;
    transition: 0.3s; /* Add a subtle transition effect */
  }

  @media screen and (max-width: 768px) {
    

.clientForm {
  width: 600px;
  height: auto; 
  margin: 0 auto;
  padding: 20px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  margin-bottom: 40px;
  
  margin-top: 40px;
}

.formSection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.formGroup {
  flex-basis: calc(33.33% - 10px); 
  margin-right: 20px; 
}

.formGroup3 {
  flex-basis: 100%; 
  margin-right: 0;
}

.inputField {
  width: 100%;
  padding: 5px;
  margin-top: 1px;
 
  border-radius: 4px;
  font-size: 14px;
}

.inputFieldEmail {
  width: 100%; 
  padding: 5px;
  margin-top: 1px;
 
  border-radius: 4px;
  font-size: 14px;
}

.input3 {
  width: 100%; 
  padding: 5px;
  margin-top: 1px;
  
  border-radius: 4px;
  font-size: 14px;
}

.error {
  
  font-size: 12px;
  margin-top: 1px;
}

.submitButton {
  background-color: var(--color-primary);
  
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px;
}
.submitButton:hover {
  background-color: var(--color-fourth);
  color: var(--color-primary);
  transform: scale(1.05);
  box-shadow: inset 0px 0px 6px 0px var(--color-primary);
}
.CancelButton {
  background-color: var(--color-primary);
  
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px;
}


.CancelButton:hover {
  background-color: var(--color-danger);
  color: var(--color-primary);
  transform: scale(1.05);
  box-shadow: inset 0px 0px 6px 0px var(--color-primary);
}

.label {
  margin-top: 1px;
  color: var(--color-secondary);
}

.inputFieldTextarea {
  width: 100%; 
  padding: 5px;
  margin-top: 1px;
 
  border-radius: 4px;
  font-size: 14px;
  height: 100px;
}

.linksss {
  color: var(--color-btn-bg);
}

.link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .clientForm {
        width: 100%; 
        margin-right: 0;
                
      }
      .formGroup {
        flex-basis: 100%; 
        margin-right: 0;
      }
      .formGroup3 {
        flex-basis: 100%; 
        margin-right: 0;
      }
    
}
}

  
/* Main  */  
.MainContainer {
    grid-area: main;
    overflow-y: auto;
    padding: 20px 20px;
    color: rgba(255, 255, 255, 0.95);
  }
  
  .MainTitle {
    display: flex;
    justify-content: space-between;
  }
  
  .MainCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin: 15px 0;
  }
  
  .Card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 8px 15px;
    border-radius: 5px;
  }
  
  .Card:first-child {
    background-color: #2962ff; /* Blue */
  }
  
  .Card:nth-child(2) {
    background-color: #ff6d00; /* Orange */
  }
  
  .Card:nth-child(3) {
    background-color: #2e7d32; /* Green */
  }
  
  .Card:nth-child(4) {
    background-color: #d50000; /* Red */
  }
  
  .Card:nth-child(5) {
    background-color: #aa00ff; /* Purple */
  }
  
  .Card:nth-child(6) {
    background-color: #0091ea; /* Light Blue */
  }
  
  .Card:nth-child(7) {
    background-color: #64dd17; /* Lime Green */
  }
  
  .Card:nth-child(8) {
    background-color: #ffd600; /* Yellow */
  }
  
  .Card:nth-child(9) {
    background-color: #ff1744; /* Pink */
  }
  
  .Card:nth-child(10) {
    background-color: #00c853; /* Dark Green */
  }
  
  .Card:nth-child(11) {
    background-color: #6200ea; /* Deep Purple */
  }
  
  .Card:nth-child(12) {
    background-color: #c51162; /* Magenta */
  }
  .Card:nth-child(13) {
    background-color: #11bfc5; /* Magenta */
  }
  .Card:nth-child(14) {
    background-color: #ff9800; /* Amber */
  }
  
  .Card:nth-child(15) {
    background-color: #795548; /* Brown */
  }
  
  .CardInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .CardInner > .CardIcon {
    font-size: 25px;
  }
  
  .Charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 60px;
    height: 300px;
  }
  
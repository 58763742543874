/* ProxyPayment.module.css */
.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow-x: auto; /* Allows horizontal scrolling for wide tables */
    display: block;
    max-height: 720px; /* Adjust based on your needs */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  .table {
    width: 300%;
    max-width: 300%;
    border-collapse: collapse;
  }
  .link {
    
    color: var(--color-accent2);
  }
  
  .link:hover {
    text-decoration: underline;
    color: var(--color-accent1);
  }
  .tableHeader {
    background-color: #007bff;
    color: #ffffff;
    text-align: left;
    padding: 12px 15px;
    z-index: 2;
    top: 0;
   position: sticky;
  }
  
  .tableRow:nth-child(even) {
    background-color: #f2f2f2;
  }
  .searchInput {
    padding: 10px 15px;
    margin: 20px 0;
    width: 300%; /* Adjusts width considering padding */
    font-size: 16px; /* Larger font size for better readability */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
  
  .searchInput:focus {
    outline: none; /* Removes the default focus outline */
    border-color: #007bff; /* Highlight color */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 123, 255, 0.6); /* Increased shadow on focus for emphasis */
  }
  
  
  .tableCell {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .actionButton {
    font-size: 14px;
    color: #fff;
    background-color: #28a745;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .actionButton:hover {
    background-color: #8bc097;
  }
  .redbtn{
    font-size: 14px;
    color: #fff;
    background-color: #f54a06;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .redbtn:hover{
    
    background-color: #f69068;
    
  }
  /* Additional styling for 'Pay Back Money' button to differentiate it from the 'Pay Money' button */
  .actionButton.payBack {
    background-color: #dc3545;
  }
  
  .actionButton.payBack:hover {
    background-color: #c82333;
  }
  
body {
    /* font-family: 'Poppins', sans-serif; */
    line-height: 1.6;
    color: var(--text-color);
  }
  
  .anchor{
    color: var(--color-accent1);
  }
  .anchor:hover{
    color: var(--color-accent2);
    text-decoration: underline;
  }
  .bigBox {
    background-color: var(--color-fifth);
    padding: 20px;
    width: 70rem;
    margin-left: 120px;
    display: flex;
    align-items: center;
    height: 65rem;
    border: 2px solid var(--color-primary);
    margin-top: 2rem;
    margin-bottom: 4rem;
    position: relative;
  }
  
  .blueSection {
    width: 100%;
    /* height: 20%; */
    background-color: var(--color-primary);
    position: absolute;
    left: 0;
    top: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    font-weight: bold;
  }
  
  .companyDescription {
    font-size: 35px;
    color: var(--color-fifth);
    margin-left: 30px;
    max-width: 60%;
    overflow: hidden;
    word-wrap: break-word;
  }
/* CSS */
.style_privacy {
  display: flex;
  flex-direction: row;
  
}

.style_privacypara {
  margin-left: 8px; /* Add some space between the checkbox and the text */
  
}

  .paragraph {
    font-size: 20px;
    color: var(--bg-primary);
    justify-content: flex-start;
    max-width: 60%;
    overflow: hidden;
    word-wrap: break-word;
    margin-bottom: 50px;
    font-weight: bold;
    row-gap: 30%;
    margin-left: 30px;
    font-weight: bold;
    margin-top: 100px;
  }
  
  .companyName {
    font-size: 40px;
    color: var(--bg-primary);
    font-weight: bold;
    margin-left: 30px;
  }
  
  .container {
    width: 320px;
    height: 32rem;
    text-align: left;
    background-color: var(--bg-primary);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px var(--bg-primary);
    position: relative;
    font-family: Arial, sans-serif;
    left: 5%;
    color: var(--color-fifth);
    bottom: 5%;
    border: 2px solid var(--color-primary);
    
  }
  .margin {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    margin-bottom: 20px;
    color: var(--color-fourth);
    
  }
  .margin1 {
    display: flex;
    flex-direction: row;
    
  }
  .name,
  .contact,
  .email,
  .company {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    height: 30px;
    border: 2px solid var(--color-primary);
  }
  .demoForm select {
    width: 100%;
    padding: 7px; 
    border: 2px solid var(--color-primary);
     height: 30px;
  }
  
  
  .btn {
  width: 100%;
  padding: 10px;
  background-color: var(--color-btn-bg); 
  color: var(--color-fifth);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: inset 0px 0px 6px 0px ;
  border-radius: 0.4rem;
  }

  .btn:hover{
    background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  }
  .point {
    margin-bottom:22px;
  }
  
  /* ----------------------- Term And Condition start ---------------------------------- */
  
  .TermsContainer, .PrivacyPolicyContainer {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .MainHeading {
    color: #333333;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: bolder;
  }
  
  .SecondHeading {
    color: #555555;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .Para {
    color: #666666;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
 /* Existing styles... */

/* Responsiveness */
@media screen and (max-width: 1024px) {
  /* Tablet adjustments */
  .bigBox {
    width: 90%; /* Adjust width to be more fluid */
    margin-left: auto; /* Center align */
    margin-right: auto; /* Center align */
    height: auto; /* Adjust height to be auto */
    flex-direction: column; /* Stack children vertically */
  }

  .blueSection, .companyDescription, .paragraph, .container, .companyName {
    width: auto; /* Allow these elements to fill their containers */
    margin-left: 10px;
    margin-right: 10px;
  }

  .companyDescription, .paragraph {
    font-size: 28px; /* Adjust font size for readability */
  }

  .container {
    left: 2%; /* Adjust positioning */
    bottom: 2%; /* Adjust positioning */
  }
}

@media screen and (max-width: 768px) {
  /* Mobile adjustments */

  .blueSection {
    width: 100%;
    height: 5%;
    background-color: var(--color-primary);
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    font-weight: bold;
  }

  .bigBox, .blueSection, .companyDescription, .paragraph, .container, .companyName {
    margin-left: 5px;
    margin-right: 5px;
  }

  .companyDescription, .paragraph, .companyName {
    font-size: 20px; /* Further adjust font size for mobile readability */
  }

  .container {
    width: 90%; /* Make the container fill more of the screen */
    left: 5%; /* Adjust left positioning to center the container */
    bottom: 5%; /* Adjust bottom positioning */
  }

  .TermsContainer, .PrivacyPolicyContainer, .MainHeading, .SecondHeading, .Para {
    /* Adjustments to ensure text is centered and readable on mobile */
    text-align: left;
    padding: 10px;
  }

  .btn {
    padding: 12px; /* Increase padding for easier clicking */
  }
}

/* Mobile adjustments for screens up to 480px wide */
@media screen and (max-width: 480px) {
  body {
    /* Adjust body styles if necessary, e.g., font size or padding */
  }
 

  .bigBox {
    width: 97%; 
    margin-left: 10px; /* Adjust margins for smaller screens */
    margin-right: 10px;
    height: auto; /* Height becomes auto to accommodate content */
    flex-direction: column; /* Stack children if necessary */
    padding: 10px; /* Adjust padding */
  }

  .blueSection {
    height: auto; /* Adjust the height for mobile */
    padding: 10px; /* Adjust padding for smaller screen */
    margin: 0;
  }

  .companyDescription, .companyName {
    font-size: 18px; /* Adjust font size for better readability on small screens */
    margin-left: 10px; /* Adjust margins to fit small screens */
    margin-right: 10px;
    max-width: 100%; /* Ensure no overflow */
  }

  .paragraph {
    /* margin-bottom: 200px; */
    /* margin-top: 400px;  */
  }

  .container {
    width: 90%; /* Container takes more screen width */
    margin-left: auto; /* Center align the container */
    margin-right: auto;
    padding: 10px; /* Adjust padding */
    left: 5%; /* Adjust or remove left positioning */
    bottom: auto; /* Adjust or remove bottom positioning */
    height: auto; /* Adjust height to be auto */
  }

  .btn {
    padding: 12px; /* Larger padding for easier touch */
  }

  /* Adjust form elements for better usability on small screens */
  .name, .contact, .email, .company, .demoForm select {
    padding: 8px; /* Increase padding for touch targets */
    margin-bottom: 15px; /* Increase space between form elements */
  }

  /* Adjustments for terms and conditions, privacy policy containers for better readability */
  .TermsContainer, .PrivacyPolicyContainer {
    padding: 10px; /* Adjust padding */
  }

  .MainHeading, .SecondHeading, .Para {
    font-size: 16px; /* Adjust font sizes for headings and paragraphs */
  }
}


.form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid var(--bg-secondary);
    border-radius: 5px;
    margin-top: 50px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .td {
    width: 22%;
    padding: 10px;
    border: 2px solid var(--bg-secondary); 
    background-color: var(--color-fifth);
  }
  .td1 {
    width: 22%;
    padding: 10px;
    border: 2px solid var(--bg-secondary); 
    background-color: var(--color-fifth);
  }
  .cell {
    width:105%;
    padding: 10px;
    border: 2px solid var(--bg-secondary)c; 
    background-color: var(--color-fifth);
  }
  .cell1 {
    width:105%;
    padding: 10px;
    border: 2px solid var(--bg-secondary); 
    background-color: var(--color-fifth);
  }
  .required {
    color: var(--color-danger);
  }
  
  
  .label {
    font-weight: bold;
    display: block; 
    color: var(--bg-primary);
  }
  
  .input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--bg-secondary);
    border-radius: 3px;
  }
  
  .adhaarInputs {
    display: flex;
    gap: 5px;
  }
  
  .button {
    padding: 10px 20px;
    background-color: var(--bg-accent);
    color: var(--color-fifth);
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  .submitButton {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px;
  }
  
  .submitButton:hover {
    background-color: var(--color-fourth);
    color: var(--color-primary);
    transform: scale(1.05);
    box-shadow: inset 0px 0px 6px 0px var(--color-primary);
  }
  
  @media (max-width: 768px) {
    .form {
      max-width: 100%;
    }
  }
  
/* Default styles for larger screens */

.main {
  background-color: var(--bg-secondary);
  padding: 0;
  box-sizing: border-box;
  margin-top: 0%;
  margin-bottom: 0%;
}

.mainBenifits{
  background-color: var(--bg-primary);
  /* border-radius: 2rem; */
  margin-bottom: 8rem;
}

.mobileScreenFrame {
  max-width: 36px; /* Represents the max width of a mobile device */
  margin: auto; /* Centers the frame on the page */
  border: 16px solid black; /* Simulates the mobile device frame */
  border-radius: 24px; /* Rounds the corners to look more like a mobile device */
  padding: 20px; /* Space between the border and the video */
  box-shadow: 0px 0px 24px #000; /* Adds a shadow for a 3D effect */
  background: #000; /* Background of the frame, can be adjusted or set to an image */
  display: flex;
  justify-content: center;
  align-items: center;
}


.videoAd {
  position: relative;
  overflow: hidden;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-width: 640px; /* Adjust based on your layout needs */
  margin: 20px auto; 
  aspect-ratio: 16 / 9; 
}

.videoAd video {
  width: 100%;
  height: auto;
  display: block; /* Remove default browser styling */
  border-radius: 10px; /* Match parent's border-radius */
}

/* Optional: Style controls for customization */
.videoAd video::-webkit-media-controls {
  color: var(--primary-color); /* Adjust based on your theme */
}

.videoAd video::-webkit-media-controls-play-button {
  background: var(--secondary-color);
  border-radius: 50%;
}


@keyframes slideRightToLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .benfitsWrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: 6rem;
    width: 100%;
    gap: 4rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: thin;
    scrollbar-color: #a6c1ee #fbc2eb;
    padding-top: 2rem;
    background-color: var(--bg-primary);
}


.benfitsWrapper::-webkit-scrollbar {
  height: 8px;
}

.benfitsWrapper::-webkit-scrollbar-thumb {
  background-color: #a6c1ee;
  border-radius: 20px;
}

.benfitsWrapper::-webkit-scrollbar-track {
  background: #fbc2eb;
}


.BenifitsTitle span {
  font-size: 24px; /* Icon size */
  color: var(--color-primary);
}


.info{
    color: var(--bg-primary);
    font-weight: bold;
    text-align: center;
    width: 16rem;
    /* padding-top: 4rem; */
}

.Title{
  font-size: 1rem;
  padding-top: 0rem;
  text-align: center;
}

.programsProgram {
  flex: 0 0 auto; /* Prevent cards from growing or shrinking */
  width: 20rem; /* Fixed width for each card */
  height: 10rem;
  background: #e0e0e0; /* Light grey background */
  border-radius: 20px;
  /* box-shadow: 8px 8px 15px #bebebe, -8px -8px 15px #ffffff; */
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
  margin: 0 1rem; /* Spacing between cards */
  transition: transform 0.3s ease;
}

.programsProgram:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}
.programsProgram span {
    position: absolute;
    top: -1.5rem;
    color: var(--bg-primary);
    
}
/* style.module.css */
.mainBenefits {
  transition: transform 0.5s ease-out; /* Smooth transition for the transform property */
  transform: translateX(-100%); /* Start off-screen to the left */
  opacity: 0; /* Start hidden */
}

.mainBenefits.active {
  transform: translateX(0); /* Move into view */
  opacity: 1; /* Become fully visible */
}

.videoImageContainer {
  position: relative;
  width: 100%; 
  padding-top: 30%;
  z-index: 1000;
  bottom: 8rem; 
  height: 50%;
}

.image {
  position: absolute;
  top: 0;
  left: 20%;
  bottom: 5rem;
  width: 60%;
  height: 100%;
  object-fit: cover; /* Covers the space without stretching */
}

.playButton {
  position: absolute;
  top: 105%;
  z-index: 1000;
  left: 50%;
  /* bottom: 50%; */
  transform: translate(-50%, -50%); /* Perfectly centers the button */
  background: rgba(0, 0, 0, 0.6); /* Styling for visibility */
  border-radius: 50%;
  padding: 18px;
  cursor: pointer;
  
}

.playButton:hover{
  background-color: rgb(141, 212, 141);
}

@media screen and (max-width: 1080px) {

  .playButton {
    position: absolute;
    top: 100%;
    z-index: 1000;
    left: 50%;
    /* bottom: 50%; */
    transform: translate(-50%, -50%); /* Perfectly centers the button */
    background: rgba(0, 0, 0, 0.6); /* Styling for visibility */
    border-radius: 50%;
    padding: 18px;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .mainBenifits{
    padding: 2rem;
  }
  .benfitsWrapper {
      justify-content: center;
      padding: 0 20px; /* Padding on smaller screens for better edge appearance */
  }

  .programsProgram {
      width: 45%; /* Adjust card width on smaller screens */
      margin: 0 10px; /* Smaller margin between cards */
  }

  .playButton {
    position: absolute;
    top: 90%;
    z-index: 1000;
    left: 50%;
    /* bottom: 50%; */
    transform: translate(-50%, -50%); /* Perfectly centers the button */
    background: rgba(0, 0, 0, 0.6); /* Styling for visibility */
    border-radius: 50%;
    padding: 18px;
    cursor: pointer;
  }
}

@media screen and (max-width: 480px) {
  .playButton {
    position: relative;
    /* top: 110%; */
    z-index: 1000;
    left: 50%;
    bottom: 9rem;
    transform: translate(-50%, -50%); /* Perfectly centers the button */
    background: rgba(0, 0, 0, 0.6); /* Styling for visibility */
    border-radius: 50%;
    padding: 18px;
    cursor: pointer;
  }


  .videoImageContainer{
    height: 100%;
    width: 98%; 
    align-items: center;
    margin: auto;
    position: relative;
    right: 18%;
  }

  .image{
    /* margin: auto; */
    /* display: flex; */
    height: 200%;
    align-items: center;
    /* padding-top: 5rem; */
    width: 100%;
    position: absolute;
  }

  .benfitsWrapper {
      flex-direction: column;
      align-items: center;
  }

  .programsProgram {
      width: 80%; /* Full width cards on very small screens */
      margin: 10px 0; /* Stack cards with vertical spacing */
  }
  .mainBenifits{
    padding: 1rem;
  }
}

/* YourStylesheet.module.css */

.ComponentContainer {
    display: flex;
    /* justify-content: space-between; */
  }
  
  /* If you need the children to be aligned at the top */
  .ComponentContainer > * {
    align-self: flex-start;
  }
  
  /* If you want to ensure that the children do not wrap to the next line on small screens */
  .ComponentContainer {
    flex-wrap: nowrap;
  }
  
  .MultiFormContainer{
    margin-left: 1rem;
  }
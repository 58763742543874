/* ClientForm.module.css */

.container {
  padding: 20px;
  height: auto; /* Changed from 100vh to auto for better responsiveness */
  border-radius: 8px;
  margin: 20px;
}

  .DataColume{
    color: var(--color-fifth);
  }
  
  .heading {
    color: var(--color-fifth);
    margin-bottom: 15px;
    text-align: center;
    font-weight: bolder;
  }
  
  .titleColumn {
    /* width: 15%;  */
  }

  .td {
    width: 10%; 
  }

  .table {
    width: 100%; 
    /* min-width: 600px; */
    border-collapse: collapse;
  }

  .tableHead {
    background-color: var(--bg-secondary);
    color: var(--color-fifth);
    margin-bottom: 1rem;
  }
  
  .tableHead th {
    color: var(--color-fifth);
    padding: 10px;
    text-align: left;
    border-top: 2px solid var(--bg-accent);
    border-bottom: 2px solid var(--bg-accent);
    /* border-right: 2px solid var(--bg-accent);
    border-left: 2px solid var(--bg-accent); */
  }
  
  .tableBody {
    background-color: var(--color-fourth);  
    color: var(--color-accent2);
    font-weight: bold;
    font-size: 0.9rem;
  }

   .tableBody tr:hover {
    transform: scale(1.03);
    background-color: var(--color-fifth);
    box-shadow: 0 10px 20px var(--bg-primary), 0 6px 6px var(--bg-primary);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}
.btn {
  background-color: var(--color-fourth);
  color: var(--bg-secondary);
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px var(--bg-primary);
  margin-top: 2%;
  
}

.tableBody tr:hover .btn {
  background-color: var(--bg-fifth); 
  color: var(--bg-secondary); 
  
}
  .tableBody td {
    padding: 4px;
    text-align: left;
   
    color: var(--bg-primary);
   
  }
  
  .paginationContainer {
    position: relative;
    left: 37rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 141%; */
    width: 20%;
    margin-top: 20px;
    transition: var(--bg-primary) 0.3s ease;
    box-shadow: 0 2px 4px var(--color-primary);
  }
  
  .previousButton,
  .nextButton {
    margin-right: 10px; /* Adjust this value as needed */
    
  }
  
  .previousButton .prev,
  .nextButton .next {
    margin-left: 5px; /* For .previousButton only */
    margin-right: 5px; /* For .nextButton only */
    color: var(--color-fourth); 
  }
 
  
  .currentPageIndicator {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 38px;
    border-radius: 50%;
    color: var(--color-fifth); /* Ensure this variable is defined in your CSS or root */
    font-weight: bold;
    background-color: var(--bg-accent); /* Ensure this variable is defined */
    margin: 0 10px;
    transition: var(--bg-primary) 0.3s ease; 
     box-shadow: 0 2px 4px var(--color-primary); 
    border: 1px solid var(--bg-secondary);
  }
  
  .input{
    width: 100%;
  }

  @media (max-width: 1080px) {
    .paginationContainer {
      position: relative;
      left: 16rem;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-left: 141%; */
      width: 30%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      box-shadow: 0 2px 4px var(--color-primary);
    }
  }
  
  @media (max-width: 768px) {
    .container {
      /* Adjust the styles for smaller screens */
      padding: 10px;
      max-width: 100%;
      overflow-x: scroll; 

    }

    .paginationContainer {
      position: relative;
      left: 37rem;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-left: 141%; */
      width: 20%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      box-shadow: 0 2px 4px var(--color-primary);
    }
  
    .table {
      min-width: 600px;
    }
  
    .tableHead th, .tableBody td {
      /* Reduce padding and font size for smaller screens */
      padding: 5px;
      font-size: 14px; /* Adjust font size as needed */
    }
  
    .btn {
      padding: 8px 10px;
      font-size: 14px;
    }
    
  }

  @media (max-width: 480px) {
    .paginationContainer {
      position: relative;
      left: 11rem;
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-left: 141%; */
      width: 40%;
      margin-top: 20px;
      transition: var(--bg-primary) 0.3s ease;
      box-shadow: 0 2px 4px var(--color-primary);
    }
  }
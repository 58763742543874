/* CaseTitleData.module.css */

.FormTable {
  width: 100%; /* Adjust width as needed */
  border-collapse: collapse;
}

.Tbody {
  /* Additional styles if needed */
}

.Tr {
  /* Styles as needed */
}

.Td {
  border: 1px solid black;
  padding: 4px;
  text-align: left;
  vertical-align: top;
}

.TdLabel {
  font-weight: bold;
  background-color: #f2f2f2;
  white-space: nowrap;
  font-size: 1rem;
}

.TdData {
  background-color: #fff;
  font-size: 0.8rem;
}

.Gap td {
  padding: 10px 0; /* Adjust the padding to control the size of the gap */
  border: none; /* Remove border for the gap row */
}

@media (max-width: 768px) {
  .FormTable {
    width: 100%; /* Ensure full width for small screens */
  }
}

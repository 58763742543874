/* MaintenancePage.module.css */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 90vh;
    background-color: #f0f2f5;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
  
  .image {
    max-width: 60%;
    height: 30%;
    margin-bottom: 20px;
  }
  
  .message {
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center; /* Center text horizontally */
    max-width: 80%; /* Control the maximum width */
    margin-left: auto; /* Center block-level elements */
    margin-right: auto; /* Center block-level elements */
    white-space: pre-wrap; /* Allows text to wrap and preserves whitespace */
  }
  
  
  .contactLink {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .companyName {
    margin-top: 20px;
    font-size: 24px;
  }
  
  .law {
    color: pink;
  }
  
  .fax {
    color: white;
  }
  

.formContainer {
    max-width: 50%;
    height: 100%;
    margin: 4rem auto;
    padding: 20px;
    background-color: var(--bg-primary);
    border: 1px solid var(--color-btn-bg);
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    border-radius: 5px;
  }

  .required {
    color: var(--color-danger); /* Adjusted color */
    font-weight: bold; /* Added emphasis */
    font-size: large;
}
  .heading {
    text-align: center;
    font-weight: bolder;
    font-size: 26px;
    color: var(--color-fifth);
    margin-bottom: 3%;
  }
  .BtnContainer{
    display: flex;
    top: 0rem;
    justify-content: center;
    gap: 1rem;
    position: relative;
  }
  .text:focus, select:focus, 
  .time:focus, .dateOfHearing:focus, textarea:focus,
  .date:focus{
    border: 2px solid var(--color-primary);
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    color: var(--color-fifth);
  }
  .file{
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    background-color: var(--color-fifth);

  }
  .text,
  .date,
  .time,
  .textarea {
    width: 100%;
    padding: 0.4rem;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }

  .select{
    /* padding: 0.4rem; */
    width: 100%;
    height: 1.5rem;
  }
 
  .error {
    color: var(--color-danger);
    font-size: 12px;
    margin-top: 2px;
  }
  
  .submitButton {
    background-color: var(--color-btn-bg); 
      color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    color: var(--color-btn-bg);
    
  }
  .buttonCancel {
    background-color: var(--color-primary);
    color: var(--color-fifth);
    font-weight: bolder;
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  
  .buttonCancel:hover {
    background-color: var(--color-danger); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 1px 0px var(--color-primary);
  }

  .time{
    width: 100%;
    padding: 0.2rem;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .dateOfHearing{
    width: 100%;
    padding: 0.2rem;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 3px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);

  }
  
  
  .successPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .successPopup div {
    border: 1px solid var(--color-primary);
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    
  }
  
  .successPopup button {
    background-color: var(--color-accent1);
    color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    /* margin-bottom: 4px;  */
  }
  
  .field {
    flex: 1;
    margin-right: 10px; 
  }
  .selectDateOfHearing, .selectTimeOfHearing{
    width: 22rem;
    height: 1.5rem;
  }
  
  @media (max-width: 768px) {
    
    .formContainer {
      max-width: 100%;
      padding: 10px;
    }
    
    .text,
    .date,
    .file,
    .select,
    .textarea {
      margin-bottom: 8px;
    }
  }

  @media (max-width: 480px) {
    .label, .text, .date, .file,.textarea, .time, .dateOfHearing {
      width: 100%; /* Ensures full width */
      display: block; /* Ensures proper stacking */
      /* margin-bottom: 8px; */
      height: 1.2rem;
    }
  
    .label {
      font-size: 0.9rem; /* Adjusts the font size for better readability */
      color: var(--color-fifth);
    }
  
   
  
    .error {
      color: var(--color-danger);
      font-size: 0.8rem; /* Larger font size for error messages */
      margin-top: 2px;
    }
  
    .formContainer {
      padding: 10px; /* Reduces padding to utilize space more effectively */
    }
  
    .BtnContainer {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Align buttons to center */
      gap: 0.5rem; /* Smaller gap between buttons */
    }
  
    .submitButton, .buttonCancel {
      width: 100%; /* Full width buttons */
      margin-top: 5px; /* Consistent margin for top spacing */
    }
  
    .successPopup div {
      padding: 15px; /* Slightly reduced padding */
      font-size: 0.9rem; /* Adjusted font size */
    }
  
    .successPopup button {
      padding: 8px 12px; /* Smaller padding */
    }
    .selectDateOfHearing{
      width: 11rem;
      height: 1.5rem;
    }
    .selectTimeOfHearing{
      width: 11rem;
      height: 1.5rem;
    }
  }


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensures that the overlay is above other content */
  }
  
  .modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    position: relative; /* For absolute positioning of close button */
  }
  
  .modalHeader {
    margin-bottom: 20px;
    display: flex; /* Aligns the header content */
    justify-content: space-between; /* Separates the title and close button */
    align-items: center; /* Centers the button vertically */
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
  }
  
  .toggleContainer {
    margin-bottom: 20px;
    display: flex;
    align-items: center; /* Centers the toggle and label text */
  }
  
  .toggleInput {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
  
  .toggleSlider {
    position: relative;
    cursor: pointer;
    width: 60px;
    height: 34px;
    background-color: #ccc;
    border-radius: 34px;
    margin-right: 10px; /* Space between the toggle and label text */
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .toggleSlider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  .toggleInput:checked + .toggleSlider {
    background-color: #2196F3;
  }
  
  .toggleInput:checked + .toggleSlider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .printPdfButton {
    /* Your button styles */
  }
  
  .closeButton {
    /* Close button styles */
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px; /* Adjust as needed */
  }
  
  /* Rest of your styles */
  
Calendar.module.css
.MainContainer{
  overflow-x: auto;
}
.calendarContainer {
  font-family: 'Arial', sans-serif;
  /* padding: 5rem; */
  margin-top: 2rem;
  height: 100vh; 
  overflow-y: auto; /* This will add vertical scrolling */
}



  /* Add this style to your CalendarForm.module.css or equivalent file */
.errorMsg {
  color: red; /* Red color for the error message */
  margin-top: 5px; /* Optional: Adjust spacing as needed */
  font-size: 0.8em; /* Optional: Adjust font size as needed */
}

 
  .modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%;
    height: 25rem;
    max-width: 500px;
  }
  
  .closeButton {
    float: right;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .inputField {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .button{
    background-color: red;
  }
  
  
  .header {
    color: #333;
  }
  
  .rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 0 0;
    flex-basis: 50%px;
    overflow: hidden;
    height: 100%;
  }
  
  .AlertUpdateContainer {
    text-align: center;
    padding: 10px;
  }
  

  /* Button Styles */
  .btn1 {
    /* color: var(--color-secondary); */
    background-color: white;
    font-size: 1.1rem;
    font-weight: 700;
    margin-right: 1rem;
    cursor: pointer;
  }
  
  .btn2 {
    /* color: var(--color-primary); */
    background-color: white;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
  }
  
  .btn3 {
    /* color: var(--color-primary); */
    font-size: 1.1rem;
    font-weight: 700;
    background-color: white;
    margin-left: 1rem;
    cursor: pointer;
  }
  
  .btn {
    background-color: white;
    color: var(--color-primary);
    padding: 5px 12px;
    border: 1px solid var(--color-primary);
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: var(--color-fourth);
    color: var(--color-primary);
  }
  

  
  /* .btnOpenEvent {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
  } */

  
  
  /* Task visible form css */
  .visibleForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    max-width: 100%;
    margin: auto;
  }
/*   
  .TasksVisibleContainer {
    display: flex;
    flex-direction: column;
  }
   */
  .TasksVisibleTitle {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .TasksVisibleInput {
    width: calc(100% - 10px); 
    /* margin-bottom: 0.4rem; */
    /* padding: 0.2rem; */
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .TimeContainer {
    display: flex;
    flex-direction: column;
  }


  .BtnContainerUpDateTask,
  .BtnContainerUpdateHearing{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 7rem;
    gap: 1rem;
    position: relative;
    bottom: 4.8rem;
    justify-content: center;
  }

  .BtnContainerTask{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 7rem;
    gap: 1rem;
    position: relative;
    bottom: 4.8rem;
    justify-content: center;
  }

  
  
  /* css for hearing */
.HearingVisibleForm {
  /* Add styles for the form container if needed */
}

.formRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.formGroup {
  flex: 1;
  margin-right: 10px;
}

.formGroup:last-child {
  margin-right: 0;
}

/* .TasksVisibleTitle,
.HearingVisibleFormTitle {
  display: block;
  margin-bottom: 50px;
  font-weight: bold;
  color: #333;
} */

.TasksVisibleInput,
.HearingVisibleFormInput,
.HearingVisibleFormDate,
.HearingVisibleFormTime {
  width: 100%;
  /* padding: 4px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.BtnContainerHearing{
  display: flex;
  /* flex-direction: row-reverse; */
  margin-top: 6.8rem;
  gap: 1rem;
  position: relative;
  bottom: 4.8rem;
  justify-content: center;
}



.btn {
  background-color: #007bff; /* Example: blue */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}


.btn:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

  
  /* appointment css */

  .AppointmentVisibleForm {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px; 
    max-width: 100%; 
    margin: auto; */
  }

 
  .AppointmentFormTitle {
    margin-bottom: 0px;
    font-size: 4px;
  }
  
  .AppointmentVisibleFormInput,
  .AppointmentVisibleFormTime,
  .AppointmentVisibleFormDate {
    width: 100%; 
    margin-bottom: 20px;
  }

  
  .AppointmentVisibleFormInput {
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .AppointmentVisibleFormTime {
    margin-right: 0; 
  }
  
  /* Convert h6 to label */
  .AppointmentVisibleForm label {
    margin-bottom: 0px;
    font-size: 14px;
    display: block;
  }

  .BtnContainer{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 6.56rem;
    gap: 1rem;
    position: relative;
    bottom: 4.8rem;
    justify-content: center;
  }
  .BtnContainerUpdateAppointment{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 7rem;
    gap: 1rem;
    position: relative;
    bottom: 4.8rem;
    justify-content: center;
  }
  
  /* Update clickedEvent */
  .btnContainerclickedEvent{
    padding-top: 2rem;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
  }
  /* CSS to target the view buttons */
.rbc-toolbar button {
  /* Your custom styles here */
  background-color: red;
  border-radius: 4px;
  /* Add more styling as needed */
}



  @media only screen and (max-width: 786px) {
    .calendarContainer{
      height: 40rem; 
    }
   
    
  
    .formGroup, .TasksVisibleInput, .HearingVisibleFormInput, .AppointmentVisibleFormInput {
      width: 100%; /* Make form elements take full width */
    }
  
    .btnContainerclickedEvent, .BtnContainer, .BtnContainerUpDateTask, .BtnContainerUpdateAppointment, .BtnContainerHearing {
      flex-direction: column; /* Stack buttons vertically */
      align-items: center; /* Center-align buttons */
      gap: 10px; /* Adjust gap between buttons */
    }
  }
  
  @media only screen and (max-width: 480px) {
    .modal{
      /* width: 100vw; */
    }
    .modalContent{
      width: 98%;
      height: 30rem;
    }
    .calendarContainer{
      height: 40rem;
    }
   .BtnContainerUpdateHearing{
    display: flex;
    flex-direction: column;
   }
   .btn1,.btn2,.btn3{
    font-size: 0.8rem;
   }
   .btnContainerclickedEvent, .BtnContainer, .BtnContainerUpDateTask, .BtnContainerUpdateAppointment, .BtnContainerHearing {
    display: flex;
    flex-direction: row;

   }
  }



  /* CalendarForm.module.css */
.filterSelect {
  width: 11rem; /* Adjust based on your layout */
  padding: 12px;
  margin: 10px 0;
  background-color: #f4f4f4;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: all 0.3s ease;

  /* Custom dropdown arrow */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 24px;
}

.filterSelect:hover {
  border-color: #b1b1b1;
}

.filterSelect:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 2px 8px rgba(0,123,255,0.2);
}


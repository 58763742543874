
.container {
  padding: 20px;
  background-color: #f4f4f4; 
  border-radius: 5px; 
  width: 50%; 
  margin: 40px auto; 
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.formGroup {
  margin-bottom: 20px; 
}

.label {
  display: block;
  margin-bottom: 5px; 
  color: #333; 
  font-size: 16px; 
}

.textInput, .input, .select, .button {
  display: block;
  width: calc(100% - 16px); 
  padding: 8px;
  margin-bottom: 5px; 
  box-sizing: border-box; 
  border: 1px solid #ccc; 
  border-radius: 4px;
}

.textInput {
  height: 100px;
  resize: vertical; 
}

.input, .select {
  font-size: 16px; 
}

.select {
  cursor: pointer; 
}

.button {
  background-color: #4CAF50; 
  color: white;
  padding: 14px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto; 
  display: inline-block; 
}

.button:hover {
  background-color: #45a049; 
}

.heading {
  color: #333; 
  text-align: center;
  margin-bottom: 30px;
}

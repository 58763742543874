 
.formContainer {
    width: 100%;
    max-width: 400px; 
    margin: 0 auto;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
    background-color: var(--bg-primary);
    border: 1px solid var(--color-btn-bg);
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
  }
  .Heading{
    text-align: center;
    color: var(--color-primary);
  }
  
 
  .fieldGroup {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 2px;
    font-weight: bold;
    color: var(--color-fifth);
  }
  
  .inputField,
  .selectField {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 4px;
    font-size: 16px;
    height: 40px;
    
  }
  
  .selectField {
    width: 100%;
  }
  
  .error {
    color: var(--color-danger);
    font-size: 14px;
    
  }
  .BtnContainer{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top:1rem;
  }
  
  /* Submit Button */
.submitButton {
  background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}
.submitButton:hover {
  background-color: var(--color-fourth); 
  box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
  font-weight: bolder;
  transform: scale(1.05); 
  color: var(--color-btn-bg);
  
}
.buttonCancel {
  background-color: var(--color-primary);
  color: var(--color-fifth);
  font-weight: bolder;
  padding: 10px 15px;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  margin-top: 10px;
  box-shadow: inset 0px 0px 6px 0px ;
}

.buttonCancel:hover {
  background-color: var(--color-danger); 
  color: var(--color-primary);
  transform: scale(1.05); 
  box-shadow: inset 0px 0px 1px 0px var(--color-primary);
}

  
  @media screen and (max-width: 768px) {
    .formContainer {
      max-width: 100%;
    }
    
    .inputField,
    .selectField {
      width: 100%;
    }
  }
  
/* Header  */
.Header {
    grid-area: header;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 0 30px;
    box-shadow: 0 6px 7px -3px rgba(0, 0, 0, 0.35);
  }
  
  .MenuIcon {
    display: none;
  }

  .Icon{
    vertical-align: middle;
    line-height: 1px;
    font-size: 20px;
    color: var(--color-accent2)
  }

  .Icon, .Header{
    margin-right: 5px;
  }


.formContainer {
    /* max-width: 600px; */
    margin: auto;
    padding: 20px;
    background-color: #f4f7f6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formField {
    width: calc(33.333% - 10px); /* Adjust for three in a row with some spacing */
    margin-bottom: 20px;
    padding: 0 5px; /* Small horizontal padding inside each form field container */
    box-sizing: border-box;
    float: left; /* Align fields in a row */
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  
  .inputField, .selectField, .date, .cdate {
    width: 100%;
    padding: 8px; /* Smaller padding for the fields */
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .error {
    color: #ff6b6b;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .BtnContainer {
    display: flex;
    clear: both; /* Clear floats */
    /* flex-direction: row-reverse; */
    justify-content: end;
    margin-top: 20px;
  }
  
  .submitButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .formField {
      width: 100%; /* Full width on smaller screens */
      margin-bottom: 20px;
      padding: 0;
    }
  }
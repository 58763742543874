.reviewDocForm {
    margin: 0 auto;
    padding: 20px;
    
    border-radius: 5px;
    margin-bottom: 40px;
    background-color: var(--bg-primary);
    border: 1px solid var(--color-btn-bg);
    box-shadow: 0px 0px 10px 0.2px var(--color-accent1);
    margin-top: 40px;
    width: 600px;
  }
  
  .heading {
    font-size: 1.2rem;
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-fifth);
  }
  .para {
    
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-Third);
  }
  
  .label {
    margin-right: 10px;
    color: var(--color-fifth);
  }
  .input0 {
    width: 60%;
    padding: 5px;
    border: 1px solid var(--color-accent1);
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .input {
    width: 60%;
    padding: 5px;
    border: 1px solid var(--bg-secondary);
    border-radius: 4px;
    font-size: 16px;
    margin-right: 10px;
    box-shadow: inset 0px 0px 4px 0px var(--color-Third);
  }
  .inputTextarea {
    width: 60%;
    padding: 5px;
    border: 1px solid var(--color-primary);
   
    border-radius: 4px;
    height: 100px;
    font-size: 16px;
  }
  .inputTextarea:focus {
   
    border: 1px solid var(--color-primary);
  }
  .error {
    color: var(--color-danger);
    margin-top: 5px;
  }
  .submitButton {
    background-color: var(--color-btn-bg); 
    color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    color: var(--color-primary);
    transform: scale(1.05); 
    box-shadow: inset 0px 0px 6px 0px var(--color-primary);
  }
  
/* MultiFormTabs.module.css start */


.tabTitles {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 1rem;
  }
  
  .tabTitles button {
    padding: 10px 20px;
    margin-right: 5px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tabTitles button:hover {
    background-color: #e0e0e0;
  }
  
  .activeTab {
    background-color: #007bff;
    color: var(--color-secondary);
    border-color: #007bff;
  }
  
  .tabContent {
    border: 1px solid #ddd;
    padding: 20px;
    height: 3%;  
    width: 60rem;
  }


  .submitButton {
    background-color: var(--color-btn-bg); 
      color: var(--color-fifth);
    padding: 10px 15px;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: inset 0px 0px 6px 0px ;
  }
  .submitButton:hover {
    background-color: var(--color-fourth); 
    box-shadow: inset 0px 0px 4px 0px var(--color-btn-bg);
    font-weight: bolder;
    transform: scale(1.05); 
    color: var(--color-btn-bg);
    
  }

  .labels{
    color:var(--color-secondary)
  }
  /* MultiFormTabs End */

  /* Task form Strat */
/* Simplified and concise CSS for smaller form fields in three columns */

.formContainer {
    /* max-width: 600px; */
    margin: auto;
    padding: 20px;
    background-color: #f4f7f6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formField {
    width: calc(33.333% - 10px); /* Adjust for three in a row with some spacing */
    margin-bottom: 20px;
    padding: 0 5px; /* Small horizontal padding inside each form field container */
    box-sizing: border-box;
    float: left; /* Align fields in a row */
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
  }
  
  .inputField, .selectField, .date, .cdate {
    width: 100%;
    padding: 8px; /* Smaller padding for the fields */
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .error {
    color: #ff6b6b;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .BtnContainer {
    display: flex;
    clear: both; /* Clear floats */
    /* flex-direction: row-reverse; */
    justify-content: center;
    margin-top: 20px;
    gap: 1rem;

  }
  

  
  @media (max-width: 768px) {
    .formField {
      width: 100%; /* Full width on smaller screens */
      margin-bottom: 20px;
      padding: 0;
    }
  }
  
  
  @media (max-width: 480px) {
    .tabContent {
      border: 1px solid #ddd;
      padding: 20px;
      height: 3%;  
      width: 128vw;
    }
  }
  
  


  /* Task End */
/* UserManagement.module.css */
.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto; /* Allows horizontal scrolling for wide tables */
    display: block;
    max-height: 720px; /* Adjust based on your needs */
    overflow-y: auto; /* Enable vertical scrolling */
}
.heading{
    text-align: center;
}
.table {
    width: 150%;
    max-width: 150%;
    border-collapse: collapse;
}

.tableHeader {
    background-color: #007bff;
    color: #ffffff;
    text-align: left;
    padding: 12px 15px;
    z-index: 2;
    top: 0;
    position: sticky;
}

.tableRow:nth-child(even) {
    background-color: #f2f2f2;
}

.searchInput {
    padding: 10px 15px;
    margin: 20px 0;
    width: 150%; /* Adjust width to fit within the container */
    font-size: 16px; /* Larger font size for better readability */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 5px; /* Rounded corners */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.searchInput:focus {
    outline: none; /* Removes the default focus outline */
    border-color: #007bff; /* Highlight color */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1), 0 0 8px rgba(0, 123, 255, 0.6); /* Increased shadow on focus for emphasis */
}

.tableCell {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}

.heading {
    margin-bottom: 20px;
    font-size: 22px; /* Larger heading for better visibility */
    color: #333; /* Dark color for contrast */
}

.statusIcon {
    margin-left: 10px; /* Space out icons from text and other icons */
    cursor: pointer; /* Indicates clickable icons */
    font-size: 24px; /* Larger icons for easier interaction */
}

.downloadIcon {
    cursor: pointer;
    /* Additional styling for download icon if needed */
}

/* You can add more styles here based on your layout and design preferences */

/* TeamMember.module.css */

.MainContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .formContainer {
    max-width: 960px; /* Adjust based on your preference */
    background-color: #f4f7f6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .fieldGroup {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .inputField, .selectField {
    flex: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .inputField:last-child, .selectField:last-child {
    margin-right: 0;
  }
  
  .horizontalFields {
    display: flex;
    justify-content: space-between;
  }
  
  .BtnContainer {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  
  .submitButton, .Button {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submitButton {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .Button {
    /* Adjust based on your Button component styles */
  }
  
  .error {
    color: #ff6b6b;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .imageUpload {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .uploadedImage, .emptyImage {
    width: 100px; /* Adjust based on your needs */
    height: 100px; /* Adjust based on your needs */
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .link {
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
  }
  
  @media (max-width: 768px) {
    .fieldGroup, .horizontalFields {
      flex-direction: column;
    }
  
    .inputField, .selectField {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .inputField:last-child, .selectField:last-child {
      margin-bottom: 0;
    }
  }
  
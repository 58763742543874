.userProfilePage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #0f0c29, #302b63, #24243e);
    color: #fff;
    font-family: 'Arial', sans-serif;
  }
  
  .formGroup {
    margin: 20px 0;
    perspective: 500px;
  }
  
  .formGroup .labels {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }
  
  .formGroup .Holders{
    width: 300px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.1);
    color: #0f0c29;
    outline: none;
    transition: transform 0.3s ease;
  }
  
  .formGroup .Holders:focus{
    transform: translateY(-5px);
  }
  
  .submitButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ff758c 0%, #ff7eb3 100%);
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 5px 15px rgba(255, 120, 180, 0.4);
    transition: transform 0.2s ease;
  }
  
  .submitButton:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(255, 120, 180, 0.5);
  }
  
  /* Add some 3D and futuristic effects */
  .Holders:focus {
    border: 1px solid #ff7eb3;
  }
  
  .Holders {
    background: rgba(255, 255, 255, 0.2);
    appearance: none;
  }
  
  /* This ensures that the background of the input fields matches the futuristic theme */
  .Holders::placeholder {
    color: #e0e0e0;
  }
  
  /* Enhance the overall appearance with more 3D effects */
 .Holders {
    /* background: linear-gradient(145deg, #1e1c31, #2a2a35); */
    background-color: #e0e0e0;
  }
  
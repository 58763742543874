/* Main Flex Container */
.flexContainer {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-around; 
  gap: 30px; 
  /* margin-top: 10rem; */
  width: 90vw;
  height: 60vh;
  position: relative;
  right: 25rem;
  background-color: transparent;
}

.MainHeading{
  position: relative;
  bottom: 15rem;
  font-size: 5rem;
}
.Heading{
  font-size: 5rem;
  color: white;
}

/* Form and Company Info Styling */
.contactFormContainer, .companyInfo {
  flex-basis: calc(50% - 30px); /* Adjust for gap */
  max-width: calc(50% - 30px); /* Responsive max-width */
  background-color: var(--bg-secondary);
  padding: 2rem;
  border-radius: 12px; /* Smoothed corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  opacity: 0.9; /* Slightly transparent */
}



/* Input and Textarea enhancements */
.contactFormContainer .input,
.contactFormContainer {
  padding: 0.75rem; /* Comfortable padding */
  margin-top: 0.25rem; /* Space between label and input */
  font-size: 1rem; /* Accessible font size */
  border-radius: 8px; /* Consistent rounded corners */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for focus */
  width: 100%;
}
.input{
  background-color: var(--color-btn-bg);
}

.textArea{
  padding: 0.75rem; /* Comfortable padding */
  margin-top: 0.25rem; /* Space between label and input */
  font-size: 1rem; /* Accessible font size */
  border-radius: 8px; /* Consistent rounded corners */
  transition: border-color 0.3s ease-in-out; /* Smooth transition for focus */
  width: 100%;
  height: 8rem;
  background-color: var(--color-btn-bg);
}



/* Submit Button Enhancements */
.contactFormContainer .submitButton {
  font-size: 1.1rem; /* Larger text for clarity */
  letter-spacing: 0.05em; /* Subtle spacing for elegance */
  padding: 0.75rem 1.5rem; /* Adequate padding */
  width: 100%; /* Full width for mobile responsiveness */
  display: block; /* Ensure it's block level for full width */
  margin-top: 1rem; /* Space above button */
}

/* Message Styles */
.contactFormContainer .successMessage,
.contactFormContainer .errorMessage {
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 8px;
  font-weight: bold;
}

.contactFormContainer .successMessage {
  background-color: #d4edda; /* Green background for success */
  color: #155724; /* Dark green text for contrast */
  border: 1px solid #c3e6cb; /* Slight border */
}

.contactFormContainer .errorMessage {
  background-color: #f8d7da; /* Red background for error */
  color: #721c24; /* Dark red text for contrast */
  border: 1px solid #f5c6cb; /* Slight border */
}

/* Additional Aesthetic Adjustments */
/* Use var() for CSS variables if you have a theme or color scheme defined. Replace hex values with your variable names as needed. */


/* Button Enhancements */
.btn {
  background-color: var(--color-fourth);
  color: var(--bg-secondary);
  padding: 1rem 11rem;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px var(--bg-primary);
  margin-top: 2rem;
  position: relative;
  top: 1rem;
  
}
.btn:hover{
  background-color: var(--color-btn-bg);
}

/* Typography and Spacing for Articles */
.article h3 {
  margin-bottom: 0.75rem; /* Space between heading and text */
  color: #333; /* Dark text for emphasis */
}

.article p {
  font-size: 0.9rem; /* Slightly smaller font size for articles */
  color: #666; /* Light text for readability */
  line-height: 1.5; /* Line height for improved readability */
}

@media (max-width: 1024px) {
  .flexContainer {
    flex-direction: column; /* Stack elements vertically for better readability */
    align-items: center; /* Center align the flex items */
    height: 100%; /* Adjust height to auto to accommodate the vertical stacking */
    width: 60vw; /* Adjust the width for some breathing room */
    right: 0; /* Reset right alignment */
  }

  .contactFormContainer, .companyInfo {
    flex-basis: 100%; /* Allow each container to take full width */
    max-width: 100%; /* Ensure containers do not exceed their parent's width */
    margin-bottom: 20px; /* Add some space between the containers when stacked */
  }

  .MainHeading, .Heading {
    font-size: 3.5rem; /* Reduce font size for a more suitable presentation */
    bottom: 10rem; /* Adjust positioning */
  }

  .btn {
    padding: 1rem 4rem; /* Adjust padding for a more proportionate appearance */
    width: auto; /* Allow the button to shrink/grow as needed */
    margin-top: 2rem; /* Adjust top margin for spacing */
  }
}

@media (max-width: 768px) {
  .flexContainer{
    height: 80%;
    width: 48rem;
    display: flex;
    flex-direction: row;
  }

  .contactFormContainer, .companyInfo {
    height: 80%;
    width: 40rem;
    position: relative;
    left: 19rem;
  }

  .btn {
    background-color: var(--color-fourth);
    color: var(--bg-secondary);
    padding: 1rem 9rem;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px var(--bg-primary);
    margin-top: 2rem;
    position: relative;
    top: 0rem;
  }

  .Heading{
    font-size: 2.5rem
  }
}


@media (max-width: 480px) {
  .flexContainer{
    position: relative;
    height: 80%;
    width: 98%;
    display: flex;
    flex-direction: column;
    right: 19rem;
  }

  .contactFormContainer, .companyInfo {
    height: 80%;
    width: 48rem;
    position: relative;
    /* left: 22.5rem; */
    right: 10rem;
  }

  .btn {
    background-color: var(--color-fourth);
    color: var(--bg-secondary);
    padding: 1rem 6rem;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px var(--bg-primary);
    margin-top: 2rem;
    position: relative;
    top: 0rem;
  }

  .Heading{
    font-size: 2.5rem
  }
  
}
.MainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89.2vh;
  /* background-image: url('../../../../assets/dashboard.jpg'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Container{
  width: 80%;
  max-width: 600px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: var(--color-fourth);
  box-shadow: 0px 0px 10px 0px var(--color-primary);
  height: 40%;
  margin: 10px; /* Adjust margin for spacing between ContainerLeft and ContainerRight */
  
}
.Container:hover{
  
  background-color: var(--bg-secondary);
  
  
}

.Caselinks {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin-top: 20px;
}

.heading {
  font-size: 2.5rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
  color: var(--bg-primary);
}
.Container:hover .heading {
  color: var(--color-fifth);
}
.Caselinks li {
  list-style: none;
  margin-bottom: 4px;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 4px 0px var(--color-primary);
  background-color: var(--color-fourth);
}

.Caselinks a {
  display: inline-block;
  color: var(--color-fifth);
  border: 1px solid var(--button-border-color);
  box-shadow: 0px 4px 6px var(--color-primary);
  background: linear-gradient(145deg, var(--bg-primary), var(--color-fourth));
  border-radius: 5px;
  transition: var(--transition);
  width: 11rem;
}

.Caselinks a:hover {
  font-size: 1.2rem;
  font-weight: 700;
  transition: var(--transition);
  width: 12.9rem;
}

@media screen and (max-width: 768px) {

  .Container {
    width: 98%;
  }

  .Caselinks a {
    width: 8rem;
  }
}
@media screen and (max-width: 600px) {
  .MainContainer{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    height: 90vh;
  } 
  
  .Container {
      width: 15rem;
      height: 14rem;
    }
    .Container h1{
      font-size: 2rem;
    }

}
